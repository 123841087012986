import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
// import i18next
import { useTranslation } from "react-i18next";

const SettingsNews = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    
    // Set theme
    const [theme, setTheme] = useState('darkTheme');

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/');
        }
    }, [])
    
    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const closeNotifMB = () => {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem("theme"), location])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr/settings/news" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.main')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/settings/news"+location.search}/>
                <meta name="twitter:title" content={t('title.settings.main')}/>
                <title>{t('title.settings.main')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <div id="mBLogged" className={theme+" error"} style={{display: 'none'}}>
                <div id="textN_nMAPPb1">
                    <p></p>
                </div>
                <div className="iconClose" onClick={closeNotifMB}><i className="fi fi-sr-cross"></i></div>
            </div>
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <div id="returnIcon" onClick={() => navigate('/settings')} tabIndex={1}>
                            <i className="fi fi-rr-angle-left"></i>
                        </div>
                        <h1 id="title">{t('settings.news')}</h1>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="WhatsNew">
                            <div id="titleTop">
                                <h1>Quoi de neuf ?</h1>
                            </div>
                            <div id="describePart">
                                
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsNews;