import { useEffect } from "react";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";

export default function News({autoShow}) {
    const { t } = useTranslation();

    useEffect(() => {
        if(autoShow){
            $('#newsPopup .js-modal-close').off('click').on('click', (e) => {
                e.preventDefault();
                $('#newsPopup').attr('aria-hidden', 'true');
                $('#newsPopup').removeAttr('aria-modal');
                const hideModal = () => {
                    $('#newsPopup').css("display", "none");
                    $('#newsPopup').off('animationend');
                }
                $('#newsPopup').on('animationend', hideModal);
                if(document.getElementById('mainAppHome')) document.getElementById('mainAppHome').style.zIndex = -1;
                if(document.getElementById('friendsPart')) document.getElementById('friendsPart').style.zIndex = -1;
            
                // Register to database
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/update/view/update`,
                    {
                        headers:{
                            "Content-Type": "application/json",
                        },
                        data: { user: Cookies.get('loginToken') }
                    }
                );
            })
        }
    }, [autoShow])

    return (
        <>
            <aside id="newsPopup" className="modal" aria-hidden={autoShow ? "false": "true"} aria-modal={autoShow ? "true": "false"} style={{display: autoShow ? "flex": "none"}}>
                <div className="modal-wrapper js-modal-stop" style={{width: 750,height:750}}>
                    <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10}}>
                        <i className="fi fi-rr-cross"></i>
                    </button>
                    <div id="WhatsNew">
                        <div id="titleTop">
                            <h1>Quoi de neuf ?</h1>
                            <h2>Le 16/10/24</h2>
                        </div>
                        <div id="describePart">
                            <p>
                                <span style={{fontWeight: 500,fontSize: 18}}>Bonjour !</span>
                                <br/>
                                C'est sûrement la première fois que vous voyez ceci, pour faire simple, c'est un "carnet" où nous vous décrivons les ajouts dans une mise à jour.
                                <br/><br/>
                                Bonne lecture !
                                <br/><br/>
                                <span style={{fontWeight: 500,fontSize: 20,color: "#2B88FF"}}>Quel ajout ?</span>
                                <div style={{margin:0,height: 0,width: "100%",borderBottom: "2px solid #2B88FF",marginTop: 10,marginBottom: 20}}></div>
                                <span style={{fontWeight: 500,fontSize: 20}}>Les groupes.</span>
                                <br/>
                                Les groupes sont arrivés ! Rassemblez-vous entre amis pour travailler, discuter, partager des images drôles... Vous pouvez en créer un en cliquant sur <strong>l'icône à côté de la maison (en bas)</strong> puis cliquer sur <strong>"Groupe +"</strong>, ajouter vos amis et c'est parti !
                                <br/><br/>
                                <span style={{fontWeight: 500,fontSize: 20}}>Et ensuite ?</span>
                                <br/>
                                La mise à jour suivante concernera les signalements, mais je suis sûr que ça ne vous intéresse pas trop... c'est pourquoi je vais vous donner un indice pour une mise à jour qui pourrait vous intéresser : <strong>personnaliser</strong>.
                                <br/><br/>
                                Comme dit lors de la précédente mise à jour, il n'y aura plus de "grosse" mise à jour. Préparez-vous à revoir ce message !
                                <br/><br/>
                                <span style={{fontWeight: "500",fontSize: 20,color: "rgb(214, 95, 95)"}}>
                                    À très vite !
                                </span>
                                <div style={{margin:0,height: 0,width: "100%",borderBottom: "2px solid rgb(214, 95, 95)",marginTop: 10,marginBottom: 20}}></div>
                                Merci à vous d'avoir lu cet article, on se retrouvera très bientôt avec la prochaine mise à jour !
                            </p>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}