import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../Components/Common';
import { VerticalMenu } from "../../Components/Pages/Settings";
// import Loading
import { LoadingDefault } from "../../Components/Loading/LoadingDefault";
// import i18next
import { useTranslation } from "react-i18next";
// import Auth
import { retreiveInfos } from "../../Components/Functions/Auth";

const Settings = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set infos
    const [infos, setInfos] = useState([]);
    // Set loading
    const [loading, setLoading] = useState(true);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/');
        }
    }, [])

    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const closeNotifMB = () => {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    useEffect(() => {        
        // Get [user] infos
        retreiveInfos().then(res => {
            setInfos(res);
            // set loading
            setLoading(false);
        })

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem("theme"), location])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr/settings" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.main')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/settings"+location.search}/>
                <meta name="twitter:title" content={t('title.settings.main')}/>
                <title>{t('title.settings.main')}</title>
            </Helmet>
            {
                loading === true ?
                <LoadingDefault />
                :
                <>
                <Appbar />
                <Navbar />
                <div id="mBLogged" className={theme+" error"} style={{display: 'none'}}>
                    <div id="textN_nMAPPb1">
                        <p></p>
                    </div>
                    <div className="iconClose" onClick={closeNotifMB}><i className="fi fi-sr-cross"></i></div>
                </div>
                <div id="settingsMain">
                    <VerticalMenu infos={infos} theme={theme} />
                </div>
                </>
            }
       </>
    )
}

export default Settings;