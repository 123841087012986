import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Helmet from "react-helmet";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from 'jquery';
import { useGoogleLogin } from '@react-oauth/google';
import DOMPurify from "dompurify";
import {UAParser} from "ua-parser-js";
// import i18next
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
// import Functions
import { API_VERSION } from "../../Components/Functions";
// import Images
import gLogo from '../../img/oauth/google.png';
import { publicIp } from "public-ip";

const Register = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('loginToken')) {
            if(Cookies.get('logged') === '1'){
                navigate('/');
            }
        }
    }, [])

    // Set common states
    const [token, setToken] = useState(null);
    const [error, setError] = useState('');
    // Step for sign up
    const [steps, setSteps] = useState({
        actualStep: 'step1',
        step1: {
            name: "flex",
            username: "block",
        },
        step2: "none",
        step3: "none",
        continueBtn: "block",
        submitBtn: "none",
        return: "none",
        titleMargin: 30,
        oauth: "slide-in-top",
    });
    // Set form states
    const [form, setForm] = useState({
        givenName: '',
        familyName: '',
        username: "",
        gender: {
            name: '',
            checked: false,
        },
        birthdate: '',
        email: '',
        password: '',
        confirm_password: ''
    });
    // Ref
    const captchaRef = useRef(null);

    // hCaptcha
    const onExpire = () => {console.log("hCaptcha Token Expired");};
    const onError = (err) => {console.log(`hCaptcha Error: ${err}`);};

    // Device & browser
    // -- Get OS name
    const getOSName = () => {
        const userAgent = navigator?.userAgent || "unknown";
        const parser  = new UAParser(userAgent);
        var device = parser.getOS().name;
        return device;
    }
    // -- Get browser name
    const getBrowserName = () => {
        const userAgent = navigator?.userAgent || "unknown";
        const parser  = new UAParser(userAgent);
        var browser = parser.getBrowser().name;
        return browser;
    };

    // Go to the next step
    const continueStep = () => {
        if(steps.step2 === "none"){
            if(form.username !== "") {
                setSteps({
                    ...steps,
                    actualStep: "step2",
                    step1: {
                        username: "none",
                        name: "none"
                    },
                    step2: "block",
                    return: "block",
                    titleMargin: 0,
                    oauth: "slide-out-top"
                })
                setError('')
            }else{
                var errorInputUSR = $('.inputUsername_eFormLIB1');
                if (form.username === "") {
                    setError('You must enter a username.');
                    if(i18n.resolvedLanguage === "fr") {
                        setError('Vous devez entrer un nom d\'utilisateur.')
                    }
                    errorInputUSR.css({ borderColor: "#db4e4e", color: "#db4e4e" })
                }
            }
        }else{
            if(form.birthdate !== "" && form.email !== "") {
                setSteps({
                    ...steps,
                    actualStep: "step3",
                    step2: "none",
                    step3: "block",
                    continueBtn: "none",
                    submitBtn: "block"
                })
                setError('')
            }else{
                var errorInputBD = $('.inputBirthDate_eFormLIB1');
                var errorInputEM = $('.inputEmail_eFormLIB1');
                if(form.birthdate === "" && form.email !== "") {
                    setError('You must enter your birth date.');
                    if(i18n.resolvedLanguage === "fr") {
                        setError('Vous devez entrer votre date de naissance.')
                    }
                    errorInputBD.css({ borderColor: "#db4e4e", color: "#db4e4e" })
                }else if (form.email === "" && form.birthdate !== ""){
                    setError('You must enter your email address.');
                    if(i18n.resolvedLanguage === "fr") {
                        setError('Vous devez entrer votre adresse email.')
                    }
                    errorInputEM.css({ borderColor: "#db4e4e", color: "#db4e4e" })
                }else {
                    setError('You must enter your birth date and your email address.');
                    if(i18n.resolvedLanguage === "fr") {
                        setError('Vous devez entrer votre date de naissance et votre adresse email.')
                    }
                    errorInputBD.css({ borderColor: "#db4e4e", color: "#db4e4e" })
                    errorInputEM.css({ borderColor: "#db4e4e", color: "#db4e4e" })
                }
            }
        }
    }
    // Return to the step 1, 2, ...
    const returnStep = () => {
        if(steps.actualStep === "step2"){
            setSteps({
                ...steps,
                actualStep: "step1",
                step1: {
                    username: "block",
                    name: "flex"
                },
                step2: "none",
                return: "none",
                titleMargin: 30,
                oauth: "slide-in-top"
            })
        }else if(steps.actualStep === "step3"){
            setSteps({
                ...steps,
                actualStep: "step2",
                step2: "block",
                step3: "none",
                continueBtn: "block",
                submitBtn: "none"
            })
        }
    }

    // Sign up validate form
    const validate = () => {
        const regexEmail = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
        $('.errorInfoLI_tLIB1.signup').css('color', '#db4e4e');
        // For birth date
        const currentDate = new Date().toISOString().split('T')[0];
        const birthDate = new Date(form.birthdate);
        const age = Math.floor((new Date(currentDate) - birthDate) / (365.25 * 24 * 60 * 60 * 1000));
        const requiredAge = age.toString();
        // VERIFY INFOS EXCEPT PASSWORDS
        // -- USERNAME, NAME
        if(form.username.length > 25) {
            setSteps({
                ...steps,
                actualStep: "step1",
                step1: {
                    username: "block",
                    name: "flex"
                },
                step2: "none",
                return: "none",
                titleMargin: 30,
                submitBtn: "none",
                continueBtn: "block",
                step3: "none",
            })
            setError('Your username cannot be longer than 25 characters.');
            if(i18n.resolvedLanguage === "fr") {
                setError('Votre nom d\'utilisateur ne peux pas faire plus de 25 caractères.')
            }
            $('.inputUsername_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
        }else if(form.username.length < 3) {
            setSteps({
                ...steps,
                actualStep: "step1",
                step1: {
                    username: "block",
                    name: "flex"
                },
                step2: "none",
                return: "none",
                titleMargin: 30,
                submitBtn: "none",
                continueBtn: "block",
                step3: "none",
            })
            setError('Your username cannot be less than 3 characters long.');
            if(i18n.resolvedLanguage === "fr") {
                setError('Votre nom d\'utilisateur ne peux pas faire moins de 3 caractères.')
            }
            $('.inputUsername_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
        }
        // -- BIRTHDATE
        else if (requiredAge < 15) {
            setSteps({
                ...steps,
                actualStep: "step2",
                step2: "block",
                step3: "none",
                continueBtn: "block",
                submitBtn: "none"
            })
            // Set error
            setError("You must be at least 15 years old.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Vous devez avoir au moins 15 ans.");
            }
        }
        // -- EMAIL
        else if (!regexEmail.test(form.email)) {
            setSteps({
                ...steps,
                actualStep: "step2",
                step2: "block",
                step3: "none",
                continueBtn: "block",
                submitBtn: "none"
            })
            $('.inputEmail_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            // Set error
            setError("You must enter a valid email address.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer une adresse email valide.");
            }
        }
        // VERIFY PASSWORDS
        else if (form.password === '' && form.confirm_password !== '') {
            $('.inputPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            setError("You must enter your password.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer votre mot de passe.");
            }
        }
        else if (form.confirm_password === '' && form.password !== '') {
            $('.inputConfPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            setError("You must confirm your password.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Vous devez confirmer votre mot de passe.");
            }
        }else if (form.confirm_password === '' && form.password === '') {
            $('.inputPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            $('.inputConfPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            setError("You must enter your password and confirm it.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer votre mot de passe et le confirmer.");
            }
        }else if (form.confirm_password !== form.password) {
            $('.inputPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            $('.inputConfPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            setError("The passwords don't match.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Les mots de passe ne correspondent pas.");
            }
        }else if (form.password.length < 8) {
            $('.inputPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            $('.inputConfPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
            setError("Your password must be at least 8 characters long.");
            if (i18n.resolvedLanguage === "fr"){
                setError("Votre mot de passe doit comporter au moins 8 caractères.");
            }
        }
        // IF EVERYTHING IS CORRECT
        else {
            captchaRef.current.execute();
            // Set loading text
            setError('Loading the verification...');
            if(i18n.resolvedLanguage === "fr") {
                setError('Chargement de la vérification...');
            }
            $('.errorInfoLI_tLIB1.signup').css('color', '#707070');
            // Reset input color
            $('.inputPass_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
            $('.inputConfPass_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
        }
    };

    useEffect(() => {
        // Initialize theme
        document.documentElement.className = "lightTheme";
        document.body.className = "lightTheme";

        // Once checkbox for gender is checked
        $("input:checkbox").on('click', function() {
            var box = $(this);
            if (box.is(":checked")) {
                var group = `input:checkbox[name='${box.attr("name")}']`;
                $(group).prop("checked", false);
                box.prop("checked", true);
            } else {
                box.prop("checked", false);
            }
            // Set to form data
            setForm({
                ...form,
                gender: {
                    name: box.attr('id'),
                    checked: box.prop('checked'),
                },
            })
        });

        // Register [user]
        var errorText = $('.errorInfoLI_tLIB1.signup');
        if (token) {
            setError('Veuillez patienter...');
            errorText.css('color', '#707070');
            // Call API
            axios({
                method: 'POST',
                url: `https://api.snot.fr/v${API_VERSION}/register`,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
                data: { ...form, params: { lng: i18n.resolvedLanguage } }
            })
            .then(async (response) => {
                var getIP = undefined;
                    getIP = await axios.get('https://jsonip.com', { params: {mode: 'cors'} }).then(res => res.data.ip);

                if(!response.data.error){
                    // Call API
                    axios({
                        method: 'POST',
                        url: `https://api.snot.fr/v${API_VERSION}/login`,
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { email: form.email, password: CryptoJS.SHA256(form.password).toString(), device: getOSName(), browser: getBrowserName(), ip: getIP }
                    })
                    .then((responseLogin) => {
                        // Set cookies
                        Cookies.set('loginToken', responseLogin.data.cookies.loginToken, { expires: 280, sameSite: 'Lax', secure: true });
                        Cookies.set('_theme', responseLogin.data.cookies._theme, { expires: 280, sameSite: 'Lax', secure: true });
                        // Redirect to home
                        if(searchParams.get('rel')){
                            window.location.replace(searchParams.get('rel'));
                        }else{
                            window.location.replace('/');
                        }
                    })
                }else{
                    $('.errorInfoLI_tLIB1.signup').css('color', '#db4e4e');
                    if(response.data.error === "alrTakenEmail"){
                        setError('This email address is already taken.');
                        if(i18n.resolvedLanguage === "fr"){
                            setError('Cette adresse email est déjà utilisée.');
                        }
                    }else if(response.data.error === "emailNotSent"){
                        setError('We couldn\'t send the email, please try again.');
                        if(i18n.resolvedLanguage === "fr"){
                            setError('Nous n\'avons pas pu envoyer l\'email, veuillez réessayer.');
                        }
                    }
                }
            })
        }
    }, [token, form])

    /* GOOGLE AUTH */
    // -- GENERATE RANDOM STRING (for CSRF for example) --
    String.random = function(length, characters) {
        let result = '';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
        }
        return result;
    };

    // Google OAuth
    const loginGoogle = useGoogleLogin({
        onSuccess: async ({ code }) => {
            // Set result
            var errorText = document.querySelector('.errorInfoLI_tLIB1.signup');
            if (i18n.resolvedLanguage === "fr"){
                setError('En attente d\'une réponse...');
            }else {
                setError('Waiting for an answer...');
            }
            errorText.style.color = '#707070';
            // send code
            axios.post(`https://api.snot.fr/v${API_VERSION}/auth/google`, { code: code, device: getOSName(), browser: getBrowserName() })
            .then((response) => {
                setError('');
                if (response.data.resultLogin.exist === 'yes') {
                    if (response.data.resultLogin.redirectTo === "redirectFr") {
                        i18next.changeLanguage('fr');
                    }else if(response.data.resultLogin.redirectTo === "redirectEn"){
                        i18next.changeLanguage('en');
                    }
                    // Set cookies
                    Cookies.set('logged', 1, { secure: true });
                    Cookies.set('loginToken', response.data.resultLogin.cookies.loginToken, { expires: 280, sameSite: 'Lax', secure: true });
                    Cookies.set('_theme', response.data.resultLogin.cookies._theme, { expires: 280, sameSite: 'Lax', secure: true });
                    // Redirect to home
                    if(searchParams.get('rel')){
                        window.location.replace(searchParams.get('rel'));
                    }else{
                        window.location.replace('/');
                    }
                }
            });
        },
        flow: 'auth-code',
        state: String.random(30, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"),
        select_account: true
    });

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr/register" />
                <meta name="robots" content="index,follow"/>
                <meta property="og:title" content={t('title.register')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/register"+window.location.search}/>
                <meta name="twitter:title" content={t('title.register')}/>
                <title>{t('title.register')}</title>
            </Helmet>
            <div id="defaultRegister">
                <div className="returnLI_tLIB1" style={{display: steps.return}} onClick={returnStep}><i className="fi fi-rr-arrow-left"></i></div>
                <header className="headerLogIn_mverSB1" style={{ marginTop: steps.titleMargin }}>
                    <div className={"infoGoogle_authPcVB1 "+steps.oauth}>
                        <div onClick={() => loginGoogle()} id="continueAuth" role="button">
                            <img src={gLogo} alt="Google" title="Google Logo" width={20} height={20} style={{ marginRight: 10 }} />
                            <span>{i18n.resolvedLanguage === "fr" ? "Continuer avec" :"Continue with"} Google</span>
                        </div>
                        <div id="closeBannerAuth" onClick={() => $('.infoGoogle_authPcVB1').addClass('slide-out-top')}><i className="fi fi-rr-cross"></i></div>
                    </div>
                    <img alt="SNOT" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner_black?ext=png&folder=logo"} className="logo_headerLImvB1"/>
                </header>
                <main id="SignUp">
                    <section id="LogInContainer">
                        <div className="topLogIn_mverSB1 signup">
                            <h1 className="titleLI_tLIB1 signup" style={{ marginTop: steps.titleMargin }}>{t('signup.title')}</h1>
                            <h2 className="subtitleLI_tLIB1">{t('signup.haveAcc.description')} <Link to="/" className="signUp_linkLIB1" title={t('signup.haveAcc.buttonTitleAccessibility')}>{t('signup.haveAcc.buttonTitle')}</Link></h2>
                            <p className="errorInfoLI_tLIB1 signup">{error}</p>
                        </div>
                        <div className="formLogIn_mverSB1 signup" {...(error === '' ? {style: { marginTop: 80 }}: {})}>
                            <form method="post" id="formSignUp" style={{ width: "calc(100% - 80px)" }}>
                                <div id="nameInformationsContainer" style={{display: steps.step1.name}}>
                                    <div className="firstName_fLIB1">
                                        <label className="labelName_eFormLIB1" htmlFor="givenName">{t('signup.firstName')}</label>
                                        <input className="inputName_eFormLIB1 givenName" title={t('signup.firstName')} id="givenName" type="text" name="givenName" placeholder="John" autoComplete="given-name" tabIndex={1} required
                                            onChange={(string) => {
                                                setForm({ ...form, givenName: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            }}/>
                                    </div>
                                    <div className="lastName_fLIB1" style={{ marginLeft: 10 }}>
                                        <label className="labelName_eFormLIB1" htmlFor="familyName">{t('signup.lastName')}</label>
                                        <input className="inputName_eFormLIB1 familyName" title={t('signup.lastName')} id="familyName" type="text" name="familyName" placeholder="Doe" autoComplete="family-name" tabIndex={2} required
                                            onChange={(string) => {
                                                setForm({ ...form, familyName: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            }}/>
                                    </div>
                                </div>
                                <div id="username_fLIB1" style={{display: steps.step1.username}}>
                                    <label className="labelUsername_eFormLIB1" htmlFor="username">{t('signup.username')}</label>
                                    <input className="inputUsername_eFormLIB1" title={t('signup.username')} id="username" type="text" name="username" placeholder="JohnDoe12" autoComplete="username" tabIndex={3} required
                                        onChange={(string) => {
                                            setForm({ ...form, username: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            if($('.inputUsername_eFormLIB1').css('border-color') !== "#D0D0D0" && string.target.value.trim() !== "") {
                                                $('.inputUsername_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
                                            }else{
                                                $('.inputUsername_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
                                            }
                                        }}/>
                                </div>
                                <label className="labelGender_eFormLIB1"  style={{display: steps.step1.name}}>{t('signup.gender.label')}</label>
                                <div id="genderInformationsContainer" style={{display: steps.step1.name,justifyContent:'space-between'}}>
                                    <label className="genderContainer-infoSUpPCvB1" tabIndex={4}>
                                        <input type="checkbox" title={t('signup.gender.male')} id="male" name="gender" />
                                        <span className="genderButton-infoSUpPCvB1">
                                            <i className="fi fi-rr-mars" style={{ display: 'flex',height: 'min-content',marginRight: 10 }}></i> 
                                            {t('signup.gender.male')}
                                        </span>
                                    </label>
                                    <label className="genderContainer-infoSUpPCvB1" tabIndex={5}>
                                        <input type="checkbox" title={t('signup.gender.female')} id="female" name="gender" />
                                        <span className="genderButton-infoSUpPCvB1">
                                            <i className="fi fi-rr-venus" style={{ display: 'flex',height: 'min-content',marginRight: 10 }}></i> 
                                            {t('signup.gender.female')}
                                        </span>
                                    </label>
                                    <label className="genderContainer-infoSUpPCvB1" tabIndex={6}>
                                        <input type="checkbox" title={t('signup.gender.other')} id="other" name="gender" />
                                        <span className="genderButton-infoSUpPCvB1">
                                            <i className="fi fi-rr-interrogation" style={{ display: 'flex',height: 'min-content',marginRight: 10 }}></i> 
                                            {t('signup.gender.other')}
                                        </span>
                                    </label>
                                </div>
                                <p className="adviceSignUp_fPfLIB1" style={{display: steps.step1.name, alignItems: "center", marginTop: 0}}><i className="fi fi-rr-info" style={{ display: 'flex', height: "min-content", marginRight: 5 }}></i> {t('signup.gender.note')}</p>
                                <div className="birthdate_fLIB1" style={{display: steps.step2}}>
                                    <label className="labelBirthDate_eFormLIB1" htmlFor="birthdate">{t('signup.birthdate')}</label>
                                    <input className="inputBirthDate_eFormLIB1" title={t('signup.birthdate')} placeholder="jj/mm/aaaa" id="birthdate" type="date" name="birthdate" tabIndex={2} required
                                        onChange={(string) => {
                                            setForm({ ...form, birthdate: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            if($('.inputBirthDate_eFormLIB1').css('border-color') !== "#D0D0D0" && string.target.value.trim() !== "") {
                                                $('.inputBirthDate_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
                                            }else{
                                                $('.inputBirthDate_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
                                            }
                                        }}/>
                                </div>
                                <div className="email_fLIB1 signup" style={{display: steps.step2}}>
                                    <label className="labelEmail_eFormLIB1" htmlFor="email">{t('general.log.email')}</label>
                                    <input className="inputEmail_eFormLIB1 signup" title={t('general.log.email')} id="email" type="email" name="email" placeholder="john.doe@example.com" tabIndex={3} autoComplete="email" required
                                        onChange={(string) => {
                                            setForm({ ...form, email: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            if($('.inputEmail_eFormLIB1').css('border-color') !== "#D0D0D0" && string.target.value.trim() !== "") {
                                                $('.inputEmail_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
                                            }else{
                                                $('.inputEmail_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
                                            }
                                        }}/>
                                </div>
                                <div className="password_fLIB1 signup" style={{display: steps.step3}}>
                                    <label className="labelPass_eFormLIB1" htmlFor="password">{t('general.log.password')}</label>
                                    <input className="inputPass_eFormLIB1 signup" title={t('general.log.password')} id="password" type="password" name="password" placeholder="min. 8 caractères" tabIndex={1} required
                                        onChange={(string) => {
                                            setForm({ ...form, password: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            if($('.inputPass_eFormLIB1').css('border-color') !== "#D0D0D0" && string.target.value.trim() !== "") {
                                                $('.inputPass_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
                                            }else{
                                                $('.inputPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
                                            }
                                        }}/>
                                </div>
                                <div className="confpass_fLIB1" style={{display: steps.step3}}>
                                    <label className="labelConfPass_eFormLIB1" htmlFor="confpass">{t('signup.confpass')}</label>
                                    <input className="inputConfPass_eFormLIB1" title={t('signup.confpass')} id="confpass" type="password" name="confpass" required
                                        onChange={(string) => {
                                            setForm({ ...form, confirm_password: DOMPurify.sanitize(string.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) });
                                            if($('.inputConfPass_eFormLIB1').css('border-color') !== "#D0D0D0" && string.target.value.trim() !== "") {
                                                $('.inputConfPass_eFormLIB1').css({ borderColor: "#D0D0D0", color: "#000" })
                                            }else{
                                                $('.inputConfPass_eFormLIB1').css({ borderColor: "#db4e4e", color: "#db4e4e" })
                                            }
                                        }}/>
                                </div>
                                <div className="submitFGPASS_fLIB1">
                                    <button className="submitButton_fPfLIB1 continue" id="submit" type="button" title={t('general.buttons.continue')} onClick={continueStep} style={{ display: steps.continueBtn }} tabIndex={7}>{t('general.buttons.continue')}</button>
                                    <button className="submitButton_fPfLIB1 signup h-captcha" onClick={validate} id="submit" type="button" title={t('signup.buttonTitleAccessibility')} style={{ display: steps.submitBtn }} tabIndex={3}>{t('signup.buttonTitle')}</button>
                                    <HCaptcha
                                        sitekey="2edae787-0070-4f53-a63d-108a890a9fec"
                                        size="invisible"
                                        onVerify={setToken}
                                        onError={onError}
                                        onExpire={onExpire}
                                        ref={captchaRef}
                                    />
                                </div>
                                <p className="adviceSignUp_fPfLIB1" style={{display: steps.step3}}><i className="fi fi-rr-info"></i> {t('signup.advice.part1')} <a title={t('footer.tos')} href={i18n.resolvedLanguage === "fr" ? 'https://about.snot.fr/fr/tos' : 'https://about.snot.fr/en/tos'} hrefLang={i18n.resolvedLanguage} tabIndex={6}>{t('footer.tos')}</a> {t('signup.advice.part2')} <a href={i18n.resolvedLanguage === "fr" ? 'https://about.snot.fr/fr/privacy' : 'https://about.snot.fr/en/privacy'} hrefLang={i18n.resolvedLanguage} title={t('footer.privacy')} tabIndex={7}>{t('footer.privacy')}</a>.</p>
                            </form>
                        </div>
                    </section>
                </main>
                <div className="legalhCaptcha_pcverSB1" style={{display:'none'}}>
                    <span>
                        This site is protected by hCaptcha and its
                        <a href="https://www.hcaptcha.com/privacy"> Privacy Policy</a> and
                        <a href="https://www.hcaptcha.com/terms"> Terms of Service</a> apply.
                    </span>
                </div>
                <footer className="footerLogIn_mverSB1">
                    <div className="text_fLIB1">
                        <p>
                            {t('general.log.dontWantSignup')} <Link to="/home" className="accessSNOT_txtFooLIB1" title={t('general.log.accessSNOT')}>{t('general.log.accessSNOT')}</Link>
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}
 
export default Register;