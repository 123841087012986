import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import $ from 'jquery';
import DOMPurify from "dompurify";
import Cookies from "js-cookie";
import InfiniteScroll from "react-infinite-scroll-component";
import Html2React from "html-to-react";
// import i18next
import { useTranslation, Trans } from 'react-i18next';
import i18n from "../../../i18n/i18n";
// import Components
import { Appbar, Navbar, NotLoggedBanner, StreakProfile, TrendsContainer } from '../../../Components/Common';
import { PostLiked } from "../../../Components/Pages/Home";
import { LoadingDefault } from '../../../Components/Loading/LoadingDefault';
import PostLoading, { PostLoadingWithMedia } from "../../../Components/Loading/LoadingPost";
import { ProfileLinks, ActionsButtons, BlockButton } from '../../../Components/Pages/Profile';
import { EditProfile } from "../../../Components/Modals/Profile/Edit";
import LikeAnimation from "../../../Hooks/likeAnimation";
// import Modals
import { FollowersModal, SubsModal } from "../../../Components/Modals/Profile/SubsFollowsList";
import BlockModal from "../../../Components/Modals/Profile/Block";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
import * as User from "../../../Components/Functions/Profile";
import { openModal } from "../../../Components/Functions/Modal";
import * as Settings from '../../../Components/Functions/Settings';
 // import Auth
import { retreiveInfos, retreiveAbout } from "../../../Components/Functions/Auth";

const ProfileLiked = () => {
    const { t } = useTranslation();
    const { user } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // Redirect to @[user]
    if(!user.includes('@')) {
        navigate('/@'+user+'/liked');
    }
    
    // Set req limit
    const [reqLimit, setReqLimit] = useState(0);
    const [limit, setLimit] = useState(10);
    // Set data
    const [logged, setLogged] = useState(0);
    const [loggedClass, setLoggedClass] = useState('not-logged');
    const [loading, setLoading] = useState(true);
    const [loadingImage, setLoadingImage] = useState(true);
    const [loadingButtons, setLoadingButtons] = useState(true);
    const [data, setData] = useState([]);
    const [settings, setSettings] = useState([]);
    const [found, setFound] = useState(false);
    const [blocked, setBlocked] = useState(false);
    // For user data
    const [infos, setInfos] = useState([]);
    const [stats, setStats] = useState({
        followers: "-",
        subs: "-"
    });
    const [lists, setLists] = useState({
        followers : [],
        subs: [],
    })
    const [myInfos, setMyInfos] = useState([]);
    const [about, setAbout] = useState([]);
    // For session data
    const [session, setSession] = useState('other');
    const [haveLinks, setHaveLinks] = useState(0);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // For streak data
    const [logs, setLogs] = useState([]);

    // Set logged
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
            setLoggedClass('logged');
        }
    }, [])

    // GET USER LIKED POST
    const getUserLikedPosts = async (uid,myUID) => {
        const posts = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/posts/${uid}/profile/liked`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    theme: theme.replace('Theme', ''),
                    uid: myUID,
                    lang: i18n.resolvedLanguage,
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }
        ).then(res => res.data);

        setData(posts)
    }

    // GET NEW POSTS
    const getNewPosts = async (uid, myUID=undefined, initReq, initLimit) => {
        const posts = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/posts/${uid}/profile/liked`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    uid: myUID,
                    theme: theme.replace('Theme', ''),
                    lang: i18n.resolvedLanguage,
                    app: 'web',
                    /* FOR INFINITE SCROLL */
                    req: initReq,
                    limit: initLimit
                }
            }
        ).then(res => res.data.result);

        setData({result: data.result.concat(posts)});
    }

    // Verify if [user] has blocked [user_2]
    const blockedUser = (uid, blocked) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${blocked}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    /*
     * Posts loading
     */
    const PostsLoading = () => {
        return (
            <>
                <PostLoading widthUser={185} widthUserTag={225} />
                <PostLoading widthUser={120} widthUserTag={170} />
                <PostLoadingWithMedia widthUser={250} widthUserTag={300} />
                <PostLoading widthUser={150} widthUserTag={200} />
            </>
        )
    }

    /* -----------------
     * To build the post
     * -----------------
     */
    const UserLikedPosts = () => {
        if(data.result === null){
            return(
                <>
                    <p className={"text_frFPB1 "+theme} style={{ textAlign: "center", marginTop: 50 }}>
                        <span className="texton_frPFPB1">{t('profile.error.noLiked.title')}</span><br/>
                        {t('profile.error.noLiked.subtitle')}
                    </p>
                </>
            );
        }else{
            if (data.result !== undefined) {
                return(
                    <InfiniteScroll
                        dataLength={data.result.length}
                        next={loadMore}
                        hasMore={data.result.length !== limit ? false: true}
                        loader={<PostsLoading />}
                        scrollableTarget="profilePartMain"
                        endMessage={<></>}
                    >
                        {data.result.map((response) => (
                            <PostLiked
                                data={{
                                    uid: response.uid,
                                    user: infos.uid,
                                    sender: response.sender,
                                    content: response.content,
                                    content_clear: response.textonly,
                                    media: response.media,
                                    tags: response.tags,
                                    date: response.send_date,
                                    likes: response.likesCount,
                                    shares: response.sharesCount
                                }}
                                theme={theme}
                                logged={logged}
                            />
                        ))}
                    </InfiniteScroll>
                );
            }
        }
    }

    // Check streak logs for [user] logged in
    const checkStreakLogs = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/streak/logs`,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Close a notifications
    function closeNotification() {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    // Load more posts
    const loadMore = () => {
        var newReqLimit = reqLimit + 10;
        var newLimit = limit + 10;
        setReqLimit(newReqLimit);
        setLimit(newLimit);
        // Fetch new posts
        if(logged === 1 && myInfos.lenght !== 0 && infos.length !== 0){
            getNewPosts(infos.uid, myInfos.uid, newReqLimit, newLimit);
        }else{
            getNewPosts(infos.uid, undefined, newReqLimit, newLimit);
        }
    }

    useEffect(() => {
        // Retreive [user] infos
        User.retreiveUserInfos(user.replace('@', '')).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos(res);
                setFound(true);

                // Retreive [user] stats
                // -- FOLLOWERS (COUNT)
                User.getFollowersCount(res.uid).then((resFollowers) => {
                    // -- SUBS (COUNT)
                    User.getSubscriptionsCount(res.uid).then((resSubs) => {
                        setStats({ followers: resFollowers, subs: resSubs })
                    })
                })

                if(logged === 1){
                    retreiveInfos().then((resSessInfos) => {
                        // Retreive all posts published by [user]
                        getUserLikedPosts(res.uid, resSessInfos.uid);
                        // Retreive [user] followers/subs
                        // -- FOLLOWERS
                        User.getFollowers(res.uid, resSessInfos.uid).then((resFollowers) => {
                            // -- SUBS
                            User.getSubscriptions(res.uid, resSessInfos.uid).then((resSubs) => {
                                setLists({ followers: resFollowers, subs: resSubs });
                            })
                        })
                        // Retreive my data
                        setMyInfos(resSessInfos);
                        // ABOUT
                        retreiveAbout(resSessInfos.uid).then(resAbout => {
                            setAbout(resAbout);
                        })
                        // BLOCKED
                        blockedUser(resSessInfos.uid, res.uid).then(resBlock => {
                            if(resBlock !== 0){
                                setBlocked(true);
                            }
                        })
                        // ...
                        if(resSessInfos.uid === res.uid) {
                            setSession('current');
                            if(!res.link_1){
                                setHaveLinks(5);
                            }
                        }
                        setLoadingButtons(false);
                    })
                }else{
                    // Retreive all posts published by [user]
                    getUserLikedPosts(res.uid, undefined);
                    // Retreive [user] followers/subs
                    // -- FOLLOWERS
                    User.getFollowers(res.uid, undefined).then((resFollowers) => {
                        // -- SUBS
                        User.getSubscriptions(res.uid, undefined).then((resSubs) => {
                            setLists({ followers: resFollowers, subs: resSubs });
                        })
                    })
                    // Set blocked status
                    setBlocked(false);
                }

                // Get user's streak info
                // -- Check streak logs
                checkStreakLogs(res.uid).then((logs) => {
                    if(logs.success === true){
                        setLogs(logs.logs);
                    }else{
                        setLogs(logs);
                    }
                })

                // Retreive settings for [user]
                Settings.retreiveSettings(res.uid).then(resSettings => {
                    if(resSettings !== null){
                        setSettings(resSettings[0]);
                    }
                })
            }else{
                // Retreive [user] infos
                User.retreiveUserInfos(user.replace('@', '').replace('_', '.')).then((res) => {
                    if(res !== "This user doesn't exist.") {
                        setInfos(res);
                        setFound(true);

                        // Retreive [user] stats
                        // -- FOLLOWERS (COUNT)
                        User.getFollowersCount(res.uid).then((resFollowers) => {
                            // -- SUBS (COUNT)
                            User.getSubscriptionsCount(res.uid).then((resSubs) => {
                                setStats({ followers: resFollowers, subs: resSubs })
                            })
                        })

                        if(logged === 1){
                            retreiveInfos().then((resSessInfos) => {
                                // Retreive all posts published by [user]
                                getUserLikedPosts(res.uid, resSessInfos.uid);
                                // Retreive [user] followers/subs
                                // -- FOLLOWERS
                                User.getFollowers(res.uid, resSessInfos.uid).then((resFollowers) => {
                                    // -- SUBS
                                    User.getSubscriptions(res.uid, resSessInfos.uid).then((resSubs) => {
                                        setLists({ followers: resFollowers, subs: resSubs });
                                    })
                                })
                                // Retreive my data
                                setMyInfos(resSessInfos);
                                // ABOUT
                                retreiveAbout(resSessInfos.uid).then(resAbout => {
                                    setAbout(resAbout);
                                })
                                // BLOCKED
                                blockedUser(resSessInfos.uid, res.uid).then(resBlock => {
                                    if(resBlock !== 0){
                                        setBlocked(true);
                                    }
                                })
                                // ...
                                if(resSessInfos.uid === res.uid) {
                                    setSession('current');
                                    if(!res.link_1){
                                        setHaveLinks(5);
                                    }
                                }
                                setLoadingButtons(false);
                            })
                        }else{
                            // Retreive all posts published by [user]
                            getUserLikedPosts(res.uid, undefined);
                            // Retreive [user] followers/subs
                            // -- FOLLOWERS
                            User.getFollowers(res.uid, undefined).then((resFollowers) => {
                                // -- SUBS
                                User.getSubscriptions(res.uid, undefined).then((resSubs) => {
                                    setLists({ followers: resFollowers, subs: resSubs });
                                })
                            })
                            // Set blocked status
                            setBlocked(false);
                        }

                        // Get user's streak info
                        // -- Check streak logs
                        checkStreakLogs(res.uid).then((logs) => {
                            if(logs.success === true){
                                setLogs(logs.logs);
                            }else{
                                setLogs(logs);
                            }
                        })

                        // Retreive settings for [user]
                        Settings.retreiveSettings(res.uid).then(resSettings => {
                            if(resSettings !== null){
                                setSettings(resSettings[0]);
                            }
                        })
                    }
                })
            }
            setLoading(false);
        })

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // EVENT LISTENERS
        if(logged === 1 && session !== 'current'){
            if(document.getElementById('report')){
                document.getElementById('report').addEventListener('click', () => {
                    $('#moreProfile').trigger('click')
                })
            }
        }

        // -- For "More" menu
        // -- -- For share (set clipboard js)
        $('#share').off('click');
        $('#share').on('click', () => document.execCommand('copy'));
    }, [location, logged, session])

    useEffect(() => {
        // EVENT LISTENERS
        // -- For "More" menu
        // -- -- For share (set clipboard js)
        $('#share').off('click');
        $('#share').on('click', () => document.execCommand('copy'));
    }, [loading])

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://mobile.snot.fr/"+user+"/liked"} />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={"SNOT - "+user+" liked posts"}/>
                <meta property="og:url" content={"https://mobile.snot.fr/"+user+"/liked"+location.search}/>
                <meta name="twitter:title" content={"SNOT - "+user+" liked posts"}/>
                <title>{"SNOT - "+user+" liked posts"}</title>
            </Helmet>
            {
                loading === true ?
                    <LoadingDefault />
                :
                <>
                    <Appbar />
                    <Navbar />
                    <LikeAnimation />
                    <div id="mBLogged" className={theme+" error"} style={{display: "none"}}>
                        <div id="textN_nMAPPb1">
                            <p>Erreur</p>
                        </div>
                        <div className="iconClose" onClick={closeNotification}><i className="fi fi-sr-cross"></i></div>
                    </div>
                    {
                        found === true ?
                        <>
                        {
                            session === "current" && myInfos.length !== 0 && about.length !== 0 ?
                                <EditProfile user={myInfos} about={about} theme={theme} />   
                            : ''
                        }
                        <main id="profilePartMain">
                            <section id="profilePart_Profile" className={theme}>
                                <div className="topPart_profileMVB1">
                                    <div className={"banner_tPProfileMVB1 "+theme} style={{ backgroundColor: infos.banner }}>
                                        {
                                            logged === 1 ?
                                                session === "current" ?
                                                <div id="moreButtons" style={{ display: "flex", marginLeft: "auto", width: "fit-content", }}>
                                                    <div className={"moreBtn_profileMVB1 "+theme} tabIndex={1} data-container="moreProfile">
                                                        <i className="fi fi-sr-menu-dots" data-container="moreProfile"></i>
                                                    </div>
                                                    <div className={"moreBtn_profileMVB1 "+theme} role="button" tabIndex={2} onClick={() => navigate('/settings')}>
                                                        <i className="fi fi-rr-settings"></i>
                                                    </div>
                                                </div>
                                                :
                                                <div id="moreButtons" style={{ display: "flex", marginLeft: "auto", width: "fit-content", }}>
                                                    <div className={"moreBtn_profileMVB1 "+theme} tabIndex={1} data-container="moreProfile">
                                                        <i className="fi fi-sr-menu-dots" data-container="moreProfile"></i>
                                                    </div>
                                                </div>
                                            :
                                            <div id="moreButtons" style={{ display: "flex", marginLeft: "auto", width: "fit-content", }}>
                                                <div className={"moreBtn_profileMVB1 "+theme} tabIndex={1} data-container="moreProfile">
                                                    <i className="fi fi-sr-menu-dots" data-container="moreProfile"></i>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={"profileInfo_tPProfileMVB1 "+theme}>
                                        <div className={loadingImage ? "avatar skeleton "+theme: "avatar "+theme }>
                                            <img alt="Avatar" title="Avatar" src={infos.avatar} className="avatar_pPSMaMvB1" onError={(e) => {
                                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                    e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                                }
                                            }} style={loadingImage ? {display: "none"}: {}} onLoad={() => setLoadingImage(false)} referrerPolicy="no-referrer" />
                                        </div>
                                        <div className={"user "+theme} style={{ width:'auto', height:'auto' }}>
                                            <span id="username">{infos.username}</span>
                                        </div>
                                        <div className={"usertag "+theme} style={{width:'auto', height:'auto',marginTop: -5}}>
                                            <span id="usertag">@{infos.usertag.toLowerCase().replace('.', '_')}</span>
                                        </div>
                                    </div>
                                    {
                                        (infos.length !== 0 && blocked !== null) ?
                                        <ActionsButtons params={{session: session, infos: infos, loading: loadingButtons, blocked: blocked, logged: logged}} theme={theme} />
                                        :
                                        <></>
                                    }
                                    <div className={"aboutMe_tPProfileMVB1 about "+theme} {...((logged === 1 && session === 'current') ? {style:{height:'auto', marginBottom: haveLinks, marginTop: 45}}: {style:{height:'auto', marginBottom: haveLinks}})}>
                                        <span>{Html2React.Parser().parse(DOMPurify.sanitize(infos.aboutme,  {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}))}</span>
                                    </div>
                                    <ProfileLinks infos={infos} />
                                    <div id="followCountProfile">
                                        <div className={"followersCount_profileMVB1 following "+theme} onClick={() => {
                                            document.querySelector('.subsFllwList_mAppMVB1.subs').style.display = "block";
                                            document.querySelector('.bottomNavBar_mverSB1').style.zIndex = "100";
                                            document.body.style.overflow = "hidden";
                                        }} style={{ display: "flex" }}>
                                            <span id="title">{t("profile.subs")}</span>
                                            <div className="separator_fCPMVB1"></div>
                                            <span id="count">{stats.subs}</span>
                                        </div>
                                        <div className={"followersCount_profileMVB1 follower "+theme} onClick={() => {
                                            document.querySelector('.subsFllwList_mAppMVB1.followers').style.display = "block";
                                            document.querySelector('.bottomNavBar_mverSB1').style.zIndex = "100";
                                            document.body.style.overflow = "hidden";
                                        }} style={{ display: "flex" }}>
                                            <span id="title">Followers</span>
                                            <div className="separator_fCPMVB1"></div>
                                            <span id="count">{stats.followers}</span>
                                        </div>
                                        <StreakProfile logs={logs} theme={theme} />
                                    </div>
                                    <div className={"createDate_tPProfileMVB1 datecreate "+theme} style={{width:'auto', height:'auto'}}>
                                        <span id="createdDate" style={{ marginRight: 10 }}>{t('profile.accountCreated')} {new Date(infos.date_account).toLocaleDateString(i18n.resolvedLanguage, { day:'2-digit',month:'2-digit',year: '2-digit' })}</span>
                                    </div>
                                </div>
                            </section>
                            <section id="profileBar" className={"profileBar_mverSB1 "+theme}>
                                <div className={"others_pBmVeB1 "+theme}>
                                    <Link to={!user.includes('@') ? '/@'+user: '/'+user}>
                                        <div id="myspost">
                                            <span>Spost</span>
                                            <i className="fi fi-rr-list"></i>
                                        </div>
                                    </Link>
                                    <Link to={!user.includes('@') ? '/@'+user+'/reposts': '/'+user+'/reposts'}>
                                        <div id="respost">
                                            <span>Re-spost</span>
                                            <i className="fi fi-rr-refresh"></i>
                                        </div>
                                    </Link>
                                    <div id="liked" className="current">
                                        <span>{t('profile.menu.liked')}</span>
                                        <i className="fi fi-sr-heart"></i>
                                    </div>
                                </div>
                            </section>
                            <section id="spostPart">
                                <div className={"spostPart_profileMVB1 "+theme}>
                                    <div className="mainPart_mAppMVB1">
                                        {
                                            session !== "current" ?
                                                blocked === false ?
                                                    (!data.message && (data.message === null || data.message === undefined)) ?
                                                        settings.privacy_setting1 === "Public" ?
                                                        <UserLikedPosts />
                                                        :
                                                        <>
                                                        <p className="text_frFPB1" style={{ textAlign: "center", marginTop: 50 }}>
                                                            <span className="texton_frPFPB1" style={{display: "flex",justifyContent: "center",marginBottom: 10}}>{t('profile.error.private.title')}<i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",marginLeft: 10}}></i></span>
                                                            {t('profile.error.private.liked.private')}
                                                        </p>
                                                        </>
                                                    :
                                                    <>
                                                    <p className="text_frFPB1" style={{ textAlign: "center", marginTop: 50 }}>
                                                        <span className="texton_frPFPB1" style={{display: "flex",justifyContent: "center",marginBottom: 10}}>{t('profile.error.private.title')}<i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",marginLeft: 10}}></i></span>
                                                        {t('profile.error.private.description_liked')}
                                                    </p>
                                                    </>
                                                :
                                                <>
                                                <p className="text_frFPB1" style={{ textAlign: "center", marginTop: 50 }}>
                                                    <span className="texton_frPFPB1" style={{display: "flex",justifyContent: "center",marginBottom: 10}}>{t('profile.error.blocked.title')}<i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",marginLeft: 10}}></i></span>
                                                    {t('profile.error.blocked.description_liked')}
                                                </p>
                                                </>
                                            :
                                            <UserLikedPosts />
                                        }
                                    </div>
                                </div>
                                <TrendsContainer theme={theme} />
                            </section>
                        </main>
                        </>
                        :
                        <main id="spostPart" style={{top: 100, height: "calc(100% - 170px)", display: "flex",position: "absolute",left: 0,right: 0}}>
                            <div className="spostPart_profileMVB1" style={{margin: 0,border: 0}}>
                                <div className="mainPart_mAppMVB1" style={{display:"flex",justifyContent:"center",margin: 0}}>
                                    <p className="text_frFPB1" style={{textAlign: "center"}}>
                                        <i className="fi fi-rr-cross-circle" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:48,justifyContent: "center"}}></i>
                                        <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.accountNotExist.title')}</span>
                                        {t('profile.error.accountNotExist.description')}
                                    </p>
                                </div>
                            </div>
                        </main>
                    }
                    {/* BLOCK & UNBLOCK MODAL */}
                    {
                        (found === true && logged === 1 && session !== 'current' && infos.length !== 0 && myInfos.length !== 0) ?
                        <BlockModal uid={myInfos.uid} user={infos.uid} theme={theme} />
                        : <></>
                    }
                    {/* More Menu */}
                    {
                        found === true ?
                        <aside id="moreProfile" className="menu darkTheme" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                            <div className="menu-wrapper js-menu-stop" style={{ width: "100%" }}>
                                <div className="profilePart_moreFriendsMMVB1" style={{maxWidth:"100%", display: "flex"}}>
                                    <div className={loadingImage ? "avatar skeleton "+theme: "avatar "+theme }>
                                        <img alt={"@"+infos.usertag.toLowerCase().replace('.', '_')} title={infos.username} src={infos.avatar} className="avatar_profilePpPreviewB1" onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                            }
                                        }} style={loadingImage ? {display: "none"}: {}} onLoad={() => setLoadingImage(false)} referrerPolicy="no-referrer" />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", marginLeft: 10 }}>
                                        <p id="username" style={{maxWidth:"100%",margin:0}}>{infos.username}<i className="fi fi-sr-badge-check"></i></p>
                                        <p id="usertag" style={{margin:0}}>@{infos.usertag.toLowerCase().replace('.', '_')}</p>
                                    </div>
                                </div>
                                <div className="actionsPart_spostMMVB1">
                                    {
                                        logged === 1 ?
                                            (session !== 'current' && infos.length !== 0 && myInfos.length !== 0) ?
                                                <>
                                                <div id="share"><span>{t('comment.share')}</span><i className="fi fi-rr-share"></i></div>
                                                <div id="report" data-modal="reportPopup" onClick={openModal}><span data-modal="reportPopup">{t('general.report.button')}</span><i className="fi fi-rr-flag" data-modal="reportPopup"></i></div>
                                                <BlockButton uid={myInfos.uid} user={infos.uid} blocked={blocked} />
                                                </>
                                            :
                                                <>
                                                <div id="share"><span>{t('comment.share')}</span><i className="fi fi-rr-share"></i></div>
                                                </>
                                        :
                                            <>
                                            <div id="share"><span>{t('comment.share')}</span><i className="fi fi-rr-share"></i></div>
                                            </>
                                    }
                                </div>
                            </div>
                        </aside>
                        : <></>
                    }
                    {/* Followers/Subscriptions List Modal */}
                    {
                        (lists.followers.length !== 0 && lists.subs.length !== 0) ?
                        <>
                        <FollowersModal user={infos} list={lists.followers} />
                        <SubsModal user={infos}  list={lists.subs} />
                        </>
                        : ''
                    }
                </>
            }
        </>
    );
}
 
export default ProfileLiked;