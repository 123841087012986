import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
// import i18n
import i18n from './i18n/i18n'

// init fallback languages
i18n.init({
	debug: false,
	supportedLngs: ["en", "fr", "jp"],
	fallbackLng: ["en", "fr", "jp"],
	preload: ["en", "fr", "jp"]
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
		<Main />
    </React.StrictMode>
);