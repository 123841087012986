// import Images
import errorMediaDark from '../../../img/error/media_dark.png';
import errorMediaLight from '../../../img/error/media_light.png';

export default function SeeMedia({type: { video, image }, media, theme}) {
    // BUILD THE SEE MEDIA COMPONENT
    return (
        <>
            <div className="seeMedia_mAppMVB1">
                <div className="actions_sMappMVeB1">
                    <div id="save" className='downloadMedia' title="Save" role="button">
                        <i className="fi fi-rr-download"></i>
                    </div>
                    <div id="close" onClick={() => {
                        document.querySelector('.seeMedia_mAppMVB1').style.display = "none";
                        document.querySelector('.mediaImage_sMappMVeB1').style.display = "none";
                        document.querySelector('.mediaVideo_sMappMVeB1').style.display = "none";
                        document.body.style.overflow = "auto";
                    }}>
                        <i className="fi fi-rr-cross"></i>
                    </div>
                </div>
                {/* Video */}
                <div className="mediaVideo_sMappMVeB1" style={{ display: video }}>
                    <div className="videoContainer_videoAMB1">
                        <video id="videoPlayer" src={media} preload="metadata"></video>
                        <div className="overlay">
                            <div className="play-pause-button"><i className="fi fi-sr-play"></i></div>
                            <div className="bottom_bar">
                                <div className="playpauseBtn_bBarB1"><i className="fi fi-sr-play"></i></div>
                                <div className="progressBar_bBarB1">
                                    <div className="progress-bar-fill"></div>
                                </div>
                                <div className="volumeControl_bBarB1" style={{marginRight: 20}}>
                                    <div className="volume-icon"><i className="fi fi-ss-volume"></i></div>
                                    <input type="range" className="volume-slider" min="0" max="1" step="0.05" value="0.5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Image */}
                <div className="mediaImage_sMappMVeB1" style={{ display: image }}>
                    <img id="mediaImg" src={media} alt="Image" title={media}
                        onError={({ currentTarget }) => {
                            currentTarget.src = errorMediaLight;
                            if(theme === "darkTheme"){
                                currentTarget.src = errorMediaDark;
                            }
                        }}
                        referrerPolicy="no-referrer"
                    />
                </div>
            </div>
        </>
    );
}