import API_VERSION from './ApiVersion';
import changeMedia from './ChangeMedia';

// TO OPEN DRAWER MENU
const openDrawer = () => {
    var wrapper = document.querySelector('.container_mDrawerMVB1');
    var wrapperMenu = document.getElementById('menuDrawer');
    
    if (wrapper.style.display == "none") {
        wrapper.style.display = "block";
        wrapperMenu.style.transform = "none";
    }else {
        wrapper.style.display = "none";
        wrapperMenu.style.transform = "translate(-999%, 0)";
    }
}

// SEE MEDIA
const downloadMedia = async (src) => {
    const response = await fetch(src);
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
  
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = undefined;
  
    document.querySelector('.actions_sMappMVeB1').appendChild(anchorElement);
    anchorElement.click();
  
    document.querySelector('.actions_sMappMVeB1').removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
}

const seeMedia = (link, type) => {
    var background = document.querySelector('.seeMedia_mAppMVB1');
    var video = document.querySelector('.mediaVideo_sMappMVeB1');
    var image = document.querySelector('.mediaImage_sMappMVeB1');

    background.style.display = "block";
    document.body.style.overflow = "hidden";
    if (type == "image") {
        image.style.display = "flex";
        video.style.display = "none";

        var img = document.getElementById('mediaImg');
        img.src = link;
        img.setAttribute('title', link);
        img.setAttribute('alt', link);

        var download = document.querySelector('#save.downloadMedia');
            download.removeEventListener('click');
            download.addEventListener('click', () => downloadMedia(link));
    }else {
        video.style.display = "flex";
        image.style.display = "none";

        var video = document.getElementById('videoPlayer');
        video.src = link;

        var download = document.querySelector('#save.downloadMedia');
            download.removeEventListener('click');
            download.addEventListener('click', () => downloadMedia(link));
    }
}

export {
    API_VERSION,
    changeMedia,
    openDrawer,
    seeMedia
}