let container = null;

export const openMenu = function(e) {
    e.preventDefault();
    container = document.getElementById(e.target.getAttribute('data-container'));
    container.style.display = null;
    container.removeAttribute('aria-hidden');
    container.setAttribute('aria-modal', 'true');
    if (container.id == "profilePreview") {
        container.setAttribute('data-user', document.getElementById("profilePart").getAttribute('data-user'))
    }
    container.addEventListener('click', closeMenu);
    if(container.querySelector('.js-menu-close-other')) {
        container.querySelectorAll('.js-menu-close-other').forEach((e) => {
            e.addEventListener('click', closeMenu);
        })
    }
    container.querySelector('.js-menu-stop').addEventListener('click', stopPropagation);
    document.querySelector('.bottomNavBar_mverSB1').style.zIndex = "100";
    document.querySelector('.headerMain_mverSB1').style.zIndex = "-1";
    if(document.querySelector('.floatingBar_mverSB1')){
        document.querySelector('.floatingBar_mverSB1').style.zIndex = "-1"
    }
    document.body.scrollTo(0,0);
    //document.body.style.overflow = "hidden";
    /*document.querySelectorAll('#mBLogged.notif').forEach(all => {
        all.style.bottom = "270px";
        all.style.top = "auto";
    });*/
}

export const closeMenu = function(e) {
    if (container === null) return;
    e.preventDefault();
    container.setAttribute('aria-hidden', 'true');
    container.removeAttribute('aria-modal');
    container.removeEventListener('click', closeMenu);
    container.querySelector('.js-menu-stop').removeEventListener('click', stopPropagation);
    const hideBottomMenu = function () {
        container.style.display = "none";
        container.removeEventListener('animationend', hideBottomMenu);
        container = null;
    }
    container.addEventListener('animationend', hideBottomMenu);
    document.querySelector('.bottomNavBar_mverSB1').style.zIndex = "2";
    document.querySelector('.headerMain_mverSB1').style.zIndex = "98";
    if(document.querySelector('.floatingBar_mverSB1')){
        document.querySelector('.floatingBar_mverSB1').style.zIndex = "98"
    }
    //document.body.style.overflow = "auto";
    /*document.querySelectorAll('#mBLogged.notif').forEach(all => {
        all.style.top = "215px";
        all.style.bottom = "auto";
    });*/
}

const stopPropagation = function(e) {
    e.stopPropagation();
}

window.addEventListener('keydown', function (e) {
    if (e.key === "Escape" || e.key === "Esc") {
        closeMenu(e);
    }
})