let modal = null;

const focusableSelector = 'button, div, a, input, textarea';
let focusables = [];
let previouslyFocusedElement = null;

export const openModal = (e) => {
    e.preventDefault();
    modal = document.getElementById(e.target.getAttribute('data-modal'));
    focusables = Array.from(modal.querySelectorAll(focusableSelector));
    previouslyFocusedElement = document.querySelector(':focus');
    if(focusables.length !== 0) focusables[0].focus();
    modal.style.display = null;
    modal.removeAttribute('aria-hidden');
    modal.setAttribute('aria-modal', 'true');
    modal.addEventListener('click', closeModal);
    if(modal.querySelector('.js-modal-close')){
        modal.querySelector('.js-modal-close').addEventListener('click', closeModal);
    }
    modal.querySelector('.js-modal-stop').addEventListener('click', stopPropagation);
    if(modal.querySelector('.js-modal-close-other')){
        modal.querySelectorAll('.js-modal-close-other').forEach((e) => {
            e.addEventListener('click', closeModal);
        })
    }
    // IF MODAL IS A REPLY/COMMENT ONE
    if(e.target.getAttribute('data-modal').includes('Reply') || e.target.getAttribute('data-modal').includes('Com')){
        document.getElementById('mainAppHome').removeAttribute('style');
        document.getElementById('mainAppHome').style.position = "absolute";
    }
    if(e.target.getAttribute('data-infos') === 'friends') document.getElementById('friendsPart').style.zIndex = 99;
    // ...
    window.scrollTo(0, 0);
}

const closeModal = (e) => {
    if (modal === null) return;
    if (previouslyFocusedElement !== null) previouslyFocusedElement.focus();
    e.preventDefault();
    modal.setAttribute('aria-hidden', 'true');
    modal.removeAttribute('aria-modal');
    modal.removeEventListener('click', closeModal);
    if(modal.querySelector('.js-modal-close')){
        modal.querySelector('.js-modal-close').removeEventListener('click', closeModal);
    }
    modal.querySelector('.js-modal-stop').removeEventListener('click', stopPropagation);
    if(modal.querySelector('.js-modal-close-other')){
        modal.querySelectorAll('.js-modal-close-other').forEach((e) => {
            e.removeEventListener('click', closeModal);
        })
    }
    const hideModal = function () {
        modal.style.display = "none";
        modal.removeEventListener('animationend', hideModal);
        modal = null;
    }
    modal.addEventListener('animationend', hideModal);
    if(document.getElementById('mainAppHome')) document.getElementById('mainAppHome').style.zIndex = -1;
    if(document.getElementById('friendsPart')) document.getElementById('friendsPart').style.zIndex = -1;
}

const stopPropagation = function(e) {
    e.stopPropagation();
}

const focusIn = function (e) {
    e.preventDefault();
    let index = focusables.findIndex(f => f === modal.querySelector(':focus'));
    if (e.shiftKey === true) {
        index--;
    }
    index++;
    if (index >= focusables.length) {
        index = 0;
    }
    if (index < 0) {
        index = focusables.length - 1;
    }
    focusables[index].focus();
}

window.addEventListener('keydown', function (e) {
    if (e.key === "Escape" || e.key === "Esc") {
        closeModal(e);
    }
    if (e.key === "Tab" && modal !== null) {
        focusIn(e);
    }
})