import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
import * as Settings from '../../../Components/Functions/Settings';
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsNotifications = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    const [infos, setInfos] = useState([]);
    const [notifSettings, setNotifSettings] = useState({
        n1: {
            checked: false,
            action: 'Enabled'
        },
        n2: {
            checked: false,
            action: 'Enabled'
        },
        n3: {
            checked: false,
            action: 'Enabled'
        },
        n4: {
            checked: false,
            action: 'Enabled'
        },
        n5: {
            checked: false,
            action: 'Enabled'
        },
        n6: {
            checked: false,
            action: 'Enabled'
        },
        n7: {
            checked: false,
            action: 'Enabled'
        },
        n8: {
            checked: false,
            action: 'Enabled'
        },
        n9: {
            checked: false,
            action: 'Enabled'
        },
        n10: {
            checked: false,
            action: 'Enabled'
        },
        n11: {
            checked: false,
            action: 'Enabled'
        },
    });

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/');
        }
    }, [])

    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const save = (index, action) => {
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/notifications?token=${Cookies.get('loginToken')}&app=web`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { setting: action, index: index }
            }
        )
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive settings
        retreiveInfos().then(res => {
            setInfos(res);
            Settings.retreiveNotifSettings(res.uid).then(settings => {
                if(settings[0] !== null){
                    var notifs = {
                        notif1: 'Enabled',
                        notif2: 'Enabled',
                        notif3: 'Enabled',
                        notif4: 'Enabled',
                        notif5: 'Enabled',
                        notif6: 'Enabled',
                        notif7: 'Enabled',
                        notif8: 'Enabled',
                        notif9: 'Enabled',
                        notif10: 'Enabled',
                        notif11: 'Enabled'
                    }

                    if(settings[0].notif_setting1 === "Enabled") {
                        $('#messagesSetting').attr('checked', 'checked');
                        notifs.notif1 = "Disabled";
                    }
                    if(settings[0].notif_setting2 === "Enabled") {
                        $('#spost').attr('checked', 'checked');
                        notifs.notif2 = "Disabled";
                    }
                    if(settings[0].notif_setting3 === "Enabled") {
                        $('#follows').attr('checked', 'checked');
                        notifs.notif3 = "Disabled";
                    }
                    if(settings[0].notif_setting4 === "Enabled") {
                        $('#friendRequests').attr('checked', 'checked');
                        notifs.notif4 = "Disabled";
                    }
                    if(settings[0].notif_setting5 === "Enabled") {
                        $('#messageRequests').attr('checked', 'checked');
                        notifs.notif5 = "Disabled";
                    }
                    if(settings[0].notif_setting6 === "Enabled") {
                        $('#comments').attr('checked', 'checked');
                        notifs.notif6 = "Disabled";
                    }
                    if(settings[0].notif_setting7 === "Enabled") {
                        $('#replies').attr('checked', 'checked');
                        notifs.notif7 = "Disabled";
                    }
                    if(settings[0].notif_setting8 === "Enabled"){
                        $('#spostLike').attr('checked', 'checked');
                        notifs.notif8 = "Disabled";
                    }
                    if(settings[0].notif_setting9 === "Enabled") {
                        $('#commentsLike').attr('checked', 'checked');
                        notifs.notif9 = "Disabled";
                    }
                    if(settings[0].notif_setting10 === "Enabled"){
                        $('#repliesLike').attr('checked', 'checked');
                        notifs.notif10 = "Disabled";
                    }
                    if(settings[0].notif_setting11 === "Enabled"){
                        $('#reposts').attr('checked', 'checked');
                        notifs.notif11 = "Disabled";
                    }
                    // ...
                    setNotifSettings({
                        n1: notifs.notif1,
                        n2: notifs.notif2,
                        n3: notifs.notif3,
                        n4: notifs.notif4,
                        n5: notifs.notif5,
                        n6: notifs.notif6,
                        n7: notifs.notif7,
                        n8: notifs.notif8,
                        n9: notifs.notif9,
                        n10: notifs.notif10,
                        n11: notifs.notif11,
                    });
                }
            })
        })
    }, [localStorage.getItem("theme")])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/notifications" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.notifications')}/>
                <meta property="og:url" content={"https://snot.fr/settings/notifications"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.notifications')}/>
                <title>{t('title.settings.notifications')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section class={"topPart_setAccMVB1 "+theme}>
                        <div id="returnIcon" onClick={() => navigate('/settings')} tabIndex={1}>
                            <i class="fi fi-rr-angle-left"></i>
                        </div>
                        <h1 id="title">{t('settings.notifications.titleStrong')}</h1>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                    <div id="AccountSettings" className="privacy security notifications">
                        <div className="filterMsgSetting_accSetMVB1" style={{borderBottom: 0}}>
                            <h1 id="filterMsgSetting_accSetMVB1Label">{t('settings.notifications.titleStrong')}</h1>
                            <h2 id="subtitle" className="info_notifsSetPcVB1">{t('settings.notifications.description')}</h2>
                            <div id="filters">
                                <div id="newMessages">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.messages')}</span>
                                    <input type="checkbox" className="notifs" id="messagesSetting" name="messagesSetting"
                                    onClick={() => {
                                        save(1, notifSettings.n1);
                                        // UX
                                        var action = notifSettings.n1;
                                        if(notifSettings.n1 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n1: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="messagesSetting" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newSpost">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.spost')}</span>
                                    <input type="checkbox" className="notifs" id="spost" name="spost"
                                    onClick={() => {
                                        save(2, notifSettings.n2);
                                        // UX
                                        var action = notifSettings.n2;
                                        if(notifSettings.n2 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n2: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="spost" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newFollows">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.follows')}</span>
                                    <input type="checkbox" className="notifs" id="follows" name="follows"
                                    onClick={() => {
                                        save(3, notifSettings.n3);
                                        // UX
                                        var action = notifSettings.n3;
                                        if(notifSettings.n3 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n3: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="follows" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newFriendsRequests">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.friend_request')}</span>
                                    <input type="checkbox" className="notifs" id="friendRequests" name="friendRequests"
                                    onClick={() => {
                                        save(4, notifSettings.n4);
                                        // UX
                                        var action = notifSettings.n4;
                                        if(notifSettings.n4 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n4: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="friendRequests" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newMessagesRequests">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.message_requests')}</span>
                                    <input type="checkbox" className="notifs" id="messageRequests" name="messageRequests"
                                    onClick={() => {
                                        save(5, notifSettings.n5);
                                        // UX
                                        var action = notifSettings.n5;
                                        if(notifSettings.n5 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n5: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="messageRequests" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newComments">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.comments')}</span>
                                    <input type="checkbox" className="notifs" id="comments" name="comments"
                                    onClick={() => {
                                        save(6, notifSettings.n6);
                                        // UX
                                        var action = notifSettings.n6;
                                        if(notifSettings.n6 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n6: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="comments" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newReplies">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.replies')}</span>
                                    <input type="checkbox" className="notifs" id="replies" name="replies"
                                    onClick={() => {
                                        save(7, notifSettings.n7);
                                        // UX
                                        var action = notifSettings.n7;
                                        if(notifSettings.n7 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n7: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="replies" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newReposts">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.reposts')}</span>
                                    <input type="checkbox" className="notifs" id="reposts" name="reposts"
                                    onClick={() => {
                                        save(11, notifSettings.n11);
                                        // UX
                                        var action = notifSettings.n11;
                                        if(notifSettings.n11 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n11: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="reposts" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newLikeSpost">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.spost_like')}</span>
                                    <input type="checkbox" className="notifs" id="spostLike" name="spostLike"
                                    onClick={() => {
                                        save(8, notifSettings.n8);
                                        // UX
                                        var action = notifSettings.n8;
                                        if(notifSettings.n8 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n8: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="spostLike" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newLikeComs">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.comments_like')}</span>
                                    <input type="checkbox" className="notifs" id="commentsLike" name="commentsLike"
                                    onClick={() => {
                                        save(9, notifSettings.n9);
                                        // UX
                                        var action = notifSettings.n9;
                                        if(notifSettings.n9 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n9: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="commentsLike" style={{cursor:"pointer"}}></label>
                                </div>
                                <div id="newLikeReplies">
                                    <span id="labelTextFilters">{t('settings.notifications.filters.replies_like')}</span>
                                    <input type="checkbox" className="notifs" id="repliesLike" name="repliesLike"
                                    onClick={() => {
                                        save(10, notifSettings.n10);
                                        // UX
                                        var action = notifSettings.n10;
                                        if(notifSettings.n10 === "Enabled"){
                                            action = "Disabled";
                                        }else{
                                            action = "Enabled";
                                        }
                                        setNotifSettings({
                                            ...notifSettings,
                                            n10: action
                                        })
                                    }}
                                    />
                                    <label htmlFor="repliesLike" style={{cursor:"pointer"}}></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsNotifications;