import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import $ from 'jquery';
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
// import Functions
import { API_VERSION } from "../../../Components/Functions";
import { openModal } from "../../../Components/Functions/Modal";
import * as Settings from "../../../Components/Functions/Settings";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsSafety = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // set data
    const [error, setError] = useState('');
    const [infos, setInfos] = useState([]);
    const [settings, setSettings] = useState([]);
    const [nsfw, setNSFW] = useState(t('settings.safety.nsfw.description_no'))
    const [messages, setMessages] = useState(t('settings.safety.messages.description_no'))
    // Set theme
    const [theme, setTheme] = useState('darkTheme');

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/');
        }
    }, [])

    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    // CALCULATE AGE -- IMPORTANT
    function calculateAge(date) { 
        var diff_ms = Date.now() - date.getTime();
        var age_dt = new Date(diff_ms); 
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    

    const save = () => {
        var nsfw = $('.selectBox_visSetMVB1.nsfw').find(":selected").attr('name');
        var receivemsg = $('.selectBox_visSetMVB1.rm').find(":selected").attr('name');
        // For filters
        var onlyImages = "Disabled";
        var allowInsults = "Disabled";
        if($("#images").is(':checked')) onlyImages = "Enabled";
        if ($("#insults").is(':checked')) allowInsults = "Enabled";
        // UI / UX
        $('#infoSave').css({ display: "block" });
        $('.updateBtn_tPSAMVB1').css({ marginLeft: "0" });
        // ...
        // -- FOR NSFW
        if(nsfw !== "" && nsfw !== settings.security_setting2){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'security_setting2', term: nsfw, age: calculateAge(new Date(infos.birthdate)) }
                }
            ).then(res => {
                if(res.data.error && res.data.error === "Unauthorized"){
                    $('#infoSave').css({ opacity: 0 });
                    setError('You must be at least 18 years old to change this setting.');
                    if(i18n.resolvedLanguage === "fr"){
                        setError('Vous devez avoir au moins 18 ans pour modifier ce paramètre.');
                    }
                    $('.visibilitySetting_accSetMVB1 .error').css('color', '#db4e4e');
                }else{
                    $('#infoSave').css({ opacity: 1 });
                    setError('');
                }
            })
        }else if(nsfw === settings.security_setting2) setError('');
        // -- FOR MESSAGES RECEIVE
        if(receivemsg !== "" && receivemsg !== settings.security_setting3){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'security_setting3', term: receivemsg }
                }
            )
        }
        // -- FOR FILTERS
        // -- -- FOR IMAGES ONLY
        if(onlyImages !== "" && onlyImages !== settings.security_setting5_sub1){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'security_setting5_sub1', term: onlyImages }
                }
            )
        }
        // -- -- FOR INSULTS
        if(allowInsults !== "" && allowInsults !== settings.security_setting5_sub2){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'security_setting5_sub2', term: allowInsults }
                }
            )
        }
    }

    const change = (vis) => {
        var iconNsfw = document.querySelector("#icon.nsfw i");
        var iconRm = document.querySelector("#icon.rm i");
        var selectBoxContainer = document.querySelector(".selectBox_visSetMVB1.rm");
        var selectBox = document.querySelector(".selectBox_visSetMVB1.rm select");

        if (vis === "yes") {
            setNSFW(t('settings.safety.nsfw.description_yes'));
            iconNsfw.className = "fi fi-sr-check-circle";
        }else if (vis === "no") {
            setNSFW(t('settings.safety.nsfw.description_no'));
            iconNsfw.className = "fi fi-sr-cross-circle";
        }else if (vis === "allow") {
            setMessages(t('settings.safety.messages.description_yes'));
            iconRm.className = "fi fi-sr-check-circle";
            selectBox.style.width = "145px";
            selectBoxContainer.style.width = "200px";
        }else if (vis === "disallow") {
            setMessages(t('settings.safety.messages.description_no'));
            iconRm.className = "fi fi-sr-cross-circle";
            selectBox.style.width = "160px";
            selectBoxContainer.style.width = "215px";
        }
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive [user] infos
        retreiveInfos().then(res => {
            setInfos(res);
            // Retreive settings
            Settings.retreiveSettings(res.uid).then(setting => {
                if(setting !== null){
                    setSettings(setting[0]);
                    // FOR NSFW SETTING
                    if(setting[0].security_setting2 === "Enabled"){
                        setNSFW(t('settings.safety.nsfw.description_yes'));
                        // UI
                        if(document.querySelector('#icon.nsfw')){
                            document.querySelector('#icon.nsfw i').className = "fi fi-ss-check-circle";
                        }
                        $('#show').attr('selected', true);
                    }else {
                        setNSFW(t('settings.safety.nsfw.description_no'));
                        // UI
                        if(document.querySelector('#icon.nsfw')){
                            document.querySelector('#icon.nsfw i').className = "fi fi-ss-cross-circle";
                        }
                        $('#notShow').attr('selected', true);
                    }
                    // FOR MESSAGES SETTING
                    if(setting[0].security_setting3 === "Enabled"){
                        setMessages(t('settings.safety.messages.description_yes'));
                        // UI
                        if(document.querySelector('#icon.rm')){
                            document.querySelector('#icon.rm i').className = "fi fi-ss-check-circle";
                        }
                        $('#allow').attr('selected', true);
                    }else {
                        setMessages(t('settings.safety.messages.description_no'));
                        // UI
                        if(document.querySelector('#icon.rm')){
                            document.querySelector('#icon.rm i').className = "fi fi-ss-cross-circle";
                        }
                        $('#disallow').attr('selected', true);
                    }
                    // FOR FILTERS SETTING
                    if(setting[0].security_setting5_sub1 === "Enabled"){
                        $('#images').attr('checked', 'checked');
                    }
                    if(setting[0].security_setting5_sub2 === "Enabled"){
                        $('#insults').attr('checked', 'checked');
                    }
                }
            })
        })
    }, [localStorage.getItem("theme"), ])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/safety" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.safety')}/>
                <meta property="og:url" content={"https://snot.fr/settings/safety"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.safety')}/>
                <title>{t('title.settings.safety')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section class={"topPart_setAccMVB1 "+theme}>
                        <div id="returnIcon" onClick={() => navigate('/settings')} tabIndex={1}>
                            <i class="fi fi-rr-angle-left"></i>
                        </div>
                        <h1 id="title">{t('settings.safety.titleStrong')}</h1>
                        <div class={"updateBtn_tPSAMVB1 fr "+theme} tabIndex={2} data-modal="confirmPassword" onClick={openModal}>{t('settings.general.saveButton')}</div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy security">
                            <div className="visibilitySetting_accSetMVB1">
                                <h1 className="title_nsfwSet-securitySetPcVB1" style={{marginBottom: 0}}>{t('settings.safety.nsfw.title')}</h1>
                                <h2 id="subtitle" className="info_nsfwSet-securitySetPcVB1">{nsfw}</h2>
                                <p className="error">{error}</p>
                                <form method="post" id="formSetVisibility">
                                    <div className="selectBox_visSetMVB1 nsfw">
                                        <div id="icon" className="nsfw"><i className="fi fi-ss-cross-circle"></i></div>
                                        <select id="select">
                                            <option value={0} id="show" name="Enabled" onClick={() => change('yes')}>{t('settings.safety.nsfw.yes')}</option>
                                            <option value={1} id="notShow" name="Disabled" onClick={() => change('no')}>{t('settings.safety.nsfw.no')}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="receiveMessagesSetting_accSetMVB1">
                                <h1 className="title_msgSet-securitySetPcVB1" style={{marginBottom: 0}}>{t('settings.safety.messages.title')}</h1>
                                <h2 id="subtitle" className="info_msgSet-securitySetPcVB1">{messages}</h2>
                                <p id="noteInfo"><i className="fi fi-rr-info"></i>{t('settings.safety.messages.note')}</p>
                                <form method="post" id="formSetReceiveMsg">
                                    <div className="selectBox_visSetMVB1 rm" style={{ width: 210 }}>
                                        <div id="icon" className="rm"><i className="fi fi-ss-cross-circle"></i></div>
                                        <select id="select" style={{ width: "auto" }}>
                                            <option value={0} id="allow" name="Enabled" onClick={() => change('allow')}>{t('settings.safety.messages.allow')}</option>
                                            <option value={1} id="disallow" name="Disabled" onClick={() => change('disallow')}>{t('settings.safety.messages.disallow')}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="filterMsgSetting_accSetMVB1" style={{borderBottom: 0}}>
                                <h1 className="title_filterSet-securitySetPcVB1">{t('settings.safety.filters.title')}</h1>
                                <div id="filters">
                                    <div id="imageOnly">
                                        <span id="labelTextFilters">{t('settings.safety.filters.images')}</span>
                                        <input type="checkbox" id="images" name="images" />
                                        <label htmlFor="images" style={{cursor: "pointer"}}></label>
                                    </div>
                                    <div id="insultsBadwords">
                                        <span id="labelTextFilters">{t('settings.safety.filters.insults')}</span>
                                        <input type="checkbox" id="insults" name="insults" />
                                        <label htmlFor="insults" style={{cursor: "pointer"}}></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsSafety;