import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Helmet from "react-helmet";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import Cookies from "js-cookie";
import {UAParser} from "ua-parser-js";
// import i18next
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
// import Functions
import { API_VERSION } from "../../Components/Functions";
// import Images
import gLogo from '../../img/oauth/google.png';
import { publicIp } from "public-ip";

const Login = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            navigate('/home');
        }
    }, [])

    // Set result text
    const [result, setResult] = useState();
    // Set URIs locals
    var localAppleURI = "en_US";

    useEffect(() => {
        document.documentElement.className = "lightTheme";
        document.body.className = "lightTheme";
        // FOR APPLE URI
        if(i18n.resolvedLanguage === "fr") { localAppleURI = "fr_FR" }
    }, [])

    // -- GENERATE RANDOM STRING (for CSRF for example) --
    String.random = function(length, characters) {
        let result = '';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
        }
        return result;
    };

    // Device & browser
    // -- Get OS name
    const getOSName = () => {
        const userAgent = navigator?.userAgent || "unknown";
        const parser  = new UAParser(userAgent);
        var device = parser.getOS().name;
        return device;
    }
    // -- Get browser name
    const getBrowserName = () => {
        const userAgent = navigator?.userAgent || "unknown";
        const parser  = new UAParser(userAgent);
        var browser = parser.getBrowser().name;
        return browser;
    };

    // Google OAuth
    const loginGoogle = useGoogleLogin({
        onSuccess: async ({ code }) => {
            var getIP = undefined;
                getIP = await axios.get('https://jsonip.com', { params: {mode: 'cors'} }).then(res => res.data.ip);
            // Set result
            var errorText = document.querySelector('.errorInfoLI_tLIB1.login');
            if (i18n.resolvedLanguage === "fr"){
                setResult('En attente d\'une réponse...');
            }else {
                setResult('Waiting for an answer...');
            }
            errorText.style.color = '#707070';
            // send code
            axios.post(`https://api.snot.fr/v${API_VERSION}/auth/google`, { code: code, device: getOSName(), browser: getBrowserName(), ip: getIP })
            .then((response) => {
                setResult('');
                if (response.data.resultLogin.exist === 'yes') {
                    if (response.data.resultLogin.redirectTo === "redirectFr") {
                        i18next.changeLanguage('fr');
                    }else if(response.data.resultLogin.redirectTo === "redirectEn"){
                        i18next.changeLanguage('en');
                    }else if(response.data.redirectTo === "redirectJp"){
                        i18next.changeLanguage('jp');
                    }
                    // Set cookies
                    Cookies.set('logged', 1, { secure: true });
                    Cookies.set('loginToken', response.data.resultLogin.cookies.loginToken, { expires: 280, sameSite: 'Lax', secure: true });
                    Cookies.set('_theme', response.data.resultLogin.cookies._theme, { expires: 280, sameSite: 'Lax', secure: true });
                    // Redirect to home
                    if(searchParams.get('rel')){
                        window.location.replace(searchParams.get('rel'));
                    }else{
                        window.location.replace('/');
                    }
                }
            });
        },
        flow: 'auth-code',
        state: String.random(30, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"),
        select_account: true
    });

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr" />
                <meta name="robots" content="index,follow"/>
                <meta property="og:title" content={t('title.login')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/"+window.location.search}/>
                <meta name="twitter:title" content={t('title.login')}/>
                <title>{t('title.login')}</title>
            </Helmet>
            <header className="headerLogIn_mverSB1 loginHome">
                <img alt="SNOT" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner_black?ext=png&folder=logo"} className="logo_headerLImvB1"/>
            </header>
            <main id="LogIn">
                <div id="loginSolutionContainer">
                    <section id="LogInContainer">
                        <div className="topLogIn_mverSB1">
                            <h1 className="titleLI_tLIB1">{t('login.title')}</h1>
                            <h2 className="subtitleLI_tLIB1">{t("login.dontHaveAcc.description")} <Link to="/register" className="signUp_linkLIB1" title={t("login.dontHaveAcc.buttonTitleAccessibility")} tabIndex="4">{t("login.dontHaveAcc.buttonTitle")}</Link></h2>
                            <p className="errorInfoLI_tLIB1 login">{result}</p>
                        </div>
                        <div className="formLogIn_mverSB1">
                            <form method="post" id="formLogIn">
                                <Link to={
                                    searchParams.get('rel') ? '/login/default?rel='+searchParams.get('rel'): '/login/default'}>
                                    <div className="oAuthButtons-logInVB1 snot" style={{ justifyContent: "center", backgroundColor: "#252525", border: "none" }}>
                                        <span style={{ fontWeight: "500", textAlign: "center", color: "#fff" }}>{i18n.resolvedLanguage === "fr" ? "Continuer avec une adresse email" :"Continue with an email address"}</span>
                                    </div>
                                </Link>
                                <div className="divider_logInVB1">{i18n.resolvedLanguage === "fr" ? "OU" :"OR"}</div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
                                    <div onClick={() => loginGoogle()} className="oAuthButtons-logInVB1" style={{ width: "100%", display: "flex",justifyContent: "center" }}>
                                        <img src={gLogo} alt="Google" title="Google Logo" width={25} height={25} />
                                        <span style={{ fontWeight: "500", marginLeft: 10 }}>{i18n.resolvedLanguage === "fr" ? "Continuer avec" :"Continue with"} Google</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </main>
            <footer className="footerLogIn_mverSB1">
                <div className="text_fLIB1">
                    <p>
                        {t('general.log.dontWantLogin')} <Link to="/home" className="accessSNOT_txtFooLIB1" title={t('general.log.accessSNOT')} tabIndex={6}>{t('general.log.accessSNOT')}</Link>
                    </p>
                </div>
            </footer>
            <script type="text/javascript" src={"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/"+localAppleURI+"/appleid.auth.js"}></script>
            <script src="https://accounts.google.com/gsi/client" async></script>
        </>
    );
}
 
export default Login;