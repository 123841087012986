import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import LoginModal from '../Modals/Common/LogIn';
import News from "../Modals/Settings/News";
// import Hooks
import { handleImageError } from "../../Hooks/handleAvatarError";
// import Functions
import { retreiveInfos } from "../Functions/Auth";
import { API_VERSION } from "../Functions";
import { openMenu } from "../Functions/Menu";

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();

    // Set HeaderView Function -- IMPORTANT
    const HeaderView = () => location.pathname;

    // Set data
    const [logged, setLogged] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notSeen, setNotSeen] = useState(0);
    const [profileContent, setProfileContent] = useState(
        <div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }>
            <i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i>
        </div>
    )
    // Set features data
    //const [backgroundSettings, setBgSettings] = useState("#26262C");
    const [update, setUpdate] = useState(false);
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');
    // Set links
    const [links, setLinks] = useState({
        friendsLink: "/login",
        chatLink: "/login",
        profileLink: "/profile",
        addLink: "/login",
    });

    // -- Set logged
    useEffect(() => {
        const loggedStatus = Cookies.get('logged') === '1';
        setLogged(loggedStatus);
        setLoading(false);

        if (loggedStatus) {
            retrieveUserData();
        } else {
            var pathname = "?rel="+HeaderView();

            setLinks({
                friendsLink: "/login"+HeaderView() !== "/" && pathname,
                chatLink: "/login"+HeaderView() !== "/" && pathname,
                profileLink: "/profile",
                addLink: "/login"+HeaderView() !== "/" && pathname,
            });

            setProfileContent(
                <div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }>
                    <i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i>
                </div>
            )
        }

        // Set theme
        const savedTheme = localStorage.getItem("theme");
        setTheme(savedTheme === "light" ? 'lightTheme' : 'darkTheme');
    }, [location, localStorage.getItem("theme")])

    const retrieveUserData = async () => {
        const savedTheme = localStorage.getItem("theme");
        const infos = await retreiveInfos();
        setNotSeen(await getNotSeenChat(infos.uid));
        setLinks({
            friendsLink: "/friends",
            chatLink: "/friends/chat",
            profileLink: "/@" + infos.usertag.toLowerCase().replace('.', '_'),
            addLink: HeaderView() !== "/" ? "/add?rel=" + HeaderView() : "/add",
        });

        setProfileContent(
            <div id="profile" className={isActive("/profile") ? "active" : ""}>
                <img
                    className="avatar_pIconMvEB1"
                    alt="avatar"
                    src={infos.avatar}
                    {...(isActive('/profile') || isActive('/@') ? {style: {outline: '2px solid', borderColor: savedTheme === "light" ? '#000': '#fff', marginBottom: 5}}: {style: {marginBottom: 5}})}
                    onError={handleImageError}
                />
            </div>
        );

        // Verify update
        axios.get(
            `https://api.snot.fr/v${API_VERSION}/new/update`,
            {
                headers:{
                    "Content-Type": "application/json",
                },
                params: { token: Cookies.get('loginToken') },
            }
        ).then((res) => {
            if(!res.data.error){
                setUpdate(true);
            }
        })
    };

    // CHECK IF MESSAGES ARE NOT SEEN
    const getNotSeenChat = async (user) => {
        const response = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/chat/all/not-seen`,
            {
                params: {
                    token: Cookies.get('loginToken'),
                    uid: user,
                    app: 'web'
                }
            }
        );
        return response.data.count;
    }

    const isActive = (path) => HeaderView() === path || HeaderView().includes(path);

    return (
        <>
        {
            logged ?
                update && (
                    <News autoShow />
                )
            : ''
        }
        <nav className={"bottomNavBar_mverSB1 "+theme}>
            <div className="actions_bNBmVeB1">
                <NavLink to="/home" index="home" icon="fi fi-rr-home" isActive={HeaderView()} />
                <NavLink to={links.friendsLink} index="friends" icon="fi fi-rr-user" isActive={isActive("/friends")} />
                <NavLink to={links.addLink} index="add" icon="fi fi-rr-add" isActive={isActive("/add")} />
                <NavLink to={links.chatLink} index="messages" icon="fi fi-rr-comment-alt" isActive={isActive("/chat")} logged={logged} notSeen={notSeen} />
                <Link to={links.profileLink}>
                    {profileContent}
                </Link>
            </div>
        </nav>
        {
            !logged &&
            <LoginModal />
        }
        </>
    );
}

const NavLink = ({ to, index, icon, isActive, logged, notSeen }) => (
    <Link to={to}>
         <div
            key={index}
            id={index}
            className={((isActive && index !== "home") || ((isActive === "/home" || isActive === "/popular" || isActive === "/subs") && index === "home")) ? "active": ''}
            onMouseEnter={() => {document.querySelector('#'+index+' i').className = icon.replace('rr-', 'sr-')}}
            onMouseLeave={() => {
                if(document.getElementById(index).className !== "active") {
                    document.querySelector('#'+index+' i').className = icon;
                }
            }}
        >
            <i className={((isActive && index !== "home") || ((isActive === "/home" || isActive === "/popular" || isActive === "/subs") && index === "home")) ? icon.replace('rr-', 'sr-') : icon}></i>
            {
                index === "messages" ?
                    logged ? notSeen === 0 ?
                    <div id="notifIndice" className="newChat-notifIndice" style={{display: "none", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>:
                    <div id="notifIndice" className="newChat-notifIndice" style={{display: "flex", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>
                    : ''
                : ''
            }
        </div>
    </Link>
);

export default Navbar;

/*const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Set HeaderView Function -- IMPORTANT
    const HeaderView = () => location.pathname;

    // Set pathname
    const [pathname, setPathname] = useState("");
    // Set data
    const [logged, setLogged] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notSeen, setNotSeen] = useState(0);
    const [profileContent, setProfileContent] = useState(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }><i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i></div>)
    // Set features data
    const [update, setUpdate] = useState(false);
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');
    const [borderColor, setBorderColor] = useState("#fff");
    // Set links
    const [friendsLink, setFriendsLink] = useState("/login"+pathname);
    const [chatLink, setChatLink] = useState("/login"+pathname);
    const [profileLink, setProfileLink] = useState("/profile");
    const [addLink, setAddLink] = useState("/login"+pathname);

    // -- Set logged
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
        }
        setLoading(false);
    }, [])

    /* ------------------------------
     * CHECK IF MESSAGES ARE NOT SEEN
     * ------------------------------
     */
    /*const getNotSeenChat = (user) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/chat/all/not-seen`,
            params: {
                token: Cookies.get('loginToken'),
                uid: user,
                app: 'web'
            }
        }).then(res => {
            setNotSeen(res.data.count);
        })
    }

    // ...
    useEffect(() => {
        if(location.pathname !== "/"){
            setPathname("?rel="+location.pathname);
        }

        if(loading === false) {
            if (logged === 1) {
                retreiveInfos().then((infos) => {
                    // Not seen messages count
                    getNotSeenChat(infos.uid);
                    // Set links
                    setFriendsLink("/friends");
                    setChatLink("/friends/chat");
                    setProfileLink("/@"+infos.usertag.toLowerCase().replace('.', '_'));
                    if(location.pathname !== "/"){
                        setAddLink("/add?rel="+location.pathname);
                    }else{
                        setAddLink("/add");
                    }

                    // Set icon and text for profile button
                    setProfileContent(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active"}>
                        <img className="avatar_pIconMvEB1" alt="avatar" title="avatar" src={infos.avatar} {...(HeaderView().includes("/@") || HeaderView().includes("/profile") ? { style: { border: '2px solid '+borderColor} }: { style: { border: '2px solid '+borderColor} })} referrerpolicy="no-referrer" style={{marginBottom: 5}} onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v1/content/icon_profile?ext=webp';
                            }
                        }} />
                    </div>)
                })
            }else{
                // Set links
                setFriendsLink("/"+pathname);
                setChatLink("/"+pathname);
                setAddLink("/"+pathname);
                setProfileLink("/profile");
                
                // Set icon and text for profile button
                setProfileContent(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active"} onMouseEnter={() => document.querySelector('#profile i').className = "fi fi-sr-circle-user"} onMouseLeave={() => {
                    if(document.getElementById('profile').className !== "active") {
                        document.querySelector('#profile i').className = "fi fi-rr-circle-user"
                    }
                }}><i className={HeaderView().includes("/@") || HeaderView().includes("/profile") ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i></div>)
            }
        }

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
                setBorderColor('#000');
            }else{
                setTheme('darkTheme');
                setBorderColor('#fff');
            }
        }

        // For updates
        if(logged === 1){
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/new/update`,
                {
                    headers:{
                        "Content-Type": "application/json",
                    },
                    params: { token: Cookies.get('loginToken') },
                }
            ).then((res) => {
                if(!res.data.error){
                    setUpdate(true);
                }
            })
        }
    }, [loading, logged, localStorage.getItem('theme')])

    return (
        <>
        {
            logged === 1 ?
                update && (
                    <News autoShow />
                )
            : ''
        }
        <nav className={"bottomNavBar_mverSB1 "+theme}>
            <div className="actions_bNBmVeB1">
                <Link to="/home">
                    <div
                        id="home"
                        className={(HeaderView() === "/home" || HeaderView() === "/popular" || HeaderView() === "/subs") ? "active": "" }
                        onMouseEnter={() => {document.querySelector('#home i').className = "fi fi-sr-home"}}
                        onMouseLeave={() => {
                            if(document.getElementById('home').className !== "active") {
                                document.querySelector('#home i').className = "fi fi-rr-home"
                            }
                        }}
                    >
                        <i className={(HeaderView() === "/home" || HeaderView() === "/popular" || HeaderView() === "/subs") ? "fi fi-sr-home": "fi fi-rr-home" }></i>
                    </div>
                </Link>
                <Link to={friendsLink}>
                    <div
                        id="friends"
                        className={(HeaderView().includes("/friends") && !HeaderView().includes('/chat')) && "active" }
                        onMouseEnter={() => {document.querySelector('#friends i').className = "fi fi-sr-users"}}
                        onMouseLeave={() => {
                            if(document.getElementById('friends').className !== "active") {
                                document.querySelector('#friends i').className = "fi fi-rr-users"
                            }
                        }}
                    >
                        <i className={(HeaderView().includes("/friends") && !HeaderView().includes('/chat')) ? "fi fi-sr-users": "fi fi-rr-users" }></i>
                    </div>
                </Link>
                <Link to={addLink}>
                    <div
                        id="add"
                        className={HeaderView() === "/add" && "active"}
                        onMouseEnter={() => {document.querySelector('#add i').className = "fi fi-sr-add"}}
                        onMouseLeave={() => {
                            if(document.getElementById('add').className !== "active") {
                                document.querySelector('#add i').className = "fi fi-rr-add"
                            }
                        }}
                    >
                        <i className={HeaderView() === "/add" ? "fi fi-sr-add": "fi fi-rr-add" }></i>
                    </div>
                </Link>
                <Link to={chatLink}>
                    <div 
                        id="messages"
                        className={HeaderView().includes("/chat") && "active" }
                        onMouseEnter={() => {document.querySelector('#messages i').className = "fi fi-sr-comment-alt"}}
                        onMouseLeave={() => {
                            if(document.getElementById('messages').className !== "active") {
                                document.querySelector('#messages i').className = "fi fi-rr-comment-alt"
                            }
                        }}
                    >
                        <i className={HeaderView().includes("/chat") ? "fi fi-sr-comment-alt": "fi fi-rr-comment-alt" }></i>
                    </div>
                </Link>
                <Link to={profileLink}>
                    {profileContent}
                </Link>
            </div>
        </nav>
        {
            logged === 0 &&
            <LoginModal />
        }
        </>
    );
}*/