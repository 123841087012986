import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Helmet from "react-helmet";
// import Components
import { Appbar, Navbar } from '../../../../Components/Common';
// import i18next
import { useTranslation } from "react-i18next";

const SettingsAboutType = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { type } = useParams();

    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set data
    const [about, setAbout] = useState(<></>)
    
    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const closeNotifMB = () => {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    /* ABOUT CONTENT */
    // -- ToS
    const TermsOfService = () => {
        return (<>
            <section className={"topPart_setAccMVB1 "+theme}>
                <div id="returnIcon" onClick={() => navigate('/settings/about')} tabIndex={1}>
                    <i className="fi fi-rr-angle-left"></i>
                </div>
                <h1 id="title" style={{ fontSize: 16 }}>{t('settings.about.title')+' > '+t('footer.tos')}</h1>
            </section>
            <section className={"settingsPart_setMainMVB1 "+theme}>
                <div id="About">
                    <div id="titleTop">
                        <h1>{t('footer.tos')}</h1>
                        <p style={{fontSize: 18,fontWeight:500}}>Date d'application : 09/04/2023</p>
                        <p style={{fontSize: 18,fontWeight:500,marginBottom:20,marginTop:-10}}>Dernière modification : 14/07/2023</p>
                    </div>
                    <div id="sommaryPart">
                        <ul>
                            <li className="legal" onClick={() => window.location.href = '/settings/about/tos#legal'}>Mentions légales</li>
                            <li className="age" onClick={() => window.location.href = '/settings/about/tos#age'}>Âge requis</li>
                            <li className="account" onClick={() => window.location.href = '/settings/about/tos#account'}>Votre compte</li>
                            <li className="contenttos" onClick={() => window.location.href = '/settings/about/tos#content'}>Contenu SNOT</li>
                            <li className="restrictions" onClick={() => window.location.href = '/settings/about/tos#restrictions'}>Restrictions concernant l'utilisation de nos services</li>
                            <li className="removal" onClick={() => window.location.href = '/settings/about/tos#removal'}>Suppression de votre accès à nos services</li>
                            <li className="additional" onClick={() => window.location.href = '/settings/about/tos#additional'}>Informations complémentaires</li>
                        </ul>
                    </div>
                    <div id="describePart">
                        {/* MAIN TEXT CONDITIONS */}
                        <div id="general">
                            <p>
                                SNOT est un réseau social visant à réunir vos amis ou à vous en trouver d'autres.
                                <br/><br/>
                                Les conditions suivantes s’appliquent à votre utilisation de notre service.
                                <br/><br/>
                                Lorsque nous utilisons les termes « SNOT », « nous », « notre » et « nos » dans ces conditions, nous nous référons à SNOT et ses services.
                                <br/><br/>
                                Lorsque nous employons le terme « vous », « votre », « vos », nous nous adressons à vous. Si vous vous présentez comme une entité juridique (comme un employé), vous devez également vous référer à ceci.
                            </p>
                        </div>
                        {/* LEGAL */}
                        <div id="legal">
                            <p style={{fontSize: 18}}><strong>1. Mentions Légales</strong></p>
                            <p>L'édition, la modification et la direction de SNOT sont assurées par MENGUY Clément, domiciliée au 8 Hameau de Kerguily, 29170, Pleuven.
                                <br/>Dont l'adresse e-mail est : <a href="mailto:contact@snot.fr">contact@snot.fr</a>.
                                <br/><br/>
                                L'hébergeur de SNOT est la société O2SWITCH, dont le siège social est situé au CHE DES PARDIAUX 63000 CLERMONT-FERRAND.
                                <br/>Le numéro de téléphone d'O2SWITCH est 04 44 44 60 40.
                            </p>
                        </div>
                        <div id="age">
                            <p style={{fontSize: 18}}><strong>2. Âge requis</strong></p>
                            <p>L'âge requis est de minimum 13 ans pour accéder à nos services. 
                                Il peut également être de plus de 13 ans si les lois de votre pays le demandent. 
                                Par exemple, en France, l'âge minimal pour un enfant <strong>seul</strong> de s'inscrire sur un réseau social est de 15 ans.
                                Si nous vous demandons votre âge dès l'inscription c'est pour savoir si oui ou non vous avez l'âge de voir du contenu pour adultes (+18 ans).
                                Vos parents ou un tuteur légal doivent accepter que vous accédiez à nos services et doivent également lire et accepter ces conditions.
                            </p>
                        </div>
                        <div id="account">
                            <p style={{fontSize: 18}}><strong>3. Votre compte</strong></p>
                            <p>Pour accéder à certains services présentés ci-dessous, vous devrez vous créer un compte SNOT.<br/><br/>
                                Les services qui requièrent un compte valide sont les suivants :<br/>
                                <ul>
                                    <li>Publier un <u>spost</u> (nom donné aux publications sur SNOT).</li><br/>
                                    <li>Discuter en message privé avec un utilisateur sur SNOT.</li><br/>
                                    <li>Ajouter, supprimer, bloquer des amis.</li><br/>
                                    <li>Suivre un créateur ou un compte en particulier.</li><br/>
                                </ul>
                                Votre compte doit respecter certaines règles, sans quoi nous serons obligés de bannir votre compte de nos services.<br/><br/>
                                Les règles que vous devez respecter sont les suivantes :<br/>
                                <ul>
                                    <li>Votre photo de profil, votre nom d'utilisateur et votre biographie ne doivent pas être ou supposer être à caractère sexuel, offensant ou haineux envers quiconque.</li><br/>
                                    <li>Votre compte ne doit pas susciter la haine, diffuser de fausses informations ou propager des idées potentiellement dangereuses.</li><br/>
                                    <li>Votre compte ne doit pas publier de fichiers ou liens susceptibles de porter atteinte à la sécurité matérielle ou physique d'un autre utilisateur.</li><br/>
                                </ul>
                                Les règles précisées ci-dessus se doivent d'être respecté par l'ensemble des utilisateurs de nos services (compte privé ou non). Nous nous réservons le droit de bannir votre compte temporairement ou de façon permanente. Nous pouvons également limiter vos activités sur nos services si vous ne respectez pas les conditions d'utilisation.
                                <br/><br/>
                                Nous déclinons toute responsabilité en cas de perte de votre compte.
                            </p>
                        </div>
                        <div id="content">
                            <p style={{fontSize: 18}}><strong>4. Contenu de SNOT</strong></p>
                            <p>Lorsque nous parlons de « contenu », nous parlons de tout ce que vous postez, uploadez... ce contenu inclut tout ce qui suit :<br/>
                                - les textes, les images, les vidéos, les documents, les liens, les émojis, et toutes autres formes de médias.<br/><br/>
                                Vous êtes responsable du contenu que vous souhaitez publier ou poster sur nos services. Le contenu que vous publiez doit également respecter <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000357475/" target="_blank">les lois sur la propriété intellectuelle</a>.<br/><br/>
                                Nous ne supportons, n'approuvons et ne garantissons pas le contenu que vous publiez sur nos services. Si ce contenu est protégé, vous pouvez le publier, mais à vos risques et périls. Nous ne pouvons pas contrôler tout le contenu publié sur nos services, nous pouvons donc pas en assumer la responsabilité.<br/><br/>
                                Cependant, le contenu publié sur notre plateforme ne doit en <strong>aucun</strong> cas être malfaisant, offensant, haineux envers une entité physique.
                                <br/><br/>
                                La sûreté du contenu publié sur SNOT est primordiale pour pouvoir préserver votre sécurité. Si vous remarquez ou supposez un contenu de ne pas respecter nos règles, vous pouvez le <a href="https://mobile.snot.fr/fr/about/help/report/">signaler à notre support</a>.
                                <br/><br/>
                                Si vous pensez qu'un contenu publié sur SNOT est susceptible de violer les lois sur la propriété intellectuelle ou d'enfreindre les droits d'auteur, vous pouvez le <a href="https://mobile.snot.fr/fr/about/help/report/">signaler ici</a> ou bien contacter notre support à cette adresse : <a href="mailto:support@snot.fr">support@snot.fr</a>.
                            </p>
                        </div>
                        <div id="restrictions">
                            <p style={{fontSize: 18}}><strong>5. Restrictions concernant l'utilisation de nos services</strong></p>
                            <p>Nos services ne doivent pas être utilisés à certaines fins. Nos services doivent être utilisés conformément aux lois et aux règlements en vigueur, et vous devez utiliser nos services uniquement à des fins autorisées.
                                <br/><br/>
                                Vous vous devez de ne pas faire, aider ou soutenir à quiconque les règles ci-dessous :
                                <ul>
                                    <li>Ne pas faire usage de nos services afin de partager ou de publier un contenu potentiellement dangereux envers autrui ou vous-même. Par exemple, vous ne devez pas partager de liens pouvant nuire à la sécurité d'une personne (liens de phishing, sites web malveillants...).</li><br/>
                                    <li>Ne pas faire usage de nos services afin de nuire à SNOT. Vous ne devez pas être tenté ou essayer de pirater nos services afin de par exemple, extraire des données, d'insérer des codes malveillants pouvant nuire à nos services et à la sécurité de nos utilisateurs.</li><br/>
                                    <li>Ne pas faire usage de nos services pour faire un acte illégal.</li><br/>
                                </ul>
                                Si vous remarquez quelque chose de suspect par rapport à ce qui est écrit ci-dessus, n'hésitez pas à signaler le compte ou le spost.
                            </p>
                        </div>
                        <div id="removal">
                            <p style={{fontSize: 18}}><strong>6. Suppression de votre accès à nos services</strong></p>
                            <p>Nous nous réservons le droit de pouvoir limiter ou supprimer l'accès à nos services.<br/><br/>
                                Cependant, vous pouvez toujours demander l'annulation de cette suppression. Pour demander cela, vous devrez faire appel et envoyer une requête à <a href="https://mobile.snot.fr/fr/about/help/support">notre support</a>.
                                <br/><br/>
                                <u>Pourquoi mon accès à SNOT a-t-il été supprimé ?</u> Votre accès à nos services peut être supprimé pour certaines raisons, précisées ci-dessous :
                                <ul>
                                    <li>Votre compte ne respectait pas les règles le concernant. Par exemple, ce compte contenait, dans sa photo de profil du contenu à caractère sexuel.</li><br/>
                                    <li>Votre compte était suspecté d'être malveillant, haineux, etc. ou l'était.</li><br/>
                                </ul>
                                Si votre compte est inactif depuis plus d'un an, ce dernier peut être supprimé. Nous rappelons qu'avant de supprimer un compte car il est jugé <u>inactif</u>, nous envoyons un e-mail pour prévenir le détenteur de ce dernier.
                            </p>
                        </div>
                        <div id="additional">
                            <p style={{fontSize: 18}}><strong>7. Informations complémentaires</strong></p>
                            <p>Nous pouvons modifier à tout moment ces conditions, pour des raisons légales, pour assurer la sécurité de nos utilisateurs ou pour les changements apportés à nos services. Lorsque les conditions d'utilisation sont modifiées, vous en serez averti par avance.<br/><br/>
                            Ces conditions constituent un accord entre vous-même et SNOT ainsi que les services que propose la plateforme.
                            <br/><br/>
                            SNOT utilise de nombreuses libraries open source. Voici quelques unes que nous utilisons pour rendre SNOT meilleur :<br/>
                            <ul>
                                <li><a href="https://undraw.co/" target="_blank" rel="noreferrer">unDraw</a></li>
                                <li><a href="https://flaticon.com/" target="_blank" rel="noreferrer">Flaticon</a></li>
                                <li><a href="https://twemoji.twitter.com/" target="_blank" rel="noreferrer">Twemoji</a></li>
                            </ul>
                            <u>Date d'application</u> : 9 avril 2023.<br/><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }

    // -- Privacy
    const Privacy = () => {
        return (<>
            <section className={"topPart_setAccMVB1 "+theme}>
                <div id="returnIcon" onClick={() => navigate('/settings/about')} tabIndex={1}>
                    <i className="fi fi-rr-angle-left"></i>
                </div>
                <h1 id="title" style={{ fontSize: 16 }}>{t('settings.about.title')+' > '+t('footer.privacy')}</h1>
            </section>
            <section className={"settingsPart_setMainMVB1 "+theme}>
                <div id="About">
                    <div id="titleTop">
                        <h1>{t('footer.privacy')}</h1>
                        <p style={{fontSize: 18,fontWeight:500}}>Date d'application : 10/04/2023</p>
                        <p style={{fontSize: 18,fontWeight:500,marginBottom:20,marginTop:-10}}>Dernière modification : 29/09/2023</p>
                    </div>
                    <div id="sommaryPart">
                        <ul>
                            <li className="first" onClick={() => window.location.href = '/settings/about/privacy#first'}>Informations que nous collectons</li>
                            <li className="provide" onClick={() => window.location.href = '/settings/about/privacy#provide'}>Informations que vous nous fournissez</li>
                            <li className="collectes" onClick={() => window.location.href = '/settings/about/privacy#collectes'}>Informations que nous collectons</li>
                            <li className="usage" onClick={() => window.location.href = '/settings/about/privacy#usage'}>Utilisation de vos données</li>
                            <li className="release" onClick={() => window.location.href = '/settings/about/privacy#release'}>Divulgation de vos données</li>
                            <li className="storage" onClick={() => window.location.href = '/settings/about/privacy#storage'}>Conservation de vos données</li>
                            <li className="protect" onClick={() => window.location.href = '/settings/about/privacy#protect'}>Protection de vos données</li>
                            <li className="additional" onClick={() => window.location.href = '/settings/about/privacy#additional'}>Informations complémentaires</li>
                        </ul>
                    </div>
                    <div id="describePart">
                        {/* MAIN TEXT CONDITIONS */}
                        <div id="general">
                            <p>
                                SNOT est un réseau social visant à réunir vos amis ou à vous en trouver d'autres.
                                <br/><br/>
                                Comme expliqué dans nos <Link to="/settings/about/tos">conditions d'utilisation</Link>,
                                lorsque nous utilisons les termes « SNOT », « nous », « notre » et « nos » dans ces conditions, nous nous référons à SNOT et ses services.
                                <br/><br/>
                                Et lorsque nous employons le terme « vous », « votre », « vos », nous nous adressons à vous. Si vous vous présentez comme une entité juridique (comme un employé), vous devez également vous référer à ceci.
                                <br/><br/>
                                Cette politique de confidentialité vous informe et explique comment nous collectons, conservons, divulguons et protégeons vos données.
                                <ul>
                                    <li>Nous nous attachons avant tout à protéger vos données et à respecter votre vie privée.</li>
                                    <li>Nous essayons de limiter l'utilisation de vos données et de nous limiter aux données nécessaires pour créer un compte, maintenir nos services. Ainsi qu'à vous fournir le meilleur de nos services.</li>
                                    <li>Nous ne vendons et ne vendrons <u>jamais</u> vos données. Nous nous efforçons de préserver votre vie privée et de ne pas vendre vos données à des tiers.</li>
                                </ul>
                                Notre politique de confidentialité complète les conditions d’utilisation consultables a <Link to="/settings/about/tos">cette adresse</Link> (<Link to="/settings/about/tos">https://mobile.snot.fr/settings/about/tos</Link>)
                            </p>
                        </div>
                        {/* LEGAL */}
                        <div id="first">
                            <p  style={{fontSize:18}}><strong>1. Informations que nous collectons</strong></p>
                            <p>
                                Lorsque vous utilisez SNOT, nous collectons des informations. Nous recueillons notamment des informations que vous nous fournissez, des informations que nous collectons automatiquement et des informations que nous recevons des services tiers.
                            </p>
                        </div>
                        <div id="provide">
                            <p  style={{fontSize:18}}><strong>1.1. Informations que vous nous fournissez</strong></p>
                            <p>
                                Les informations que vous nous fournissez sont les suivants :<br/>
                                <ul>
                                    <li><u>Les informations liées à votre compte.</u> Lorsque vous créez un compte sur nos services, nous avons besoin de certaines informations pour certains services tels que l'authentification. Pour créer un compte, vous êtes obligé de nous fournir un nom d'utilisateur, une adresse mail valide (pour permettre par exemple de vous connecter), un mot de passe et une date de naissance pour que nous puissions savoir si vous pouvez accéder à certains contenus.</li><br/>
                                    <li><u>Le contenu que vous publiez ou postez.</u> Lorsque vous postez un message en privé ou dans les sposts (publiquement), vous nous donnez certaines informations telles que votre nom d'utilisateur, votre adresse mail et votre avatar. Quand vous souhaitez ajouter des images, vidéos ou tout autre forme de médias ou fichiers, nous collectons cela.</li><br/>
                                    <li><u>Les actions que vous faites.</u> Vous nous fournissez certaines données liées à vos actions sur nos services. Par exemple, lorsque vous aimez un spost, votre adresse mail est utilisée pour savoir si vous avez déjà aimé ce spost ou non. Ce procédé est le même pour toutes les autres actions liées aux interactions rattachées à un spost (like, partager, commenter...). Nous recueillons également vos données quand vous ajoutez un ami, bloquez ou supprimez ce dernier, mais aussi lorsque vous suivez un autre utilisateur.</li><br/>
                                </ul>
                            </p>
                        </div>
                        <div id="collectes">
                            <p  style={{fontSize:18}}><strong>1.2. Informations que nous collectons</strong></p>
                            <p>
                                Nous collectons un certain nombre de données et ce automatiquement, voici les données récoltées automatiquement :<br/>
                                <ul>
                                    <li><u>Les données de votre appareil.</u> Nous récoltons certaines données liées à votre appareil tel que votre adresse IP, votre navigateur web (Firefox, Edge, Chrome...). Cela permet à nos services de s'adapter à tout types d'appareil (par exemple lorsque SNOT doit s'adapter sur téléphone).</li><br/>
                                </ul>
                                Il s'agit des seules données qui sont collectées automatiquement pour l'instant.<br/><br/>
                                Nous utilisons très peu de cookies (petit fichier localisé sur votre appareil). À l'heure actuelle, nous utilisons seulement deux cookies, qui sont indispensables car ces derniers servent à reconnaître votre compte si vous êtes connecté et l'autre cookie sert à savoir dans quelle région vous vous situez et ainsi pouvoir traduire automatiquement SNOT.
                                <br/><br/>
                                Vous ne pouvez pas refuser d'activer ces deux cookies qui sont essentiels au bon fonctionnement de SNOT.
                            </p>
                        </div>
                        <div id="usage">
                            <p  style={{fontSize:18}}><strong>2. Utilisation de vos données</strong></p>
                            <p>
                                Nous utilisons vos données dans les buts suivants :<br/>
                                <ul>
                                    <li><u>Vous fournir nos services.</u> Vos informations sont utilisées à fin de vous fournir nos services et ce de la meilleure qualité possible. Par exemple, lorsque vous ajoutez un média à un message ou spost, nous collectons ce média afin de le traiter et de le stocker. Nous utilisons également vos données afin que vous puissiez gérer votre compte ou le créer.</li><br/>
                                    <li><u>Pour vous contacter et vous fournir un service client.</u> Vos informations nous permettent de vous contacter (par e-mail principalement). Par exemple, si vous avez activé l'A2F (Authentification à doubles facteurs), nous utilisons votre adresse mail afin de vous envoyer votre compte et de vous avertir d'une connexion. Pour vous fournir un service client, nous utilisons vos informations afin de répondre à vos questions, résoudre des bugs ou problèmes liés à nos services.</li><br/>
                                    <li><u>Pour surveiller le contenu et vous protéger.</u> Vos informations nous permettent de surveiller, modérer sur le contenu publié sur notre plateforme. Par exemple lorsqu'un compte enfreint nos <a href="?page=about&about=tos">conditions d'utilisation</a>, nous pouvons le bannir ou l'avertir depuis ses informations. Également, quand vous nous signalez un contenu qui est susceptible d'enfreindre nos <a href="?page=about&about=tos">conditions d'utilisation</a>.</li><br/>
                                </ul>
                            </p>
                        </div>
                        <div id="release">
                            <p  style={{fontSize:18}}><strong>3. Divulgation de vos données</strong></p>
                            <p>
                                Nous divulguons vos données seulement si nous le sommes obligés. Voici la liste complète des raisons qui pourraient faire en sorte que nous divulguions vos données :
                                <ul>
                                    <li><u>En cas d'urgence.</u> Vos informations peuvent être divulguées si nous considérons qu'il en dépend la sécurité d'une personne ou de vous-même.</li><br/>
                                    <li><u>Pour se conformer aux autorités d'un pays.</u> Vos informations peuvent être divulguées aux autorités d'un pays (forces de l'ordre, ...) si nous décidons qu'il en est nécessaire. Par exemple si vous êtes suspecté d'un crime dans un pays, nous pouvons nous mettre en accord avec les autorités compétentes de ce pays pour leur divulguer vos données. Bien sûr, avant de divulguer vos informations, nous essayerons de vous avertir par vos données de contact (précisée plus haut).</li><br/>
                                    <li><u>Pour faire respecter nos conditions d'utilisation ou autres règles.</u> Vos données peuvent être divulguées si nous considérons que c'est nécessaire afin de protéger nos politiques (<a href="?page=about&about=tos">conditions d'utilisation</a>, ...), et ainsi de protéger nos droits, notre sécurité et notre propriété.</li><br/>
                                    <li><u>Si vous nous le demandez.</u> Vous pouvez demander à obtenir les informations que nous stockons. Pour faire cela, vous pouvez formuler une demande auprès de <a href="https://mobile.snot.fr/help/support/">notre support</a> (<a href="https://mobile.snot.fr/fr/help/support/">https://snot.fr/help/support/</a>) ou par e-mail (<a href="mailto:support@snot.fr">support@snot.fr</a>). Nous vous donnerons ainsi toutes vos données par mail et ce au cours d'un léger temps d'attente.</li><br/>
                                </ul>
                                Certaines de vos données sont automatiquement divulguées (votre nom d'utilisateur, votre avatar). Par exemple, lorsque vous postez un <u>spost</u>, les autres utilisateurs peuvent voir votre avatar et votre nom d'utilisateur. Par ailleurs, même si votre compte est en privé, les utilisateurs peuvent accéder à votre avatar, nom d'utilisateur, nombre de suivis.
                            </p>
                        </div>
                        <div id="storage">
                            <p  style={{fontSize:18}}><strong>4. Conservation de vos données</strong></p>
                            <p>
                                Vos données sont stockées jusqu'à ce que vous décidiez de les supprimer. Par exemple, quand vous supprimez un message, ce message, qui était stocké, ne le sera plus et sera supprimé définitivement.<br/><br/>
                                En soi, vos informations peuvent être stockées pour un temps indéterminé. Si vous avez perdu l'accès à votre compte et que vous souhaitez supprimer vos données, vous pouvez contacter <a href="https://mobile.snot.fr/help/support/">le support</a>.
                            </p>
                        </div>
                        <div id="protect">
                            <p  style={{fontSize:18}}><strong>5. Protection de vos données</strong></p>
                            <p>
                                Vos données sont ce qui est de plus important pour nous. Nous prenons soin de prendre plusieurs mesures afin de les protéger. En outre, les messages privés que vous envoyez sont tous cryptés, idem pour les médias uploadés.
                                <br/><br/>
                                Notre équipe de modération n'a accès qu'à un nombre restreint de données. Votre mot de passe ne sera jamais divulgué, même si ce dernier est crypté, et nous ne demanderons <u>JAMAIS</u> votre mot de passe.
                                <br/><br/>
                                D'ailleurs, nous vous recommandons d'activer l'authentification à doubles facteurs pour plus de sécurité à votre compte.
                            </p>
                        </div>
                        <div id="general">
                            <p  style={{fontSize:18}}><strong>6. Informations complémentaires</strong></p>
                            <p>Nous pouvons modifier à tout moment cette politique, pour des raisons légales, pour assurer la sécurité des données nos utilisateurs ou pour les changements apportés à nos services. Lorsque la politique de confidentialité est modifiée, vous en serez averti par avance.<br/><br/>
                            <u>Informations pour les résidents de l'Espace économique européen (EEE), du Royaume-Uni et du Brésil</u>
                            <br/><br/>
                            Si vous résidez dans ces régions, vous êtes protégés par les lois suivantes :<br/>
                            <ul>
                                <li>Le Règlement général sur la protection des données (RGPD) pour l’Union européenne et ses pays membres.</li>
                                <li>Le Lei Geral de Proteção de Dados (LGPD) pour le Brésil.</li>
                            </ul>
                            Vous avez donc certains droits en plus, les voici :<br/>
                            <ul>
                                <li>Droit d'accès à vos données personnelles</li>
                                <li>Droit de rectification de vos données personnelles si elles sont incorrectes</li>
                                <li>Droit d'effacer vos données personnelles</li>
                                <li>Droit de limiter le traitement de vos données personnelles</li>
                                <li>Droit à la portabilité de vos données personnelles</li>
                                <li>Droit d'opposition au traitement de vos données personnelles</li>
                                <li>Droit de retirer votre consentement. Le retrait du consentement n'affecte pas le caractère licite du traitement fondé sur le consentement avant le retrait.</li>
                            </ul>
                            <br/>
                            <u>Date d'application</u> : 10 avril 2023.<br/><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }

    // -- Content
    const Content = () => {
        return (<>
            <section className={"topPart_setAccMVB1 "+theme}>
                <div id="returnIcon" onClick={() => navigate('/settings/about')} tabIndex={1}>
                    <i className="fi fi-rr-angle-left"></i>
                </div>
                <h1 id="title" style={{ fontSize: 16 }}>{t('settings.about.title')+' > '+t('footer.content')}</h1>
            </section>
            <section className={"settingsPart_setMainMVB1 "+theme}>
                <div id="About">
                    <div id="titleTop">
                        <h1>{t('footer.content')}</h1>
                        <p style={{fontSize: 18,fontWeight:500}}>Date d'application : 04/07/2023</p>
                        <p style={{fontSize: 18,fontWeight:500,marginBottom:20,marginTop:-10}}>Dernière modification : 29/09/2023</p>
                    </div>
                    <div id="sommaryPart">
                        <ul>
                            <li className="rules" onClick={() => window.location.href = '/settings/about/content#rules'}>Règles</li>
                            <li className="additional" onClick={() => window.location.href = '/settings/about/content#informations'}>Informations</li>
                        </ul>
                    </div>
                    <div id="describePart">
                        {/* MAIN TEXT CONDITIONS */}
                        <div id="general">
                            <p>
                                SNOT est un réseau social visant à réunir vos amis ou à vous en trouver d'autres. 
                                <br/><br/>
                                Comme expliqué dans nos <Link to={"/settings/about/tos"}>conditions d'utilisation</Link>, lorsque nous utilisons les termes « SNOT », « nous », « notre » et « nos » dans ces conditions, nous nous référons à SNOT et ses services.
                                <br/><br/>
                                Et lorsque nous employons le terme « vous », « votre », « vos », nous nous adressons à vous. Si vous vous présentez comme une entité juridique (comme un employé), vous devez également vous référer à ceci.
                                <br/><br/>
                                Cette politique de contenu vous explique les règles à respecter lorsque vous publiez du contenu sur nos services et vous prévient comment nous faisons respecter ces règles. Voici ci-dessous les règles que vous devez respecter avant de publier du contenu sur SNOT.
                            </p>
                        </div>
                        {/* LEGAL */}
                        <div id="rules" className="divGen_sTOSB1">
                            <p className="title_dTeTOSB1"><strong>1. Règles</strong></p>
                            <p className="subtitle_dTeTOSB1"></p>
                                <p className="title_dTeTOSB1"><strong>1.1. Règle 1</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Lorsque vous publiez un message ou un contenu quelconque sur nos services, vous devez obligatoirement respecter autrui, ne pas insulter gratuitement un utilisateur.<br/>Vous pouvez bien sûr insulter mais dans l'ordre du raisonnable.
                                    <br/><br/>Ces utilisateurs seront avertis ou verront leur compte banni en jugeant la gravité de l'insulte.<br/>
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.2. Règle 2</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Il est strictement interdit de harceler ou d'inciter à la haine ou/et au harcèlement sur autrui. Vous ne devez en aucun cas vous attaquer à une personne ou à un groupe de personnes vulnérables ou marginalisées.<br/>Tous les utilisateurs de SNOT doivent se sentir dans un espace convivial et en sécurité sans être soumis à toute forme de harcèlement.
                                    <br/><br/>Ces utilisateurs seront directement bannis de nos services.
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.3. Règle 3</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Vous ne devez pas perturber le contenu et nos services quelle qu'en soit la forme. Par exemple : le spamming, l'usage de robots pour augmenter le nombre de likes, de partages, de commentaires ou d'abonnés d'un utilisateur ou encore le contournement de bannissements.
                                    <br/>Sinon, vous serez banni de nos services.
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.4. Règle 4</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Vous ne devez en aucun cas transmettre des liens (phishing, IP Logger, ...) ou fichiers compromettants la sécurité des autres utilisateurs ou compromettant leur matériel physique (ordinateurs, téléphones, ...). Les utilisateurs transmettant ces derniers seront sévèrement punis et banni de notre plateforme. 
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.5. Règle 5</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    La pornographie enfantine (ou pédopornographie) est strictement interdite et nous ne tolérerons aucune excuse, les personnes qui transmettent de tels médias seront dénoncées aux autorités et bannies de nos services.<br/>De même, la pornographie n'est pas interdite mais doit être signalée comme "NSFW" (Not Safe For Work), sans quoi nous supprimerons le message et avertirons l'utilisateur en question.
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.6. Règle 6</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Les contenus violents, gores ou graphiques sont autorisés seulement s'ils sont publiés à titre informatif et qu'ils sont publiés par des institutions officielles ou bien qu'une demande à notre égard a été faite et que nous avons autorisé la diffusion de ce genre de contenus sur votre compte.<br/>Ce genre de contenus devra toujours être marqué comme NSFW. 
                                    <br/><br/>
                                    Si des utilisateurs venaient à enfreindre cette règle, ils seront immédiatement bannis. Néanmoins, une demande sera toujours possible pour réactiver leur compte. 
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.7. Règle 7</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Vous ne devez jamais diffuser des médias ou messages ne respectant pas la vie privée d'une personne quelconque sans que celle-ci ne soit consentante. La diffusion d'informations personnelles et/ou confidentielles d'une personne ou d'une identité officielle, est interdite.<br/>De même que la diffusion de contenus sexuellement explicites et intimes sans consentement préalable de la personne concernée, est interdite. 
                                    <br/><br/>
                                    Les utilisateurs enfreignant cette règle seront bannis de nos services.
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.8. Règle 8</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    L'utilisation de nos services doit rester légale. Ne publiez pas de contenus illégaux ou ne participez pas à en publier et ne facilitez pas des opérations illégales ou interdites.
                                    <br/><br/>
                                    Si vous publiez un contenu jugé comme illégal à nos yeux et aux yeux de la justice, ce contenu sera supprimé et vous serez banni.
                                </p>
                                <p className="title_dTeTOSB1"><strong>1.9. Règle 9</strong></p>
                                <p className="subtitle_dTeTOSB1">
                                    Ne perturbez pas l'utilisation normale de nos services et ne déclenchez/provoquez pas de dysfonctionnement de la plateforme et des services SNOT.
                                    <br/><br/>
                                    Un utilisateur perturbant SNOT sera banni et une plainte pourrait être posée.
                                </p>
                            <p></p>
                        </div>
                        <div id="infos" className="divGen_sTOSB1">
                            <p className="title_dTeTOSB1"><strong>2. Informations</strong></p>
                            <p className="subtitle_dTeTOSB1">Nous pouvons modifier à tout moment cette politique, pour des raisons légales, pour assurer la sécurité des données nos utilisateurs ou pour les changements apportés à nos services. Lorsque la politique de confidentialité est modifiée, vous en serez averti par avance.<br/><br/>
                                Nous nous réservons le droit d'appliquer les sanctions notées sous chaque règle ci-dessus.<br/>
                                <br/>
                                <u>Date d'application</u> : 4 juillet 2023.<br/><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }

    // -- Get certified
    const GetCertified = () => {
        return (<>
            <section className={"topPart_setAccMVB1 "+theme}>
                <div id="returnIcon" onClick={() => navigate('/settings/about')} tabIndex={1}>
                    <i className="fi fi-rr-angle-left"></i>
                </div>
                <h1 id="title" style={{ fontSize: 16 }}>{t('settings.about.title')+' > '+t('settings.about.get-certified')}</h1>
            </section>
            <section className={"settingsPart_setMainMVB1 "+theme}>
                <div id="About">
                    <div id="titleTop">
                        <h1>{t('settings.about.get-certified')}</h1>
                    </div>
                    <div id="describePart">
                        {/* FIRST SECTION */}
                        <div id="first">
                            <p style={{fontSize:20,fontWeight:500}}>Quelle est cette certification ?</p>
                            <p>La certification sert à reconnaître les comptes : notoires, publics ou officiels.
                            <br/><br/>Vous pouvez facilement remarquer si un compte est certifié grâce à un petit badge blanc/noir à côté du nom d'utilisateur.</p>
                        </div>
                        {/* SECOND SECTION */}
                        <div id="second" style={{marginTop:30}}>
                            <p style={{fontSize:20,fontWeight:500}}>Comment l'obtenir ?</p>
                            <p>Pour obtenir cette certification, vous devez répondre à certains critères :</p>
                            <ul>
                                <li>vous devez avoir un profil minimum complet et conforme (nom d'utilisateur, photo de profil)</li>
                                <li>votre compte doit être actif et doit avoir été créé depuis au moins 30 jours</li>
                                <li>votre compte ne doit pas être trompeur</li>
                                <li>vous devez avoir activé l'authentification à deux facteurs</li>
                            </ul>
                            <p>Votre compte doit bien sûr respecter les <Link to="/settings/about/tos">conditions d'utilisation</Link>.<br/><br/>
                            Si vous répondez à ces critères, vous devriez pouvoir faire une demande de certification via cette adresse email : <a href="mailto:support@snot.fr">support@snot.fr</a><br/><br/>
                            Après cette demande, l'équipe du SNOT vérifiera la certifiabilité de votre compte et pourra ou non certifier votre compte.
                            </p>
                        </div>
                        {/* THIRD SECTION */}
                        <div id="third" style={{marginTop:30}}>
                            <p style={{fontSize:20,fontWeight:500}}>Perte de certification</p>
                            <p>Conformément aux <Link to="/settings/about/tos">conditions d'utilisation</Link>, l'équipe SNOT peut décertifier un compte s'il ne répond plus aux critères.<br/><br/>
                            Pour plus de détails, nous pouvons supprimer temporairement la certification si vous décidez de modifier votre compte (photo de profil, nom d'utilisateur...) jusqu'à ce que nous revalidions votre compte.<br/><br/>
                            Comme mentionné ci-dessus, nous pouvons supprimer la certification de votre compte si elle enfreint les critères de certification.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem("theme")])

    useState(() => {
        // SET ABOUT CONTENT
        if(type === "tos"){
            setAbout(<TermsOfService />)
        }else if(type === "privacy"){
            setAbout(<Privacy />)
        }else if(type === "content"){
            setAbout(<Content />)
        }else if(type === "get-certified"){
            setAbout(<GetCertified />)
        }else {
            setAbout(<>
            <section className={"topPart_setAccMVB1 "+theme}>
                <div id="returnIcon" onClick={() => navigate('/settings')} tabIndex={1}>
                    <i className="fi fi-rr-angle-left"></i>
                </div>
                <h1 id="title">{t('settings.about.title')}</h1>
            </section>
            <section className={"settingsPart_setMainMVB1 "+theme}>
                <div id="GeneralSettings">
                    <div className="firstSetting" onClick={() => navigate('/settings/about/tos')}>
                        <span id="settingName">{t('footer.tos')}</span>
                        <div className="goToSetting">
                            <i className="fi fi-rr-angle-right"></i>
                        </div>
                    </div>
                    <div className="secondSetting" onClick={() => navigate('/settings/about/privacy')}>
                        <span id="settingName">{t('footer.privacy')}</span>
                        <div className="goToSetting">
                            <i className="fi fi-rr-angle-right"></i>
                        </div>
                    </div>
                    <div className="thirdSetting" onClick={() => navigate('/settings/about/content')}>
                        <span id="settingName">{t('footer.content')}</span>
                        <div className="goToSetting">
                            <i className="fi fi-rr-angle-right"></i>
                        </div>
                    </div>
                </div>
                <div id="SNOTSettings">
                    <h1 id="title">{t('settings.about.help')}</h1>
                    <div className="firstSetting" onClick={() => navigate('/settings/about/get-certified')}>
                        <span id="settingName">{t('settings.about.get-certified')}</span>
                        <div className="goToSetting">
                            <i className="fi fi-rr-angle-right"></i>
                        </div>
                    </div>
                </div>
            </section>
            </>)
        }
    }, [theme])

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://mobile.snot.fr/settings/about/"+type} />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.about')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/settings/about/"+type+location.search}/>
                <meta name="twitter:title" content={t('title.settings.about')}/>
                <title>{t('title.settings.about')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <div id="mBLogged" className={theme+" error"} style={{display: 'none'}}>
                <div id="textN_nMAPPb1">
                    <p></p>
                </div>
                <div className="iconClose" onClick={closeNotifMB}><i className="fi fi-sr-cross"></i></div>
            </div>
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    {about}
                </div>
            </main>
       </>
    )
}

export default SettingsAboutType;