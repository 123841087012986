import { useState } from "react";
import {
    Routes,
    Route,
    BrowserRouter,
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import { GoogleOAuthProvider } from "@react-oauth/google";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from 'react-i18next';
// import Pages
// -- HOME
    import Home from "./pages/Home";
    import Popular from "./pages/Home/Popular";
    import Spost from './pages/Home/[spost]';
    import CommentReplyPage from './pages/Home/[spost]/[type]/[uid]';
    import SpostType from './pages/Home/[spost]/[type]';
    import Tags from "./pages/Home/Tags";
    import Subs from "./pages/Home/Subs";
// -- FRIENDS
    import Friends from "./pages/Friends";
    import Pending from "./pages/Friends/Pending";
    import Blocked from "./pages/Friends/Blocked";
    import ChatFriends from "./pages/Friends/Chat";
    import MessagesRequests from "./pages/Friends/NewPM";
// -- CHAT
    import Chat from "./pages/Chat/[key]";
// -- GROUP
    import GroupChat from "./pages/Chat/[groupKey]";
// -- PROFILE
    import Profile from "./pages/Profile";
    import ProfileUser from "./pages/Profile/[user]";
    import ProfileLiked from "./pages/Profile/[user]/Liked";
    import ProfileReposts from "./pages/Profile/[user]/Reposts";
// -- SEARCH
    import Search from "./pages/Search";
    import SearchUsers from "./pages/Search/Users";
    import SearchPosts from "./pages/Search/Posts";
// -- SETTINGS
    import Settings from "./pages/Settings";
    import SettingsAccount from "./pages/Settings/Pages/Account";
    import SettingsPrivacy from "./pages/Settings/Pages/Privacy";
    import SettingsSafety from "./pages/Settings/Pages/Safety";
    import SettingsAppearance from "./pages/Settings/Pages/Appearance";
    import SettingsAccessibility from "./pages/Settings/Pages/Accessibility";
    import SettingsLanguage from "./pages/Settings/Pages/Language";
    import SettingsNotifications from "./pages/Settings/Pages/Notifications";
    import SettingsAbout from "./pages/Settings/Pages/About";
    import SettingsAboutType from "./pages/Settings/Pages/About/[type]";
    import SettingsNews from "./pages/Settings/Pages/News";
    import SettingsSessions from "./pages/Settings/Pages/Sessions";
// -- ADD
    import Add from "./pages/Add";
// -- LOGIN
    import Login from "./pages/Login";
    import LoginDefault from "./pages/Login/Default";
// -- REGISTER
    import Register from "./pages/Register"
// -- 2FA
    import TWOFA from "./pages/2FA";
// -- RESET PASSWORD
    import ResetPassword from "./pages/Reset";
// import Components
import Interests from "./Components/Modals/Common/Interests";
// import CSS
import './css/bootstrap.min.css';
import './css/b-responsive.min.css';
// import Functions
import { verifyAuth } from "./Components/Functions/Auth";
import { API_VERSION } from "./Components/Functions";
// import Pusher
import { PusherProvider } from './Components/Context/Pusher';
import { PresenceProvider } from './Components/Context/Presence';

const Main = () => {
    const { t } = useTranslation();
    // Set logged
    const [logged, setLogged] = useState(0);

    // CHECK AND SET USER LOGGED STATUS
    verifyAuth().then((res) => {
        if(res === true){
            setLogged(1);
        }
    })

    // DETECT PREFERED THEME (SYSTEM THEME - DARK / LIGHT)
    const detectTheme = () => {
        var theme = "light";
        if(!window.matchMedia) { return false; }
        else if(window.matchMedia("(prefers-color-scheme: dark)").matches) { theme = "dark"; }

        localStorage.setItem('theme', theme);
    }

    // VERIFY IF USER IS LOGGED OR NOT
    if(logged === 1){
        if(Cookies.get('_theme') === "sync_theme"){
            detectTheme();
        }else{
            localStorage.setItem('theme', Cookies.get('_theme').replace('_theme', ''));
        }
    }else{
        detectTheme();
    }

    return (
        <>
            <Helmet
                script={[{
                    type: "application/ld+json",
                    innerHTML: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "Organization",
    "foundingDate": "2023-07-10",
    "logo": "https://api.snot.fr/v${API_VERSION}/content/logo_v512x512_dc?ext=png&folder=logo",
    "name": "SNOT",
    "url": "https://snot.fr",
    "sameAs": "[https://twitter.com,https://instagram.com,https://facebook.com,https://fr.linkedin.com,https://reddit.com]",
    "location": "France, Bretagne",
    "keywords": "[social media,social network,snot]",
    "email": "support@snot.fr",
    "description": "SNOT is a social network for users who want to make new friends, group together... You can send sposts, chat with your friends and more.",
    "founder": {
        "@context": "https://schema.org",
        "@type": "Person",
        "@id": "Organization",
        "birthDate": "2008-04-09",
        "familyName": "Menguy",
        "gender": "Male",
        "givenName": "Clément",
        "jobTitle": "Founder",
        "knowsLanguage": "[French,English]"
    }
}`
                }]}
            >
                <meta name="wot-verification" content="2686e954e83441c4b69c"/>
                <meta name="description" content={t('general.description')}/>
                <meta property="og:description" content={t('general.description')}/>
                <meta property="og:image" content={"https://api.snot.fr/v"+API_VERSION+"/content/logo_v512x512_dc?ext=png&folder=logo"}/>
                <meta content="#43B581" name="theme-color" />
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:site" content="@snot"/>
                <meta name="twitter:description" content={t('general.description')}/>
                <meta name="twitter:creator" content="@climoux"/>
                <meta name="twitter:image" content={"https://api.snot.fr/v"+API_VERSION+"/content/logo_v512x512_dc?ext=png&folder=logo"}/>
            </Helmet>
            <GoogleOAuthProvider clientId="834977239449-mo3r1n3pu1to35j5v91tni38vuqvfm1l.apps.googleusercontent.com">
                <BrowserRouter>
                    <PresenceProvider>
                        <PusherProvider>
                            {
                                logged === 1 &&
                                <Interests />
                            }
                            <Routes>
                                {/* HOME ROUTE */}
                                <Route path="/home" Component={Home} />
                                <Route path="/popular" Component={Popular} />
                                <Route path="/spost/:spost" Component={Spost} />
                                <Route path="/spost/:spost/:type/:uid" Component={CommentReplyPage} />
                                <Route path="/spost/:spost/:type" Component={SpostType} />
                                <Route path="/tags" Component={Tags} />
                                <Route path="/subs" Component={Subs} />
                                {/* LOG, REGISTER, PASS ROUTE */}
                                <Route path="/" Component={Login} />
                                <Route path="/login/default" Component={LoginDefault} />
                                <Route path="/register" Component={Register} />
                                <Route path="/reset-password" Component={ResetPassword} />
                                {/* 2FA ROUTE */}
                                <Route path="/2fa" Component={TWOFA}/>
                                {/* PROFILE ROUTE */}
                                <Route path="/profile" Component={Profile}/>
                                <Route path="/:user" Component={ProfileUser}/>
                                <Route path="/:user/liked" Component={ProfileLiked}/>
                                <Route path="/:user/reposts" Component={ProfileReposts}/>
                                {/* SEARCH ROUTE */}
                                <Route path="/search" Component={Search} />
                                <Route path="/search/users" Component={SearchUsers} />
                                <Route path="/search/posts" Component={SearchPosts} />
                                {/* SETTINGS ROUTE */}
                                <Route path="/settings" Component={Settings}/>
                                <Route path="/settings/account" Component={SettingsAccount}/>
                                <Route path="/settings/privacy" Component={SettingsPrivacy}/>
                                <Route path="/settings/safety" Component={SettingsSafety}/>
                                <Route path="/settings/sessions" Component={SettingsSessions}/>
                                <Route path="/settings/appearance" Component={SettingsAppearance}/>
                                <Route path="/settings/accessibility" Component={SettingsAccessibility}/>
                                <Route path="/settings/notifications" Component={SettingsNotifications}/>
                                <Route path="/settings/language" Component={SettingsLanguage}/>
                                <Route path="/settings/news" Component={SettingsNews}/>
                                <Route path="/settings/about" Component={SettingsAbout}/>
                                <Route path="/settings/about/:type" Component={SettingsAboutType}/>
                                {/* ADD ROUTE */}
                                <Route path="/add" Component={Add}/>
                                {/* FRIENDS ROUTE */}
                                <Route path="/friends" Component={Friends}/>
                                <Route path="/friends/pending" Component={Pending}/>
                                <Route path="/friends/blocked" Component={Blocked}/>
                                <Route path="/friends/chat" Component={ChatFriends}/>
                                <Route path="/friends/chat/requests" Component={MessagesRequests}/>
                                {/* CHAT ROUTE */}
                                <Route path="/chat/:key" Component={Chat}/>
                                {/* GROUP CHAT ROUTE */}
                                <Route path="/group/:groupKey" Component={GroupChat}/>
                            </Routes>
                        </PusherProvider>
                    </PresenceProvider>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </>
    );
}

export default Main;