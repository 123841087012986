// import API version
import { MoonLoader } from "react-spinners";
import { API_VERSION } from "../Functions";
import { useEffect, useState } from "react";

export const LoadingDefault = () => {
    document.body.className = "darkTheme loading";

    // ...
    const [loader, setLoader] = useState(<></>);
    
    useEffect(() => {
        setTimeout(() => {
            setLoader(<MoonLoader color="#fff" speedMultiplier={0.5} size={50} cssOverride={{ top: 50 }} />);
        }, 2500)
    }, [])

    return (
        <>
            <main id="loadingSplash" style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", position: "absolute", left: 0, top: 0, bottom: 0, overflow: "hidden", zIndex: 999999 }}>
                <img title="SNOT" alt="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} referrerPolicy="no-referrer" style={{ width: "75%", filter: "drop-shadow(0px 0px 50px rgb(255, 255, 255))" }} />
                {loader}
            </main>
        </>
      )
}