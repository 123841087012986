import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { openMenu } from "../Functions/Menu";
import { openModal } from "../Functions/Modal";
import * as Profile from '../Functions/Profile';
import * as Settings from '../Functions/Settings'
import { API_VERSION } from "../Functions";
import { retreiveInfos } from "../Functions/Auth";

// Set links linked with profile
const ProfileLinks = ({ infos: infos }) => {
    if(infos.link_1){
        return (
            <>
            <div className="linksProfile_tPProfileMVB1 darkTheme" style={{display:"flex"}}>
                <div id="first">
                    <i className="fi fi-sr-globe"></i>
                    <a id="link" title={infos.link_1} href={"https://"+infos.link_1} target="_blank" rel="noreferrer" tabIndex={3}>{new URL("https://"+infos.link_1).hostname}</a>
                </div>
                {
                    (infos.link_2) ?
                    <>
                        <div id="separator_lProfileMVB1"></div>
                        <div id="second">
                            <i className="fi fi-sr-globe"></i>
                            <a id="link" title={infos.link_2} href={"https://"+infos.link_2} target="_blank" rel="noreferrer" tabIndex={4}>{new URL("https://"+infos.link_2).hostname}</a>
                        </div>
                    </>: ''
                }
                {
                    (infos.link_3) ?
                    <>
                        <div id="separator_lProfileMVB1"></div>
                        <div id="third">
                            <i className="fi fi-sr-globe"></i>
                            <a id="link" title={infos.link_3} href={"https://"+infos.link_3} target="_blank" rel="noreferrer" tabIndex={5}>{new URL("https://"+infos.link_3).hostname}</a>
                        </div>
                    </>: ''
                }
            </div>
            </>
        )
    }else{
        return <></>;
    }
}

// For profile actions buttons
const ActionsButtons = ({ params: { session: session, infos: infos, loading: loading, logged: logged, blocked: blocked }, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [verified, setVerified] = useState({
        follows: '',
        friend: '',
        friendRequest: null,
    });
    const [myInfos, setMyInfos] = useState([]);
    const [blockedMe, setBlockedMe] =  useState(false);
    const [cantMessage, setCantMessage] = useState(false);

    // Generate PM key
    const [generatedKey, setKey] = useState('');

    const generateKeyPM = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/friends/generate/key`,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                resolve(response.data.key);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Verify if [user] has blocked [user_2]
    const blockedUser = (uid, blocked) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${blocked}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web',
                    inverse: true
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    /* -----------------
     * INTERACT FUNCTION
     * -----------------
     */
    const interact = (e, uid, type) => {
        if(logged === 1){
            if(type === "add"){
                const button = $('.friendBtn_profileMVB1');
                // UI
                if(cantMessage === true){
                    document.querySelector('.friendBtn_profileMVB1 i').className = "fi fi-rr-clock";
                    $('.friendBtn_profileMVB1 span').text(t('profile.buttons.pending'));
                    // UX
                    button.off('click').on('click', (e) => interact(e, uid, 'cancelRequest'));
                }else{
                    document.querySelector('.friendBtn_profileMVB1 i').className = "fi fi-rr-paper-plane";
                    $('.friendBtn_profileMVB1 span').text(t('profile.buttons.chat'));
                    // UX
                    button.off('click').on('click', () => navigate('/chat/'+generatedKey));
                }
                // API call
                axios({
                    method: 'POST',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web',
                        key: generatedKey
                    }
                })
            }else if(type === "cancelRequest"){
                const button = $('.friendBtn_profileMVB1');
                // UI
                document.querySelector('.friendBtn_profileMVB1 i').className = "fi fi-rr-user-add";
                $('.friendBtn_profileMVB1 span').text(t('profile.buttons.addFriend'));
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'sub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'DELETE',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                })
            }else if(type === "sub"){
                const button = $('.subscribeBtn_profileMVB1');
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'unsub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'POST',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                }).then(res => {
                    if(!res.data.request){
                        // UI
                        document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-minus";
                        $('.subscribeBtn_profileMVB1 span').text(t('profile.buttons.stopFollow'));
                    }else{
                        // UI
                        document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-clock";
                        $('.subscribeBtn_profileMVB1 span').text(t('profile.buttons.pending'));
                        button.off('mouseenter').on('mouseenter', () => document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-cross");
                        button.off('mouseleave').on('mouseleave', () => document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-clock");
                    }
                    // Add +1 follower
                    const count = $('.followersCount_profileMVB1.follower #count')
                    let followers = count.text();
                    followers++;
                    // .
                    count.text(followers);
                })
            }else if(type === "unsub"){
                const button = $('.subscribeBtn_profileMVB1');
                // UI
                document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-plus";
                $('.subscribeBtn_profileMVB1 span').text(t('profile.buttons.follow'));
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'sub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'DELETE',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                }).then(res => {
                    // Add -1 follower
                    const count = $('.followersCount_profileMVB1.follower #count')
                    let followers = count.text();
                    followers--;
                    // .
                    count.text(followers);
                })
            }
        }else{
            openModal(e);
        }
    }

    useEffect(() => {
        if(logged === 1){
            generateKeyPM().then(res => setKey(res));

            // Retreive settings
            Settings.retreiveSettings(infos.uid).then(setting => {
                if(setting !== null && (setting[0].security_setting3 !== "Enabled")){
                    setCantMessage(true);
                }else if(setting === null){
                    setCantMessage(true);
                }
            })

            retreiveInfos().then(res => {
                // Verify if [user] has blocked me
                if(blocked === false && session !== 'current'){
                    blockedUser(infos.uid, res.uid).then(resBlock => {
                        if(resBlock !== 0){
                            setBlockedMe(true);
                        }else{
                            setBlockedMe(false);
                        }
                    })
                }
                // Retreive my data
                setMyInfos(res);
                // Verify he's friend or follow...
                // -- Follows
                Profile.verifyTypeUser('follows', res.uid, infos.uid).then(verifyFollows => {
                    // -- Friends
                    Profile.verifyTypeUser('friends', res.uid, infos.uid).then(verifyFriend => {
                        if(verifyFriend === null){
                            Profile.verifyTypeUser('friendsrequest', res.uid, infos.uid).then(verifyFriendReq => {
                                setVerified({
                                    friend: verifyFriendReq,
                                    follows: verifyFollows,
                                    friendRequest: true
                                })
                            })
                        }else{
                            setVerified({
                                friend: verifyFriend,
                                follows: verifyFollows,
                                friendRequest: false
                            })
                        }
                    })
                })
            })
        }
    }, [logged, infos])
    
    useEffect(() => {
        if(verified.friend !== null){
            if(verified.friendRequest){
                if(cantMessage){
                    $('.friendBtn_profileMVB1').off('mouseenter').on('mouseenter', () => document.querySelector('.friendBtn_profileMVB1 i').className = "fi fi-rr-cross");
                    $('.friendBtn_profileMVB1').off('mouseleave').on('mouseleave', () => document.querySelector('.friendBtn_profileMVB1 i').className = "fi fi-rr-clock");
                    $('.friendBtn_profileMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'cancelRequest'))
                }else{
                    $('.friendBtn_profileMVB1').off('mouseenter').off('mouseleave');
                    $('.friendBtn_profileMVB1').off('click').on('click', () => navigate('/chat/'+verified.friend.keyPM))
                }
            }else{
                $('.friendBtn_profileMVB1').off('mouseenter').off('mouseleave');
                $('.friendBtn_profileMVB1').off('click').on('click', () => navigate('/chat/'+verified.friend.keyPM))
            }
        }else{
            $('.friendBtn_profileMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'add'))
        }

        if(verified.follows !== null){
            if(verified.follows.request === "Yes"){
                $('.subscribeBtn_profileMVB1').off('mouseenter').on('mouseenter', () => document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-cross");
                $('.subscribeBtn_profileMVB1').off('mouseleave').on('mouseleave', () => document.querySelector('.subscribeBtn_profileMVB1 i').className = "fi fi-rr-clock");
            }else{
                $('.subscribeBtn_profileMVB1').off('mouseenter').off('mouseleave');
            }
            $('.subscribeBtn_profileMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'unsub'))
        }else{
            $('.subscribeBtn_profileMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'sub'))
        }
    }, [verified])

    return (
        <>
        {
            logged === 1 ?
                loading === false ?
                    session === "current" ?
                    <div id="actionsButtons">
                        <div className={"editProfileBtn_tPProfileMVB1 "+theme} role="button" tabIndex={6} onClick={() => {
                            document.querySelector('.editProfile_mAppMVB1').style.display = "block";
                            document.body.style.overflow = "hidden";
                        }}>
                            {t('profile.buttons.editProfile')}
                        </div>
                    </div>
                    :
                    <div id="actionsButtons" {...(blocked === false && blockedMe === false) ? {style:{marginTop: 30, marginBottom: -30}}: {}}>
                        {
                            // -- Add friend / Message (chat)
                            (blocked === false && blockedMe === false) ?
                                verified.friend !== null ?
                                    verified.friendRequest ?
                                        !cantMessage ?
                                            <div className={"friendBtn_profileMVB1 "+theme} role="button" tabIndex={1}>
                                                <span>{t('profile.buttons.chat')}</span>
                                                <i className="fi fi-rr-paper-plane"></i>
                                            </div>
                                        :
                                            <div className={"friendBtn_profileMVB1 "+theme} role="button" tabIndex={1}>
                                                <span>{t('profile.buttons.pending')}</span>
                                                <i className="fi fi-rr-clock"></i>
                                            </div>
                                    :
                                        <div className={"friendBtn_profileMVB1 "+theme} role="button" tabIndex={1}>
                                            <span>{t('profile.buttons.chat')}</span>
                                            <i className="fi fi-rr-paper-plane"></i>
                                        </div>
                                :
                                <div className={"friendBtn_profileMVB1 "+theme} role="button" tabIndex={1}>
                                    <span>{t('profile.buttons.addFriend')}</span>
                                    <i className="fi fi-rr-user-add"></i>
                                </div>
                            :
                            <></>
                        }
                        {
                            (blocked === false && blockedMe === false) ?
                                verified.follows !== null ?
                                    verified.follows.request === "Yes" ?
                                        <div className={"subscribeBtn_profileMVB1 "+theme} tabIndex={2} role="button">
                                            <span>{t('profile.buttons.pending')}</span>
                                            <i className="fi fi-rr-clock"></i>
                                        </div>
                                    :
                                        <div className={"subscribeBtn_profileMVB1 "+theme} tabIndex={2} role="button">
                                            <span>{t('profile.buttons.stopFollow')}</span>
                                            <i className="fi fi-rr-minus"></i>
                                        </div>
                                :
                                    <div className={"subscribeBtn_profileMVB1 "+theme} tabIndex={2} role="button">
                                        <span>{t('profile.buttons.follow')}</span>
                                        <i className="fi fi-rr-plus"></i>
                                    </div>
                            :
                            <></>
                        }
                    </div>
                : ''
            :
            <div id="actionsButtons" style={{marginTop: 30, marginBottom: -30}}>
                <div className={"friendBtn_profileMVB1 "+theme} style={{minWidth:100}} role="button" tabIndex={1} data-modal="loginModal" onClick={openModal}>
                    <span data-modal="loginModal">{t('profile.buttons.addFriend')}</span>
                    <i className="fi fi-rr-user-add" data-modal="loginModal"></i>
                </div>
                <div className={"subscribeBtn_profileMVB1 "+theme} role="button" tabIndex={2} data-modal="loginModal" onClick={openModal}>
                    <span data-modal="loginModal">{t('profile.buttons.follow')}</span>
                    <i className="fi fi-rr-plus" data-modal="loginModal"></i>
                </div>
            </div>
        }
        </>
    );
}

const BlockButton = ({uid, user}) => {
    const { t } = useTranslation();
    // Set data
    const [button, setButton] = useState(<div id="block" data-modal="confirmUnblock"><span data-modal="confirmUnblock">{t('friends.blocked.button')}</span><i className="fi fi-rr-unlock" data-modal="confirmUnblock"></i></div>)

    // Verify if [user] has blocked [user_2]
    const blockedUser = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${user}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    useEffect(() => {
        blockedUser().then(res => {
            if(res === 0){
                setButton(<div id="block" data-modal="confirmBlock"><span data-modal="confirmBlock">{t('friends.my-friends.modal.buttons.block')}</span><i className="fi fi-rr-lock" data-modal="confirmBlock"></i></div>);
                // EVENT LISTENERS
                $('#block').off('click').on('click', (e) => {
                    // OPEN MODAL
                    openModal(e);
                    // HIDE MODAL
                    $('#moreProfile').trigger('click');
                })
            }else{
                setButton(<div id="block" data-modal="confirmUnblock"><span data-modal="confirmUnblock">{t('friends.blocked.button')}</span><i className="fi fi-rr-unlock" data-modal="confirmUnblock"></i></div>);
                // EVENT LISTENER
                $('#block').off('click').on('click', (e) => {
                    // OPEN MODAL
                    openModal(e);
                    // HIDE MODAL
                    $('#moreProfile').trigger('click');
                })
            }
        })
    }, [])

    return button;
}

const NewMessageButton = ({uid, user}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [button, setButton] = useState(<div id="message"><span>{t("chat.sendMessage")}</span><i className="fi fi-rr-comment-alt"></i></div>)

    // Verify if [user] has blocked [user_2]
    const blockedUser = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${user}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    // Verify if [user] has already messaged [user_2]
    const messagedUser = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/message/${user}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    useEffect(() => {
        // Retreive settings
        Settings.retreiveSettings(user).then(setting => {
            if(setting !== null && (setting[0].security_setting3 === "Enabled")){
                // VERIFY IF USER IS BLOCKED
                blockedUser().then(res => {
                    if(res !== 0){
                        setButton(<></>);
                    }
                })
                // VERIFY
                messagedUser().then(res => {
                    if(res !== 0){
                        $('#message').off('click').on('click', () => {
                            axios.get(
                                `https://api.snot.fr/v${API_VERSION}/user/${uid}/message/${user}/key`,
                                {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    params: {
                                        token: Cookies.get('loginToken'),
                                        app: 'web'
                                    }
                                }
                            ).then(resKey => {
                                navigate('/chat/'+resKey.data);
                            }).catch(err => console.log('An error occured : %c'+err.response.statusText+' '+err.response.data.message, 'color:red;'))
                        })
                    }else{
                        $('#message').off('click').on('click', () => {
                            axios.post(
                                `https://api.snot.fr/v${API_VERSION}/user/${uid}/message/${user}`,
                                undefined,
                                {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    params: {
                                        token: Cookies.get('loginToken'),
                                        app: 'web'
                                    }
                                }
                            ).then(resKey => {
                                navigate('/chat/'+resKey.data.key);
                            }).catch(err => console.log('An error occured : %c'+err.response.statusText+' '+err.response.data.message, 'color:red;'))
                        })
                    }
                }).catch(err => console.log('An error occured : %c'+err.response.statusText+' '+err.response.data.message, 'color:red;'))
            }else{
                setButton(<></>);
            }
        })
    }, [])

    return button;
}

export {
    ProfileLinks,
    ActionsButtons,
    BlockButton,
    NewMessageButton,
};