import {useEffect, useState, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import $ from 'jquery';
// import i18next
import { useTranslation, Trans } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import {LoadingSuggestions} from '../Loading/LoadingSuggestions';
// import Hooks
import { handleImageError } from '../../Hooks/handleAvatarError';
// import Functions
import { API_VERSION, openDrawer } from '../Functions';
// import Auth
import { retreiveInfos } from '../Functions/Auth';

const ucFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const TrendsContainer = ({theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set req limit
    const [req, setReq] = useState(0);
    const [limit, setLimit] = useState(3);
    // Set data
    const [data, setData] = useState([]);
    const [dataSuggest, setDataSuggest] = useState([]);
    const [infos, setInfos] = useState([]);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // -- Set logged
    const [logged, setLogged] = useState(0);
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
        }
    }, [])
    
    // GET TRENDY TAGS
    const getTrendyThemes = async () => {
        try {
            const trends = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/trends`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        theme: theme.replace('Theme', ''),
                        lang: i18n.resolvedLanguage,
                    }
                }
            );
            return trends.data.result;
        } catch (error) {
            console.error('Error fetching trends:', error);
            return null;
        }
    }

    // GET SUGGESTED ACCOUNTS
    const getSuggestions = async (uid, req=0, limit=3) => {
        try {
            const suggest = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/suggested/accounts`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: uid,
                        app: 'web',
                        req: req, // Default is 0
                        limit: limit // Default limit is 3
                    }
                }
            );
            return suggest.data; // Return the suggestions data
        } catch (error) {
            console.error('Error fetching suggested accounts:', error);
            return null;
        }
    }

    /* -------------
     * TRENDS THEMES
     * -------------
     */
    const Trends = () => {
        if(data === null || data === undefined){
            return (
                <div className="trendContainer_mDrawerMVB1" style={{ height: 'auto', paddingBottom: 10, paddingTop: 10 }}>
                    <p className="text_frFPB1">
                        <span className="texton_frPFPB1">Ah...</span><br/>
                        <Trans i18nKey={'general.trends.error.description'}></Trans>
                    </p>
                </div>
            )
        }else{
            return (<>
                {data.map(trendy => {
                    // Traduction
                    var trendName = trendy.tag;
                    if(i18n.resolvedLanguage === "fr"){
                        if(trendy === "popular") trendName = "populaire";
                        else if(trendy === "news") trendName = "actualités";
                        else if(trendy === "advertising") trendName = "publicité";
                    }

                    return (
                        <div className="trendContainer_mDrawerMVB1" onClick={() => {navigate('/tags?t='+trendy.tag);openDrawer();}}>
                            <p className="titleContainer_tCMVB1">#{ucFirst(trendName)}</p>
                            <span className='stats_tCMVB1'>{trendy.count || 0} post{trendy.count > 1 ? 's': ''}</span>
                        </div>
                    )
                })}
            </>)
        }
    }

    /* ------------------
     * SUGGESTED ACCOUNTS
     * ------------------
     */
    const LoadingSuggests = () => {
        return (
            <>
            <LoadingSuggestions theme={theme} />
            <LoadingSuggestions theme={theme} />
            <LoadingSuggestions theme={theme} />
            </>
        )
    }

    const SuggestedAccounts = () => {
        if(dataSuggest !== null || dataSuggest !== undefined){
            return (<>
                {dataSuggest.map(suggest => {
                    var certified = <></>;
                    var staff = <></>;
                    // Set badges
                    if (suggest.certified === "Yes" && suggest.staff === "Yes") {
                        certified = <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>;
                        staff = <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>;
                    }else{
                        if (suggest.staff === "Yes") {
                            staff = <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>;
                        }
                        if (suggest.certified === "Yes") {
                            certified = <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>;
                        }
                    }

                    return (
                        <div key={suggest.uid} className={"accountPre_sRlBPcVB1 ref_"+suggest.uid} onClick={() => navigate('/@'+suggest.usertag.replace('.', '_').toLowerCase())}>
                            <div id="profile">
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+suggest.usertag.replace('.', '_').toLowerCase()} title={suggest.username} src={suggest.avatar}
                                        onError={handleImageError}
                                        style={loadingAvatar ? {display: "none"}: {}}
                                        onLoad={() => setLoadingAvatar(false)}
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                <div id="user">
                                    <span id="username">{suggest.username} {certified}{staff}</span>
                                    <span id="usertag">@{suggest.usertag.toLowerCase().replace('.', '_')}</span>
                                </div>
                            </div>
                            <div id="follow" onClick={(e) => {
                                e.stopPropagation();
                                interact(suggest.uid, 'sub');
                            }}>{t('general.suggested.follow')}</div>
                        </div>
                    )
                })}
            </>)
        }else{
            return (
                <div className="accountPre_sRlBPcVB1" id='no-hover'>
                    <div id="profile">
                        <div className={"avatar skeleton "+theme} style={{width: 45, height: 45}}></div>
                        <div id="user" style={{ justifyContent: 'center' }}>
                            <span id="username" className={'user skeleton '+theme} style={{ width: 75, height: 18, marginBottom: 5 }}></span>
                            <span id="usertag" className={'user skeleton '+theme} style={{ width: 100, height: 15 }}></span>
                        </div>
                    </div>
                    <div id="follow">{t('general.suggested.follow')}</div>
                </div>
            )
        }
    }

    useEffect(() =>{
        const fetchData = async () => {
            try {
                const infos = await retreiveInfos();
                const trendsPromise = getTrendyThemes();
                const suggestionsPromise = logged === 1 ? getSuggestions(infos.uid) : null;
    
                const [trends, suggestions] = await Promise.all([trendsPromise, suggestionsPromise]);
                if (suggestions) {
                    setDataSuggest(suggestions);
                }
                setData(trends);
                setInfos(infos);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [logged])

    // FUNCTION
    const interact = useCallback(async (uid, type) => {
        const button = $('.accountPre_sRlBPcVB1.ref_'+uid+' #follow');

        if(type === "sub"){
            // UX
            button.off('click').on('click', (e) => {
                e.stopPropagation();
                interact(uid, 'unsub');
            });
            // API call
            await axios.post(
                `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                undefined,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: infos.uid,
                        app: 'web'
                    }
                }
            );
            button.text(t('general.suggested.followed'))
        }else{
            // UI
            button.text(t('general.suggested.follow'));
            // UX
            button.off('click').on('click', (e) => {
                e.stopPropagation();
                interact(uid, 'sub');
            });
            // API call
            await axios.delete(
                `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: infos.uid,
                        app: 'web'
                    }
                }
            );
        }
    }, [infos]);

    const refreshSuggestions = useCallback(async () => {
        setLoading(true);
        // ...
        var newReq = req + 3;
        var newLimit = limit + 3;
        setReq(newReq);
        setLimit(newLimit);

        const fetchData = async () => {
            try {
                const suggestionsPromise = getSuggestions(infos.uid, newReq , newLimit);
    
                const [suggestions] = await Promise.all([suggestionsPromise]);
                if (suggestions) {
                    setDataSuggest(suggestions);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [req, limit, infos]);

    return (
        <>
        <div id="menuDrawer" className={theme}>
            <h1 id="title">{t('general.trends.title')}</h1>
            <div className={"trends_fBmVeB1 "+theme}>
                <Trends />
            </div>
            {
                logged === 1 && (<>
                    <h1 id="title" style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                        {t('general.suggested.title')}
                    </h1>
                    <div className="suggestions_rlBPcVB1">
                        {
                            loading ? <LoadingSuggests/>
                            : <SuggestedAccounts />
                        }
                        <div id='refresh' onClick={refreshSuggestions}>
                            <i className='fi fi-rr-refresh'></i>
                            <span>{t('general.suggested.reload')}</span>
                        </div>
                    </div>
                    <div className="separator_fBmVeB1 darkTheme" style={{ position: 'absolute', left: 0 }}></div>
                </>)
            }
            <footer className={"legalMentions_tMpVB1 "+theme} {...(logged === 0 && {style: {marginTop: -15}})}>
                <p>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/tos": "https://about.snot.fr/en/tos"} hrefLang={i18n.resolvedLanguage} title={t('footer.tos')}>{t('footer.tos')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/privacy": "https://about.snot.fr/en/privacy"} hrefLang={i18n.resolvedLanguage} title={t('footer.privacy')}>{t('footer.privacy')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/content": "https://about.snot.fr/en/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')}>{t('footer.content')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/branding": "https://about.snot.fr/en/branding"} hrefLang={i18n.resolvedLanguage} title={t('footer.branding')}>{t('footer.branding')}</a><br/><br/>
                    SNOT © { new Date().getFullYear() }.
                </p>
            </footer>
        </div>
        </>
    )
}

export default TrendsContainer;