import { useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
// import i18n
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
// import Functions
import { API_VERSION } from "../Functions";
import { openMenu } from "../Functions/Menu";
// import Auth
import { retreiveInfos } from "../Functions/Auth";
import { retreiveCreatorInfos } from "../Functions/Home";
// import Pusher
import { usePresence } from "../Context/Presence";
import { openModal } from "../Functions/Modal";

export const Friend = ({ data: {uid, avatar, username, usertag, date, badges: { certified, staff }, key}, myData: {myUID}, theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "le " + sendDate.toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }else {
        switch (diffDays) {
            default:
                date_format = "the " + sendDate.toLocaleString('en-UK', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }

        // EVENT LISTENERS
        // -- For menu
        $('#moreMenu_'+uid+" #remove").off('click').on('click', (e) => {
            openModal(e);
            $("#moreMenu_"+uid).trigger('click')
        })
        $('#moreMenu_'+uid+" #block").off('click').on('click', (e) => {
            openModal(e);
            $("#moreMenu_"+uid).trigger('click')
        })
        // -- For modal
        // -- -- Remove
        $('#confirmRemove_'+uid+' .button_returnMVB1').off('click').on('click', () => $('#confirmRemove_'+uid).trigger('click'));
        $('#confirmRemove_'+uid+' .button_confirmMVB1').off('click').on('click', () => {
            axios.delete(
                `https://api.snot.fr/v${API_VERSION}/user/${myUID}/friends`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web',
                        key: key
                    }
                }
            ).then(res => {
                if(res.data.message === "REMOVE_FRIEND"){
                    window.location.reload();
                }
            }).catch(error => console.warn("Oops! Something went wrong. Here's the details : "+error.response.data.message.replace('_', ' ')))
        });
        // -- -- Block
        $('#confirmBlock_'+uid+' .button_returnMVB1').off('click').on('click', () => $('#confirmRemove_'+uid).trigger('click'));
        $('#confirmBlock_'+uid+' .button_confirmMVB1').off('click').on('click', () => {
            // Block user (API)
            axios.post(
                `https://api.snot.fr/v${API_VERSION}/block/${uid}`,
                { uid: myUID, key: key },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: "web"
                    }
                }
            ).then(res => {
                if(res.data.message === "BLOCK_USER"){
                    window.location.reload();
                }
            }).catch(error => console.warn("Oops! Something went wrong. Here's the details : "+error.response.data.message.replace('_', ' ')))
        });
    }, [])

    return (
        <>
        <div className="userContainer_friendsMMVB1">
            <div id="profilePart">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                    <div id="presence" style={{ width: "100%", marginTop: -57 }}>
                        <div className={"presence_presencePPartMVB1 user_"+uid}></div>
                    </div>
                </div>
                <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
            </div>
            <div id="actionsButtons">
                <div className="chat_actionsBtnMVB1" onClick={() => navigate('/chat/'+key)}><i className="fi fi-rr-comment-alt"></i></div>
                <div className="more_actionsBtnMVB1" data-container={"moreMenu_"+uid} onClick={openMenu}>
                    <i className="fi fi-rr-menu-dots-vertical" data-container={"moreMenu_"+uid}></i>
                </div>
            </div>
        </div>
        {/* CONFIRMATION REMOVE FRIEND */}
        <aside id={"confirmRemove_"+uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div className="modal-wrapper js-modal-stop">
                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                    <i className="fi fi-rr-cross"></i>
                </button>
                <div className={"confirm remove "+theme}>
                    <div id="logoTop">
                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                    </div>
                    <h1 id="title" style={{ width: "100%" }}>Vous êtes sur le point de retirer cet ami</h1>
                    <p id="subtitle" style={{ width: "100%", padding: "0 25px" }}>Êtes-vous certain de vouloir le retirer ?</p>
                    <div id="actionsButton" style={{ width: "100%" }}>
                        <div className="button_returnMVB1"><span>{t('general.buttons.no')}</span></div>
                        <div className="button_confirmMVB1"><span>{t('friends.my-friends.modal.buttons.remove')}</span></div>
                    </div>
                </div>
            </div>
        </aside>
        {/* CONFIRMATION BLOCK FRIEND */}
        <aside id={"confirmBlock_"+uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div className="modal-wrapper js-modal-stop">
                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                    <i className="fi fi-rr-cross"></i>
                </button>
                <div className={"confirm block "+theme}>
                    <div id="logoTop">
                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                    </div>
                    <h1 id="title" style={{ width: "100%" }}>Vous êtes sur le point de bloquer cet utilisateur</h1>
                    <p id="subtitle" style={{ width: "100%", padding: "0 25px" }}>Êtes-vous certain de vouloir le bloquer ?</p>
                    <div id="actionsButton" style={{ width: "100%" }}>
                        <div className="button_returnMVB1"><span>{t('general.buttons.no')}</span></div>
                        <div className="button_confirmMVB1"><span>{t('friends.my-friends.modal.buttons.block')}</span></div>
                    </div>
                </div>
            </div>
        </aside>
        {/* MORE ACTIONS MENU */}
        <aside id={"moreMenu_"+uid} className={"menu "+theme} aria-hidden="true" aria-modal="false" data-o-container="moreMenuFriends" data-user="false" style={{display:"none"}}>
            <div className="menu-wrapper js-menu-stop" style={{ width: "100%" }}>
                <div class="profilePart_moreFriendsMMVB1">
                    <img alt={"@"+usertag.toLowerCase().replace('.', '_')} title={username} src={avatar} className="avatar_profilePpPreviewB1" 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                    <p id="username">{username}</p>
                    <p id="usertag">@{usertag.toLowerCase().replace('.', '_')}</p>
                    <span id="datefriend" class="content" style={{display:"flex"}}>{t('friends.friendCar.date')} {date_format}</span>
                </div>
                <div className="actionsPart_moreFriendsMMVB1">
                    <div id="remove" data-modal={"confirmRemove_"+uid} data-infos="friends"><span data-modal={"confirmRemove_"+uid} data-infos="friends">{t('friends.my-friends.menu.remove')}</span><i className="fi fi-rr-cross-circle" data-modal={"confirmRemove_"+uid} data-infos="friends"></i></div>
                    <div id="block" data-modal={"confirmBlock_"+uid} data-infos="friends"><span data-modal={"confirmBlock_"+uid} data-infos="friends">{t('friends.my-friends.menu.block')}</span><i className="fi fi-rr-lock" data-modal={"confirmBlock_"+uid} data-infos="friends"></i></div>
                </div>
            </div>
        </aside>
        </>
    )
}

export const FriendPMBar = ({ data: {uid, avatar, username, usertag, badges: { certified, staff }, key, type, name, picture, members}, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    // Presence Indicator
    const { presenceChannel } = usePresence();
    var presence = presenceChannel;
    // New Message
    const [newChat, setNewChat] = useState(0);

    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    const getNotSeenChat = (user) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/chat/${key}/not-seen`,
            params: {
                token: Cookies.get('loginToken'),
                uid: user,
                app: 'web',
                group: type === "Group" ? true: false
            }
        }).then(res => {
            setNewChat(res.data.count);
        })
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        // -- IF TYPE IS FRIEND OR MESSAGE REQUEST
        if(type !== 'Group'){
            if (certified === "Yes") {
                var colorBadge = "#000";
                if(theme === "darkTheme"){
                    colorBadge = "#fff"
                }
                setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge, fontSize: 16 }}></i>});
            }
            if (staff === "Yes") {
                setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1", fontSize: 16}}></i>});
            }

            // FOR PRESENCE INDICATOR (NOT CHAT PRESENCE)
            if(presence !== undefined){
                presence.bind("pusher:subscription_succeeded", () => {
                    if (presence.members.get(uid) !== null) {
                        document.querySelectorAll(".presence_presencePPartMVB1.user_"+uid).forEach((el) => {
                            el.style.backgroundColor = "#67ee54";
                            el.style.display = "flex";
                        })
                    }else {
                        document.querySelectorAll(".presence_presencePPartMVB1.user_"+uid).forEach((el) => {
                            el.style.backgroundColor = "#707070";
                            el.style.display = "flex";
                        });
                    }
                });
            }
        }

        // NEW MESSAGE INDICATOR (if not seen)
        retreiveInfos().then(res => {
            getNotSeenChat(res.uid);
        });
    }, [])

    /* FOR PRESENCE REFRESHING */
    useEffect(() => {
        // -- IF TYPE IS FRIEND OR MESSAGE REQUEST
        if(type !== 'Group'){
            if(presence !== undefined){
                if (presence.members.get(uid) !== null) {
                    document.querySelectorAll(".presence_presencePPartMVB1.user_"+uid).forEach((el) => {
                        el.style.backgroundColor = "#67ee54";
                        el.style.display = "flex";
                    })
                }else {
                    document.querySelectorAll(".presence_presencePPartMVB1.user_"+uid).forEach((el) => {
                        el.style.backgroundColor = "#707070";
                        el.style.display = "flex";
                    });
                }
            }
        }
    }, [type])

    // FOR GROUP NAME
    const [gName, setGName] = useState(name);

    useEffect(() => {
        const fetchUserNames = async () => {
            if (type === "Group") {
                if (name === "") {
                    const userNames = await Promise.all(
                        JSON.parse(members).map(async (member) => {
                            const memberInfos = await retreiveCreatorInfos(member);
                            return memberInfos.usertag;
                        })
                    );
                    setGName(userNames.join(', '));
                }
            }
        };

        fetchUserNames();
    }, [type, name, members]);

    // FOR GROUP PICTURE
    const gPicture = useMemo(() => {
        if(type === "Group"){
            if(picture === "" || picture === null){
                if(name === ""){
                    return createAvatar(initials, {
                        size: 512,
                        seed: gName
                    }).toDataUri();
                }else{
                    return createAvatar(initials, {
                        size: 512,
                        seed: name
                    }).toDataUri();
                }
            }else{
                return picture;
            }
        }
    }, [type, gName]);

    // -- IF TYPE IS FRIEND OR MESSAGE REQUEST
    if(type !== 'Group'){
        return (
            <>
            <div id="userLatest" onClick={() => navigate('/chat/'+key)} className={location.pathname.includes('/chat/'+key) && 'current'}>
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className={"avatarPMBar_"+uid+" content"}
                        onError={() => {
                            if(!$('.avatarPMBar_'+uid).attr('src').includes('.googleusercontent.com')){
                                $('.avatarPMBar_'+uid).attr('src', 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp')
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                    <div id="presence" className="navbarFriends">
                        <div className={"presence_presencePPartMVB1 user_"+uid}></div>
                    </div>
                </div>
                <div className={"user "+theme}><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                {
                    newChat !== 0 ? <div className={"newChat-privateMsgMVB1 key-"+key}>{newChat}</div>: ''
                }
            </div>
            </>
        )
    }else{
        return (
            <>
            <div id="userLatest" onClick={() => navigate('/group/'+key)} className={location.pathname.includes('/group/'+key) && 'current'}>
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={gName} title={gName} src={gPicture} className={"avatarPMBar_"+uid+" content"}
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                </div>
                <div className={"user "+theme} style={{ height: 'auto', width: "100%", overflow: 'hidden' }}>
                    <span id="username" style={{ display: 'block', fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{gName}</span>
                    <span id="members" style={{fontSize: 12,fontWeight: 400,alignItems: "center",display: "flex"}}>{JSON.parse(members).length} {t('groups.members')}</span>
                </div>
                {
                    newChat !== 0 && (<>
                        <div style={{display: "flex",alignItems: "center",justifyContent: "center",marginLeft: "auto",zIndex: 2,padding: 5,background: "#26262C",borderRadius: "50% !important"}}>
                            <div className={"newChat-privateMsgPcVB1 key-"+key}>{newChat}</div>
                        </div>
                    </>)
                }
            </div>
            </>
        )
    }
}

// PENDING
export const PendingRequestContainer = ({ data: {uid, avatar, username, usertag, badges: { certified, staff }, key}, myData: {myUID}, theme }) => {
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }
    }, [])

    return (
        <>
        <div className={"userContainer_friendsMMVB1 user_"+uid}>
            <div id="profilePart">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                </div>
                <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
            </div>
            <div id="actionsButtons">
                <div className="decline_actionsBtnMVB1" onClick={() => {
                    axios.delete(
                        `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                        {
                            headers: {
                                'Content-Type': "application/json"
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                key: key,
                                uid: myUID,
                                app: 'web'
                            }
                        }
                    ).then(() => window.location.reload())
                }}>
                    <i className="fi fi-rr-cross"></i>
                </div>
                <div className="accept_actionsBtnMVB1" onClick={() => {
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/user/${myUID}/friends`,
                        undefined,
                        {
                            headers: {
                                'Content-Type': "application/json"
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                key: key,
                                uid: uid,
                                app: 'web'
                            }
                        }
                    ).then(() => window.location.reload())
                }}>
                    <i className="fi fi-rr-check"></i>
                </div>
            </div>
        </div>
        </>
    )
}

export const PendingUserRequestContainer = ({ data: {uid, avatar, username, usertag, badges: { certified, staff }, key}, myData: {myUID}, theme }) => {
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }
    }, [])

    return (
        <>
        <div className="userContainer_friendsMMVB1">
            <div id="profilePart">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                </div>
                <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
            </div>
            <div id="actionsButtons">
                <div className="cancel_actionsBtnMVB1" onClick={() => {
                    axios.delete(
                        `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                        {
                            headers: {
                                'Content-Type': "application/json"
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                key: key,
                                uid: myUID,
                                app: 'web'
                            }
                        }
                    ).then(() => window.location.reload())
                }}><i className="fi fi-rr-cross"></i></div>
            </div>
        </div>
        </>
    )
}

// BLOCKED
export const BlockedCardContainer = ({ data: {uid, avatar, username, usertag, badges: { certified, staff }}, myData: {myUID}, theme }) => {
    const { t } = useTranslation();
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }

        // EVENT LISTENERS
        $('.userContainer_friendsMMVB1.user_'+uid+' .unblock_actionsBtnMVB1').off('click').on('click', (e) => openModal(e))
        // -- Modal
        $('#confirmUnblock_'+uid+' .button_returnMVB1').off('click').on('click', () => $('#confirmUnblock_'+uid).trigger('click'))
        $('#confirmUnblock_'+uid+' .button_confirmMVB1').off('click').on('click', () => {
            axios.delete(
                `https://api.snot.fr/v${API_VERSION}/unblock/${uid}`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myUID,
                        app: "web"
                    }
                }
            ).then(res => {
                if(res.data.message === "UNBLOCK_USER"){
                    window.location.reload();
                }
            }).catch(error => console.warn("Oops! Something went wrong. Here's the details : "+error.response.data.message.replace('_', ' ')))
        })
    }, [])

    return (
        <>
        <div className={"userContainer_friendsMMVB1 user_"+uid}>
            <div id="profilePart">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                </div>
                <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
            </div>
            <div id="actionsButtons">
                <div className="unblock_actionsBtnMVB1" data-modal={"confirmUnblock_"+uid} data-infos="friends"><span id="text" data-modal={"confirmUnblock_"+uid} data-infos="friends">{t('friends.blocked.button')}</span></div>
            </div>
        </div>
        {/* CONFIRM UNBLOCK MODAL */}
        <aside id={"confirmUnblock_"+uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div className="modal-wrapper js-modal-stop" style={{ width: 400 }}>
                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                    <i className="fi fi-rr-cross"></i>
                </button>
                <div className={"confirm unblock "+theme}>
                    <div id="logoTop">
                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                    </div>
                    <h1 id="title" style={{ width: "100%" }}>Vous êtes sur le point de débloquer cet utilisateur</h1>
                    <p id="subtitle" style={{ width: "100%", padding: "0 20px" }}>Êtes-vous certain de vouloir le débloquer ?</p>
                    <div id="actionsButton" style={{ width: "100%" }}>
                        <div className="button_returnMVB1"><span>{t('general.buttons.no')}</span></div>
                        <div className="button_confirmMVB1"><span>{t('friends.blocked.button')}</span></div>
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

// FOR FRIENDS/CHAT PAGE
export const FriendChat = ({ data: {uid, avatar, username, usertag, date, badges: { certified, staff }, key, type, name, picture, members}, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if(type !== "Group"){
            if (certified === "Yes") {
                var colorBadge = "#000";
                if(theme === "darkTheme"){
                    colorBadge = "#fff"
                }
                setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
            }
            if (staff === "Yes") {
                setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
            }
        }
    }, [type])

    // FOR GROUP NAME
    const [gName, setGName] = useState(name);

    useEffect(() => {
        const fetchUserNames = async () => {
            if (type === "Group") {
                if (name === "") {
                    const userNames = await Promise.all(
                        JSON.parse(members).map(async (member) => {
                            const memberInfos = await retreiveCreatorInfos(member);
                            return memberInfos.usertag;
                        })
                    );
                    setGName(userNames.join(', '));
                }
            }
        };

        fetchUserNames();
    }, [type, name, members]);

    // FOR GROUP PICTURE
    const gPicture = useMemo(() => {
        if(type === "Group"){
            if(picture === "" || picture === null){
                if(name === ""){
                    return createAvatar(initials, {
                        size: 512,
                        seed: gName
                    }).toDataUri();
                }else{
                    return createAvatar(initials, {
                        size: 512,
                        seed: name
                    }).toDataUri();
                }
            }else{
                return picture;
            }
        }
    }, [type, gName]);

    if(type !== "Group"){
        return (
            <>
            <div className="userContainer_friendsMMVB1">
                <div id="profilePart">
                    <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                        <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                            onError={(e) => {
                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                    e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                }
                            }}
                            style={loadingAvatar ? {display: "none"}: {}}
                            onLoad={() => setLoadingAvatar(false)}
                            referrerPolicy="no-referrer"
                        />
                        <div id="presence" style={{ width: "100%", marginTop: -55 }}>
                            <div className={"presence_presencePPartMVB1 user_"+uid}></div>
                        </div>
                    </div>
                    <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                    <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
                </div>
                <div id="actionsButtons">
                    <div className="chat_actionsBtnMVB1" onClick={() => navigate('/chat/'+key)} style={{ width: 100 }}><span style={{ fontSize: 16, marginRight: 10 }}>Chat</span> <i className="fi fi-rr-comment-alt"></i></div>
                </div>
            </div>
            </>
        )
    }else{
        return (
            <>
            <div className="userContainer_friendsMMVB1">
                <div id="profilePart">
                    <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                        <img alt={gName} title={gName} src={gPicture} 
                            onError={(e) => {
                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                    e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                }
                            }}
                            style={loadingAvatar ? {display: "none"}: {}}
                            onLoad={() => setLoadingAvatar(false)}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                    <div className="user">
                        <span id="username" style={{ display: 'block', fontSize: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{gName}</span>
                    </div>
                    <div className="usertag">
                        <span id="members" style={{fontSize: 14,fontWeight: 400,alignItems: "center",display: "flex"}}>{JSON.parse(members).length} {t('groups.members')}</span>
                    </div>
                </div>
                <div id="actionsButtons">
                    <div className="chat_actionsBtnMVB1" onClick={() => navigate('/group/'+key)} style={{ width: 100 }}><span style={{ fontSize: 16, marginRight: 10 }}>Chat</span> <i className="fi fi-rr-comment-alt"></i></div>
                </div>
            </div>
            </>
        )
    }
}

// FOR CHAT REQUEST
export const FriendChatRequest = ({ data: {uid, avatar, username, usertag, badges: { certified, staff }, key}, myData: {myUID}, theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }
    }, [])

    return (
        <>
        <div className="userContainer_friendsMMVB1" onClick={() => navigate('/chat/'+key)} style={{ cursor: 'pointer' }}>
            <div id="profilePart">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} 
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                    <div id="presence" style={{ width: "100%", marginTop: -55 }}>
                        <div className={"presence_presencePPartMVB1 user_"+uid}></div>
                    </div>
                </div>
                <div className="user"><span id="username">{username} {badges.certified}{badges.staff}</span></div>
                <div className="usertag"><span id="usertag">@{usertag.replace('.', '_').toLowerCase()}</span></div>
            </div>
            <div id="actionsButtons">
                <div className="decline_actionsBtnMVB1" onClick={(e) => {
                    e.stopPropagation();
                    // ...
                    axios.delete(
                        `https://api.snot.fr/v${API_VERSION}/user/${uid}/message/${key}`,
                        {
                            headers: {
                                "Content-Type": 'application/json',
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                uid: myUID,
                                app: "web"
                            }
                        }
                    ).then(res => {
                        $('.userContainer_friendsMMVB1').remove();
                        // ...
                        var msgCount = $('#newpm .newChat-privateMsgPcVB1');
                        var newMsgCount = msgCount.text();
                            newMsgCount--;
                        msgCount.css('display', 'none');
                        // -- TOP
                        var msgCountTop = $('.topPart_friendsMMVB1 > h1');
                        msgCountTop.text(t('friends.newpm.title')+' - '+newMsgCount);
                    })
                }}>
                    <i className="fi fi-rr-cross"></i>
                </div>
                <div className="accept_actionsBtnMVB1" onClick={(e) => {
                    e.stopPropagation();
                    // ...
                    axios.put(
                        `https://api.snot.fr/v${API_VERSION}/user/${uid}/message/${key}`,
                        undefined,
                        {
                            headers: {
                                "Content-Type": 'application/json',
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                uid: myUID,
                                app: "web"
                            }
                        }
                    ).then(res => {
                        $('.userContainer_friendsMMVB1').remove();
                        // ...
                        var msgCount = $('#newpm .newChat-privateMsgPcVB1');
                        var newMsgCount = msgCount.text();
                            newMsgCount--;
                        msgCount.css('display', 'none');
                        // -- TOP
                        var msgCountTop = $('.topPart_friendsMMVB1 > h1');
                        msgCountTop.text(t('friends.newpm.title')+' - '+newMsgCount);
                    })
                }}>
                    <i className="fi fi-rr-check"></i>
                </div>
            </div>
        </div>
        </>
    )
}