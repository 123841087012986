import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import Cookies from "js-cookie";
import copy from 'copy-to-clipboard';
import HtmlToReact from 'html-to-react';
import DOMPurify from "dompurify";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
// import Functions
import { API_VERSION } from "../../Functions";
import * as Home from '../../Functions/Home';

// TIME SINCE
function timeSince(date, langYear, langDay) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;

    if (interval > 1) {
    return Math.floor(interval) + langYear;
    }
    interval = seconds / 2592000;

    if (interval > 1) {
    return Math.floor(interval) + "m";
    }
    interval = seconds / 86400;
    if (interval > 1) {
    return Math.floor(interval) + langDay;
    }
    interval = seconds / 3600;
    if (interval > 1) {
    return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
    return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + " s";
}

export const ShareMenu = ({user, post, logged, theme, session}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    // Set data
    const [actionShare, setShareEvent] = useState('share');
    const [reposted, setReposted] = useState(false);
    const [remainingSeconds, setRemaining] = useState(5);
    const [infos, setInfos] = useState([]);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>
    })

    // Set date format
    const sendDate = new Date(post.send_date);
    const today = new Date();
          today.setHours(0, 0, 0, 0);

    const match_date = new Date(post.send_date);
          match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Know if [user] already shared [post]
    const verifyShare = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${post.uid}/verify/share`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Know if [user] already reposted [post]
    const verifyRepost = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/repost/${post.uid}/verify`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, postUid, actionUser, type) => {
        if (logged === 1) {
            if(type === 'share' || type === "repost"){
                if(type === "shared"){
                    const shareCount = document.querySelector('.share_'+post.uid+' span');
                    let shares = shareCount.textContent;
                    shares++;

                    shareCount.innerHTML = shares;
                    $('.share_'+post.uid).addClass('shared');
                    $('.share_'+post.uid).removeClass('unshare');
                    // Set shared for click event
                    setShareEvent('shared');
                    
                    var newType = type;
                    if(type === "repost") newType = 'share';

                    // Add share
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/${actionUser}/actions/${postUid}/add/${newType}`,
                        { post_user: post.sender },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                app: 'web'
                            }
                        }
                    ).then(response => {
                        // ADD REPOST
                        if(type === "repost"){
                            // ...
                            axios.post(
                                `https://api.snot.fr/v${API_VERSION}/repost/add`,
                                { user: actionUser, post: postUid },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    params: {
                                        token: Cookies.get('loginToken'),
                                        uid: user.uid,
                                        app: 'web'
                                    }
                                }
                            ).then(r => {
                                $('#shareBM #repost i').removeClass('fi-rr-arrows-retweet').addClass('fi-rr-check');
                            })
                        }
                    })
                }else{
                    // ADD REPOST
                    if(type === "repost"){
                        // ...
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/repost/add`,
                            { user: actionUser, post: postUid },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    uid: user.uid,
                                    app: 'web'
                                }
                            }
                        ).then(r => {
                            $('#shareBM #repost i').removeClass('fi-rr-arrows-retweet').addClass('fi-rr-check');
                        })
                    }
                }
            }else if(type === "r_repost"){
                // REMOVE REPOST
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/repost/remove`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        },
                        data: { user: actionUser, post: postUid }
                    }
                ).then(r => {
                    // UI/UX
                    $('#shareBM #reposted i').removeClass('fi-rr-cross').addClass('fi-rr-arrows-retweet');
                    $('#shareBM #reposted span').text('Re-spost');
                    // CHANGE ID
                    $('#shareBM #reposted').attr('id', 'repost');
                    // EVENT CLICK
                    $('#shareBM #repost').off('click');
                    $('#shareBM #repost').on('click', (e) => action(e, post.uid, user.uid, 'repost'))
                })
            }
        }
    }

    useEffect(() => {
        // Retreive [user] infos
        Home.retreiveCreatorInfos(post.sender).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes") {
                    var colorBadge = "#000";
                    if(theme === "darkTheme"){
                        colorBadge = "#fff"
                    }
                    setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
                }
                if (res.staff === "Yes") {
                    setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }
            }
        })
        // Retreive my user infos
        if(user !== undefined){
            // Check if [user] already shared
            verifyShare(user.uid).then(r => {
                if(r >= 1) setShareEvent("shared");      
            })
            // Check if [user] already repost
            verifyRepost(user.uid).then(r => {
                if(r >= 1) setReposted(true);
            })
        }
    }, [logged])

    useEffect(() => {
        // EVENT LISTENERS
        // -- FOR INTEGRATE
        $('#integrate').off('click')
        $('#integrate').on('click', () => navigate('/integrate?url='+post.uid))
        // -- FOR COPY
        $('.shareMenu_'+post.uid).off('click')
        $('.shareMenu_'+post.uid).on('click', (e) => {
            copy('https://snot.fr'+location.pathname);
            // UI
            $(".shareMenu_"+post.uid+" span").text(t('general.copied'));
            setTimeout(() => {
                if($(".shareMenu_"+post.uid)){
                    $(".shareMenu_"+post.uid+' span').text(t('comment.share'))
                }
            }, 2000)
            // ...
            action(e, post, user, actionShare);
        })
    }, [logged, actionShare])

    useEffect(() => {
        // -- FOR REPOST
        if(infos.length !== 0){
            if(logged === 1 && session !== "current"){
                if(!reposted){
                    $('#repost').off('click').on('click', (e) => action(e, post.uid, user.uid, 'repost'))
                }else{ // -- FOR REPOST (REMOVE)
                    $('#reposted').off('click').on('click', (e) => action(e, post.uid, user.uid, 'r_repost'))
                }
            }
        }
    }, [logged, session, user, reposted, infos, actionShare])

    if(infos.length !== 0){
        return (
            <>
                {/* SHARE MENU */}
                <aside id="shareBM" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                    <div className="menu-wrapper js-menu-stop">
                        <div className={"spostView_shareMVeB1 "+theme}>
                            <div className={"mainPart_spostViewMVB1 "+theme}>
                                <div className="profilePart_spostViewMVB1" id="profilePart">
                                    <img alt={"@"+infos.usertag.toLowerCase().replace('.', '_')} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1" />
                                    <span id="username">{infos.username} {badges.certified}{badges.staff}</span>
                                    <span id="usertag">@{infos.usertag.toLowerCase().replace('.', '_')}</span>
                                </div>
                                <div className="datePart_spostViewMVB1">
                                    <span>{date_format}</span>
                                </div>
                                <div className="spostContent_spostViewMVB1">
                                    <span className="content">{HtmlToReact.Parser().parse(DOMPurify.sanitize(post.content, {FORBID_TAGS: ["br"], FORBID_ATTR: ["style"]}))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="actionsPart_spostMMVB1">
                            {
                                logged === 1 ?
                                    session !== 'current' ?
                                        reposted === false ?
                                        <div id="repost"><span>Re-spost</span><i className="fi fi-rr-arrows-retweet"></i></div>
                                        :
                                        <div id="reposted"><span>{t('home.repost.remove')}</span><i className="fi fi-rr-cross"></i></div>
                                    : ''
                                : ''
                            }
                            <div id="copy" className={"shareMenu_"+post.uid}>
                                <span>{t('home.general.share.copy')}</span>
                                <i className="fi fi-rr-copy-alt"></i>
                            </div>
                        </div>
                    </div>
                </aside>
            </>
        )
    }else {
        return <></>;
    }
}