import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
// import Components
import { Appbar, Navbar } from '../../../../Components/Common';
// import i18next
import { useTranslation } from "react-i18next";

const SettingsAbout = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    
    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const closeNotifMB = () => {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem("theme"), location])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr/settings/about" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.about')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/settings/about"+location.search}/>
                <meta name="twitter:title" content={t('title.settings.about')}/>
                <title>{t('title.settings.about')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <div id="mBLogged" className={theme+" error"} style={{display: 'none'}}>
                <div id="textN_nMAPPb1">
                    <p></p>
                </div>
                <div className="iconClose" onClick={closeNotifMB}><i className="fi fi-sr-cross"></i></div>
            </div>
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <div id="returnIcon" onClick={() => navigate('/settings')} tabIndex={1}>
                            <i className="fi fi-rr-angle-left"></i>
                        </div>
                        <h1 id="title">{t('settings.about.title')}</h1>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="GeneralSettings">
                            <div className="firstSetting" onClick={() => navigate('/settings/about/tos')}>
                                <span id="settingName">{t('footer.tos')}</span>
                                <div className="goToSetting">
                                    <i className="fi fi-rr-angle-right"></i>
                                </div>
                            </div>
                            <div className="secondSetting" onClick={() => navigate('/settings/about/privacy')}>
                                <span id="settingName">{t('footer.privacy')}</span>
                                <div className="goToSetting">
                                    <i className="fi fi-rr-angle-right"></i>
                                </div>
                            </div>
                            <div className="thirdSetting" onClick={() => navigate('/settings/about/content')}>
                                <span id="settingName">{t('footer.content')}</span>
                                <div className="goToSetting">
                                    <i className="fi fi-rr-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div id="SNOTSettings">
                            <h1 id="title">{t('settings.about.help')}</h1>
                            <div className="firstSetting" onClick={() => navigate('/settings/about/get-certified')}>
                                <span id="settingName">{t('settings.about.get-certified')}</span>
                                <div className="goToSetting">
                                    <i className="fi fi-rr-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsAbout;