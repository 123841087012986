import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import LogOut from "../Modals/Settings/LogOut";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
// import Functions
import { API_VERSION } from "../Functions";
import { openMenu } from "../Functions/Menu";
// import Auth
import { retreiveInfos } from "../Functions/Auth";

const countries = [
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AL', name: 'Albanie' },
    { code: 'DZ', name: 'Algérie' },
    { code: 'AS', name: 'Samoa américaines' },
    { code: 'AD', name: 'Andorre' },
    { code: 'AO', name: 'Angola' },
    { code: 'AG', name: 'Antigua-et-Barbuda' },
    { code: 'AR', name: 'Argentine' },
    { code: 'AM', name: 'Arménie' },
    { code: 'AU', name: 'Australie' },
    { code: 'AT', name: 'Autriche' },
    { code: 'AZ', name: 'Azerbaïdjan' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BB', name: 'Barbade' },
    { code: 'BY', name: 'Biélorussie' },
    { code: 'BE', name: 'Belgique' },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Bénin' },
    { code: 'BT', name: 'Bhoutan' },
    { code: 'BO', name: 'Bolivie' },
    { code: 'BA', name: 'Bosnie-Herzégovine' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BR', name: 'Brésil' },
    { code: 'BN', name: 'Brunei' },
    { code: 'BG', name: 'Bulgarie' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'CV', name: 'Cap-Vert' },
    { code: 'KH', name: 'Cambodge' },
    { code: 'CM', name: 'Cameroun' },
    { code: 'CA', name: 'Canada' },
    { code: 'KY', name: 'Îles Caïmans' },
    { code: 'CF', name: 'République Centrafricaine' },
    { code: 'TD', name: 'Tchad' },
    { code: 'CL', name: 'Chili' },
    { code: 'CN', name: 'Chine' },
    { code: 'CO', name: 'Colombie' },
    { code: 'KM', name: 'Comores' },
    { code: 'CD', name: 'République Démocratique du Congo' },
    { code: 'CG', name: 'République du Congo' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'HR', name: 'Croatie' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CY', name: 'Chypre' },
    { code: 'CZ', name: 'République tchèque' },
    { code: 'DK', name: 'Danemark' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominique' },
    { code: 'DO', name: 'République dominicaine' },
    { code: 'EC', name: 'Équateur' },
    { code: 'EG', name: 'Égypte' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'GQ', name: 'Guinée équatoriale' },
    { code: 'ER', name: 'Érythrée' },
    { code: 'EE', name: 'Estonie' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'ET', name: 'Éthiopie' },
    { code: 'FJ', name: 'Fidji' },
    { code: 'FI', name: 'Finlande' },
    { code: 'FR', name: 'France' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambie' },
    { code: 'GE', name: 'Géorgie' },
    { code: 'DE', name: 'Allemagne' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GR', name: 'Grèce' },
    { code: 'GD', name: 'Grenade' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GN', name: 'Guinée' },
    { code: 'GW', name: 'Guinée-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haïti' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HU', name: 'Hongrie' },
    { code: 'IS', name: 'Islande' },
    { code: 'IN', name: 'Inde' },
    { code: 'ID', name: 'Indonésie' },
    { code: 'IR', name: 'Iran' },
    { code: 'IQ', name: 'Irak' },
    { code: 'IE', name: 'Irlande' },
    { code: 'IL', name: 'Israël' },
    { code: 'IT', name: 'Italie' },
    { code: 'JM', name: 'Jamaïque' },
    { code: 'JP', name: 'Japon' },
    { code: 'JE', name: 'Jersey' },
    { code: 'JO', name: 'Jordanie' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KR', name: 'Corée du Sud' },
    { code: 'KP', name: 'Corée du Nord' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kirghizistan' },
    { code: 'LA', name: 'Laos' },
    { code: 'LV', name: 'Lettonie' },
    { code: 'LB', name: 'Liban' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Libéria' },
    { code: 'LY', name: 'Libye' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lituanie' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MG', name: 'Madagascar' },
    { code: 'MW', name: 'Malawi' },
    { code: 'MY', name: 'Malaisie' },
    { code: 'MV', name: 'Maldives' },
    { code: 'ML', name: 'Mali' },
    { code: 'MT', name: 'Malte' },
    { code: 'MH', name: 'Îles Marshall' },
    { code: 'MQ', name: 'Martinique' },
    { code: 'MR', name: 'Mauritanie' },
    { code: 'MU', name: 'Maurice' },
    { code: 'YT', name: 'Mayotte' },
    { code: 'MX', name: 'Mexique' },
    { code: 'FM', name: 'Micronésie' },
    { code: 'MD', name: 'Moldavie' },
    { code: 'MC', name: 'Monaco' },
    { code: 'MN', name: 'Mongolie' },
    { code: 'ME', name: 'Monténégro' },
    { code: 'MA', name: 'Maroc' },
    { code: 'MZ', name: 'Mozambique' },
    { code: 'MM', name: 'Myanmar' },
    { code: 'NA', name: 'Namibie' },
    { code: 'NR', name: 'Nauru' },
    { code: 'NP', name: 'Népal' },
    { code: 'NL', name: 'Pays-Bas' },
    { code: 'NZ', name: 'Nouvelle-Zélande' },
    { code: 'NI', name: 'Nicaragua' },
    { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigéria' },
    { code: 'MK', name: 'Macédoine du Nord' },
    { code: 'NO', name: 'Norvège' },
    { code: 'OM', name: 'Oman' },
    { code: 'PK', name: 'Pakistan' },
    { code: 'PW', name: 'Palaos' },
    { code: 'PA', name: 'Panama' },
    { code: 'PG', name: 'Papouasie-Nouvelle-Guinée' },
    { code: 'PY', name: 'Paraguay' },
    { code: 'PE', name: 'Pérou' },
    { code: 'PH', name: 'Philippines' },
    { code: 'PL', name: 'Pologne' },
    { code: 'PT', name: 'Portugal' },
    { code: 'QA', name: 'Qatar' },
    { code: 'RE', name: 'Réunion' },
    { code: 'RO', name: 'Roumanie' },
    { code: 'RU', name: 'Russie' },
    { code: 'RW', name: 'Rwanda' },
    { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'Saint-Marin' },
    { code: 'ST', name: 'Sao Tomé-et-Principe' },
    { code: 'SA', name: 'Arabie Saoudite' },
    { code: 'SN', name: 'Sénégal' },
    { code: 'RS', name: 'Serbie' },
    { code: 'SC', name: 'Seychelles' },
    { code: 'SL', name: 'Sierra Leone' },
    { code: 'SG', name: 'Singapour' },
    { code: 'SX', name: 'Sint Maarten' },
    { code: 'SK', name: 'Slovaquie' },
    { code: 'SI', name: 'Slovénie' },
    { code: 'SB', name: 'Îles Salomon' },
    { code: 'SO', name: 'Somalie' },
    { code: 'ZA', name: 'Afrique du Sud' },
    { code: 'GS', name: 'Géorgie du Sud et Îles Sandwich du Sud' },
    { code: 'SS', name: 'Soudan du Sud' },
    { code: 'ES', name: 'Espagne' },
    { code: 'LK', name: 'Sri Lanka' },
    { code: 'SD', name: 'Soudan' },
    { code: 'SR', name: 'Suriname' },
    { code: 'SJ', name: 'Svalbard et Jan Mayen' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'SE', name: 'Suède' },
    { code: 'CH', name: 'Suisse' },
    { code: 'SY', name: 'Syrie' },
    { code: 'TW', name: 'Taïwan' },
    { code: 'TJ', name: 'Tadjikistan' },
    { code: 'TZ', name: 'Tanzanie' },
    { code: 'TH', name: 'Thaïlande' },
    { code: 'TG', name: 'Togo' },
    { code: 'TK', name: 'Tokelau' },
    { code: 'TO', name: 'Tonga' },
    { code: 'TT', name: 'Trinité-et-Tobago' },
    { code: 'TN', name: 'Tunisie' },
    { code: 'TR', name: 'Turquie' },
    { code: 'TM', name: 'Turkménistan' },
    { code: 'TC', name: 'Îles Turques-et-Caïques' },
    { code: 'TV', name: 'Tuvalu' },
    { code: 'UG', name: 'Ouganda' },
    { code: 'UA', name: 'Ukraine' },
    { code: 'AE', name: 'Émirats arabes unis' },
    { code: 'GB', name: 'Royaume-Uni' },
    { code: 'US', name: 'États-Unis' },
    { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Ouzbékistan' },
    { code: 'VU', name: 'Vanuatu' },
    { code: 'VE', name: 'Venezuela' },
    { code: 'VN', name: 'Viêt Nam' },
    { code: 'WF', name: 'Wallis et Futuna' },
    { code: 'EH', name: 'Sahara occidental' },
    { code: 'YE', name: 'Yémen' },
    { code: 'ZM', name: 'Zambie' },
    { code: 'ZW', name: 'Zimbabwe' }
];

const OpSys = [
    { name: 'AIX', type: 'PC' },
    { name: 'Amiga OS', type: 'PC' },
    { name: 'Android', type: 'Mobile' },
    { name: 'Android-x86', type: 'PC' },
    { name: 'Arch', type: 'PC' },
    { name: 'Bada', type: 'Mobile' },
    { name: 'BeOS', type: 'PC' },
    { name: 'BlackBerry', type: 'Mobile' },
    { name: 'CentOS', type: 'PC' },
    { name: 'Chrome OS', type: 'PC' },
    { name: 'Chromecast', type: 'Media' },
    { name: 'Contiki', type: 'Embedded' },
    { name: 'Debian', type: 'PC' },
    { name: 'Deepin', type: 'PC' },
    { name: 'DragonFly', type: 'PC' },
    { name: 'elementary OS', type: 'PC' },
    { name: 'Fedora', type: 'PC' },
    { name: 'Firefox OS', type: 'Mobile' },
    { name: 'FreeBSD', type: 'PC' },
    { name: 'Fuchsia', type: 'Embedded' },
    { name: 'Gentoo', type: 'PC' },
    { name: 'GhostBSD', type: 'PC' },
    { name: 'GNU', type: 'PC' },
    { name: 'Haiku', type: 'PC' },
    { name: 'HarmonyOS', type: 'Mobile' },
    { name: 'HP-UX', type: 'PC' },
    { name: 'Hurd', type: 'PC' },
    { name: 'iOS', type: 'Mobile' },
    { name: 'Joli', type: 'PC' },
    { name: 'KaiOS', type: 'Mobile' },
    { name: 'Linpus', type: 'PC' },
    { name: 'Linspire', type: 'PC' },
    { name: 'Linux', type: 'PC' },
    { name: 'macOS', type: 'Mac' },
    { name: 'Maemo', type: 'Mobile' },
    { name: 'Mageia', type: 'PC' },
    { name: 'Mandriva', type: 'PC' },
    { name: 'Manjaro', type: 'PC' },
    { name: 'MeeGo', type: 'Mobile' },
    { name: 'Minix', type: 'PC' },
    { name: 'Mint', type: 'PC' },
    { name: 'Morph OS', type: 'PC' },
    { name: 'NetBSD', type: 'PC' },
    { name: 'NetRange', type: 'PC' },
    { name: 'NetTV', type: 'Media' },
    { name: 'Nintendo', type: 'Gaming' },
    { name: 'OpenBSD', type: 'PC' },
    { name: 'OpenHarmony', type: 'Mobile' },
    { name: 'OpenVMS', type: 'PC' },
    { name: 'OS/2', type: 'PC' },
    { name: 'Palm', type: 'Mobile' },
    { name: 'PC-BSD', type: 'PC' },
    { name: 'PCLinuxOS', type: 'PC' },
    { name: 'Pico', type: 'PC' },
    { name: 'Plan9', type: 'PC' },
    { name: 'PlayStation', type: 'Gaming' },
    { name: 'QNX', type: 'Embedded' },
    { name: 'Raspbian', type: 'PC' },
    { name: 'RedHat', type: 'PC' },
    { name: 'RIM Tablet OS', type: 'Mobile' },
    { name: 'RISC OS', type: 'PC' },
    { name: 'Sabayon', type: 'PC' },
    { name: 'Sailfish', type: 'Mobile' },
    { name: 'SerenityOS', type: 'PC' },
    { name: 'Series40', type: 'Mobile' },
    { name: 'Slackware', type: 'PC' },
    { name: 'Solaris', type: 'PC' },
    { name: 'SUSE', type: 'PC' },
    { name: 'Symbian', type: 'Mobile' },
    { name: 'Tizen', type: 'Mobile' },
    { name: 'Ubuntu', type: 'PC' },
    { name: 'Unix', type: 'PC' },
    { name: 'VectorLinux', type: 'PC' },
    { name: 'watchOS', type: 'Mobile' },
    { name: 'WebOS', type: 'Mobile' },
    { name: 'Windows', type: 'PC' },
    { name: 'Windows Phone', type: 'Mobile' },
    { name: 'Windows Mobile', type: 'Mobile' },
    { name: 'Xbox', type: 'Gaming' },
    { name: 'Zenwalk', type: 'PC' }
];

// TIME SINCE
function timeSince(date, langYear, langDay) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + langYear;
    }
    interval = seconds / 2592000;

    if (interval > 1) {
      return Math.floor(interval) + "mo";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + langDay;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + " s";
}

// TIME SINCE (Japanese)
function timeSinceJP(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + "年前";
    }
    interval = seconds / 2592000;

    if (interval > 1) {
      return Math.floor(interval) + "ヶ月前";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "日前";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "時間前";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "分前";
    }
    return Math.floor(seconds) + "秒前";
}

const VerticalMenu = ({infos, theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Set data
    const [staff, setStaff] = useState(false);

    // Verify if user's a staff
    const isStaff = (uid) => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/staff/${uid}`,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then((res) => resolve(res.data.staff))
            .catch(err => reject(err));
        })
    }

    useEffect(() => {
        retreiveInfos().then(res => {
            isStaff(res.uid).then(response => setStaff(response));
        })
    }, [])

    return (
        <>
        <section className={"topPart_setMainMVB1 "+theme} style={{overflow:"auto"}}>
            <div className="profileInfo_tPSMMVB1">
                <div className={"avatar "+theme}><img alt={"@"+infos.usertag.toLowerCase().replace('.', '_')} title={infos.username} src={infos.avatar} /></div>
                <div className={"user "+theme}><span id="username">{infos.username}</span></div>
                <div className={"usertag "+theme}><span id="usertag">@{infos.usertag.toLowerCase().replace('.', '_')}</span></div>
            </div>
            <div className={"othersInfo_tPSMMVB1 "+theme}>
                <div className={"datecreate "+theme}>
                    <span id="accountdate">
                        {
                            infos.date_account !== "Créé avant le 09/08/23" ?
                            t('settings.general.accountCreated')+' '+new Date(infos.date_account).toLocaleDateString(i18n.resolvedLanguage, { day:'2-digit',month:'2-digit',year: '2-digit' })
                            :
                            t('settings.general.accountCreatedBefore')+' '+new Date('08-09-2023').toLocaleDateString(i18n.resolvedLanguage, { day:'2-digit',month:'2-digit',year: '2-digit' })
                        }
                    </span>
                </div>
                <h1 id="title">{t('settings.menu.choice.1')}</h1>
            </div>
        </section>
        <section className={"settingsPart_setMainMVB1 "+theme}>
            <div id="GeneralSettings">
                <div className="firstSetting" onClick={() => navigate('/settings/account')}>
                    <i className="fi fi-rr-user"></i>
                    <span id="settingName">{t("settings.account.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="secondSetting" onClick={() => navigate('/settings/privacy')}>
                    <i className="fi fi-rr-lock"></i>
                    <span id="settingName">{t("settings.privacy.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="thirdSetting" onClick={() => navigate('/settings/safety')}>
                    <i className="fi fi-rr-shield-check"></i>
                    <span id="settingName">{t("settings.safety.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="fourthSetting" onClick={() => navigate('/settings/sessions')}>
                    <i className="fi fi-rr-devices"></i>
                    <span id="settingName">{t("settings.sessions.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
            </div>
            <div id="SNOTSettings">
                <h1 id="title">{t('settings.menu.secondary')}</h1>
                <div className="firstSetting" onClick={() => navigate('/settings/appearance')}>
                    <i className="fi fi-rr-palette"></i>
                    <span id="settingName">{t("settings.appearance.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="secondSetting" onClick={() => navigate('/settings/accessibility')}>
                    <i className="fi fi-rr-universal-access"></i>
                    <span id="settingName">{t("settings.accessibility.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="thirdSetting" onClick={() => navigate('/settings/notifications')}>
                    <i className="fi fi-rs-bell"></i>
                    <span id="settingName">{t("settings.notifications.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="thirdSetting" onClick={() => navigate('/settings/language')}>
                    <i className="fi fi-rr-language"></i>
                    <span id="settingName">{t("settings.language.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
            </div>
            <div id="SNOTSettings" className="others">
                <h1 id="title">{t('settings.menu.others')}</h1>
                <div className="firstSetting" onClick={() => navigate('/settings/news')}>
                    <i className="fi fi-rr-confetti"></i>
                    <span id="settingName">{t("settings.news")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                <div className="firstSetting" onClick={() => navigate('/settings/about')}>
                    <i className="fi fi-rr-info"></i>
                    <span id="settingName">{t("settings.about.title")}</span>
                    <div className="goToSetting">
                        <i className="fi fi-rr-angle-right"></i>
                    </div>
                </div>
                {
                    staff === true &&
                    <div id="staff" onClick={() => navigate("/staff")}>
                        <i className="fi fi-rr-shield-check"></i>
                        <span>{t("settings.staff")}</span>
                    </div>
                }
                <div className="thirdSetting" data-container="logoutMenu" onClick={openMenu}>
                    <span id="settingName" data-container="logoutMenu">{t("settings.logout")}</span>
                    <div className="goToSetting" data-container="logoutMenu">
                        <i className="fi fi-rr-sign-out-alt" data-container="logoutMenu"></i>
                    </div>
                </div>
            </div>
        </section>
        <LogOut theme={theme} />
        </>
    )
}

// Session (current)
const Session = ({data: {device, browser, location}, theme}) => {    
    // Format location
    var formatLocation = '';
    if(location.country){
        formatLocation = countries.find(c => c.code === location.country);
    }

    // OS Type
    const getOSType = (osName) => {
        const os = OpSys.find(o => o.name === osName);
        if(os.type === "PC"){
            return 'fi-sr-screen';
        }else if(os.type === "Mobile"){
            return 'fi-sr-tablet-android';
        }else if(os.type === "Gaming"){
            return 'fi-sr-gamepad';
        }else{
            return 'fi-sr-microchip'
        }
    };

    return (
        <div className={"sessionContainer_currentSetPcVB1 "+theme}>
            <i className={"fi "+getOSType(device)} />
            <div className="infosDevice">
                <p className="OS-GenBrowser" style={{margin: 0}}><span id="osName">{device}</span> - <span id="genBrowser">{browser}</span></p>
                <span id="location">{location.city}, {location.region}, {formatLocation.name}</span>
            </div>
        </div>
    )
}

// Sessions (others)
const Sessions = ({data: {token, device, browser, location, date}, uid, theme}) => {
    const {t} = useTranslation();

    // Set ref
    const sessionRef = useRef(null);

    // Format location
    var formatLocation = '';
    if(location.country){
        formatLocation = countries.find(c => c.code === location.country);
    }

    // Format date
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var formatDate = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }
    
    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                formatDate = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else if(i18n.resolvedLanguage === "en") {
        switch (diffDays) {
            default:
                formatDate = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }else {
        switch (diffDays) {
            default:
                formatDate = timeSinceJP(sendDate);
        }
    }

    // OS Type
    const getOSType = (osName) => {
        const os = OpSys.find(o => o.name === osName);
        if(os.type === "PC"){
            return 'fi-sr-screen';
        }else if(os.type === "Mobile"){
            return 'fi-sr-tablet-android';
        }else if(os.type === "Gaming"){
            return 'fi-sr-gamepad';
        }else{
            return 'fi-sr-microchip'
        }
    };

    // Close connection function
    const closeConnection = () => {
        sessionRef.current.remove();
        // API
        axios.delete(
            `https://api.snot.fr/v${API_VERSION}/${uid}/settings/sessions/close`,
            {
                headers: { "Content-Type": "application/json" },
                params: {
                    token: Cookies.get('loginToken'),
                    closeToken: token,
                    app: 'web'
                }
            }
        )
    }

    return (
        <div className={"sessionContainer_othersSetPcVB1 "+theme} ref={sessionRef}>
            <i className={"fi "+getOSType(device)} />
            <div className="infosDevice">
                <p className="OS-GenBrowser" style={{margin: 0}}><span id="osName">{device}</span> - <span id="genBrowser">{browser}</span></p>
                <span id="location">{location.city}, {location.region}, {formatLocation.name} - {formatDate}</span>
            </div>
            <div className="closeConnection" title={t('settings.sessions.others.closeAccessibility')} onClick={() => closeConnection()}>
                <i className="fi fi-sr-cross-circle" />
            </div>
        </div>
    )
}

export {
    VerticalMenu,
    Session,
    Sessions
};