import Cookies from 'js-cookie';
import { useEffect } from 'react';
// import i18next
import { useTranslation } from "react-i18next";

export default function LogOut({theme}) {
    const { t } = useTranslation();

    useEffect(() => {
        // Log out function
        const logout = () => {
            // Unsub from Pusher channel
            // Remove cookies
            Cookies.set('logged', 0, { secure: true });
            Cookies.remove('_genSet', { sameSite: 'Lax', secure: true });
            Cookies.remove('_theme', { sameSite: 'Lax', secure: true });
            Cookies.remove('loginToken', { sameSite: 'Lax', secure: true });
            // Remove interests
            if(localStorage.getItem('interests_list')) localStorage.removeItem('interests_list');
            // Redirect
            window.location.replace('/');
        }

        document.getElementById("logOut").addEventListener('click', logout)
    }, [])
    
    return (
        <>
            <aside id="logoutMenu" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop" style={{width: "100%" }}>
                    <div className="confirmationLogout_bottomMenuMVB1">
                        <h1 id="title">{t('logout.title')}</h1>
                        <div className="actionButtons_bottomMenuMVB1">
                            <div id="goBack" className="js-menu-close-other">
                                <span>{t('logout.buttons.1')}</span>
                            </div>
                            <div id="logOut">
                                <span>{t('logout.buttons.2')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}