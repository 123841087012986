import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import { Appbar, Navbar } from '../../Components/Common';
import { LoadingDefault } from "../../Components/Loading/LoadingDefault";
// import Functions
import { API_VERSION } from "../../Components/Functions";
// import Emojis
// -- Nature emojis
import { emojis_nature } from "../../Components/Functions/Emojis/Nature";

const Profile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [loading, setLoading] = useState(true);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set logged
    useEffect(() => {
        console.log(Cookies.get('logged'))
        if(Cookies.get('logged') === '1'){
            navigate('/');
        }
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
        // Set loading
        setLoading(false);
    }, [])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://mobile.snot.fr/profile" />
                <meta name="robots" content="index,follow"/>
                <meta property="og:title" content={t('title.profile')}/>
                <meta property="og:url" content={"https://mobile.snot.fr/profile"+window.location.search}/>
                <meta name="twitter:title" content={t('title.profile')}/>
                <title>{t('title.profile')}</title>
            </Helmet>
            {
                loading === true ?
                    <LoadingDefault />
                :
                <>
                    <Appbar />
                    <Navbar />
                    <main id="profilePartMain">
                        <section id="profilePart_Profile" className={theme}>
                            <div className="topPart_profileMVB1">
                                <div className={"banner_tPProfileMVB1 "+theme}></div>
                                <div className={"profileInfo_tPProfileMVB1 "+theme}>
                                    <div className={"avatar "+theme}><img alt="Avatar" title="Avatar" src={"https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp"} className="avatar_pPSMaMvB1" /></div>
                                    <div className={"user "+theme} style={{ width:'auto', height:'auto' }}><span id="username">Username</span></div>
                                    <div className={"usertag "+theme} style={{width:'auto', height:'auto',marginTop: -5}}><span id="usertag">@username</span></div>
                                </div>
                                <div className={"aboutMe_tPProfileMVB1 about "+theme} style={{height:'auto'}}>
                                    <span>No bio yet.</span>
                                </div>
                                <div id="followCountProfile">
                                    <div className={"followersCount_profileMVB1 following "+theme} style={{ display: "flex" }}>
                                        <span id="title">{t("profile.subs")}</span>
                                        <div className="separator_fCPMVB1"></div>
                                        <span id="count">0</span>
                                    </div>
                                    <div className={"followersCount_profileMVB1 follower "+theme} style={{ display: "flex" }}>
                                        <span id="title">Followers</span>
                                        <div className="separator_fCPMVB1"></div>
                                        <span id="count">0</span>
                                    </div>
                                    <div className={"streakCount_profilePcB1 "+theme}>
                                        <img src={emojis_nature.fire} alt='Streak icon' title='Streak duration' />
                                        <span>0</span>
                                    </div>
                                </div>
                                <div className={"createDate_tPProfileMVB1 datecreate "+theme} style={{width:'auto', height:'auto'}}>
                                    <span id="createdDate" style={{ marginRight: 10 }}>{t("profile.notLogged.notCreated")}</span>
                                </div>
                            </div>
                        </section>
                        <section id="profileBar" className={"profileBar_mverSB1 "+theme}>
                            <div className={"others_pBmVeB1 "+theme}>
                                <div id="myspost" className="current">
                                    <span>Spost</span>
                                    <i className="fi fi-rr-list"></i>
                                </div>
                                <div id="respost">
                                    <span>Re-spost</span>
                                    <i className="fi fi-rr-refresh"></i>
                                </div>
                                <div id="liked">
                                    <span>{t('profile.menu.liked')}</span>
                                    <i className="fi fi-rr-heart"></i>
                                </div>
                            </div>
                        </section>
                        <section id="spostPart">
                            <div className={"spostPart_profileMVB1 "+theme}>
                                <div className={"chooseLogPart_sPProfileMVB1 "+theme}>
                                    <h1 id="title">{t("profile.notLogged.login")}</h1>
                                    <div className="logSignBtn_cLPartMVB1" onClick={() => {navigate("/")}}><span id="buttonText">{t('login.title')}</span></div>
                                    <div className={"dividerText_cLPartMVB1 "+theme}>{i18n.resolvedLanguage === "fr" ? 'OU': 'OR'}</div>
                                    <div className="logSignBtn_cLPartMVB1" onClick={() => {navigate("/register")}}><span id="buttonText">{t('signup.title')}</span></div>
                                </div>
                            </div>
                        </section>
                    </main>
                </>
            }
        </>
    );
}
 
export default Profile;